<template>
    <q-page-container>
      <q-page class="q-pa-xs-md q-gutter-md">
        <div v-if="products == null && productLoaded" class="text-center">
          <br /><br />
          <p>Rates are not available !</p>
        </div>
        <!--
        <div v-if="customer != null">
          <q-list>
            <q-item>
              <q-item-section class="q-pa-xs text-bold">Seller</q-item-section>
              <q-item-section class="text-left">
                <span class="text-uppercase">{{ customer.trading_name }} </span>
              </q-item-section>
              <q-item-section class="text-left">
                <span></span>
              </q-item-section>
            </q-item>
            <q-separator />
            <q-item>
              <q-item-section class="q-pa-xs text-bold"
                >City / State</q-item-section
              >
              <q-item-section class="text-left">
                <span>{{ customer.city }} , {{ customer.state }}</span>
              </q-item-section>
              <q-item-section class="text-left">
                <span></span>
              </q-item-section>
            </q-item>
            <q-separator />
          </q-list>
        </div>
        -->
        <div v-if="products !== null">
          <q-card
            class="my-card no-shadow q-pb-sm"
            v-for="(item, index) in products"
            v-bind:key="item"
          >
            <q-card-section
              class="text-bold"
              :class="
                index % 2 == 0
                  ? 'bg-green-1  rounded-borders'
                  : 'bg-grey-4 rounded-borders'
              "
            >
              <div class="row" v-if="item.mst_product!=null">
                <div class="col">
                  <q-btn round no-shadow>
                    <q-avatar size="80px" no-shadow>
                      <img
                        :src="photoUrl + item.mst_product.img"
                        style="width: auto"
                      />
                    </q-avatar>
                  </q-btn>
                  <br /><br />
                </div>
                <div class="col text-left">
                  
                  {{ item.product }} -
                  <span class="text-grey text-caption">({{ item.category }})</span
                  >
                  <!--
                  <br />
                  <span class="text-grey">{{ item.brand }}</span> <br />
                  -->
                  <q-badge
                    color="color-blue-2"
                    :label="'Grade : ' + item.grade"
                  />
                  
                </div>
                <div class="col text-right text-red">
                  <!--
                  <q-btn color="green" rounded size="9px" label="View" />
                  -->
                  <br />
                  <q-btn
                    color="green"
                    size="11px"
                    label="View"
                    @click="goToBrands(item.product)"
                  />
                </div>
              </div>
            </q-card-section>
          </q-card>
        </div>
      </q-page>
    </q-page-container>
  </template>
  <script>
  import { userService } from "../../services/userService";
  import { buyService } from "../../services/buyService";
  import mitt from "mitt";
  
  const emitter = mitt();
  
  export default {
    name: "AllProduct",
    data() {
      return {
        isLogged: null,
        products: null,
        customer: null,
        productLoaded:false,
        photoUrl:'',
      };
    },
    methods: {
      onSellerView: function () {},
      onAllSellerProductsSuccess: function (data) {
        //console.log(data);
        if (data.data.length > 0) {
          this.photoUrl = data.productPhotoURL;
          this.products = data.data;
          this.productLoaded = true;
          
          //this.customer = data.data[0].customer;
        }
      },
      onAllSellerProductsFail: function (error) {
        this.showError(error);
        //console.log(error);
      },
    },
    created: function () {
      //this.setPageTitle("Agrawal Steel Group");
      //this.setToolbarTitle("Agrawal Steel Group");
  
      this.setPageTitle("Products");
      this.setToolbarTitle("Products");
    },
    mounted: function () {
      this.isLogged = userService.isLogged();
      emitter.on("onAllSellerProductsSuccess", this.onAllSellerProductsSuccess);
      emitter.on("onAllSellerProductsFail", this.onAllSellerProductsFail);
      buyService.getAllSellerProducts(
        { no:'query',city_id:userService.getUserCity().id},
        this,
        emitter
      );
    },
    unmounted: function () {
      emitter.off("onAllSellerProductsSuccess");
      emitter.off("onAllSellerProductsFail");
    },
    setup() {
      //const $q = useQuasar();
      //return {
      //};
    },
  };
  </script>
  
  