<template>
  <q-page-container class="bg-container">
    <q-page class="q-pa-xs-md q-gutter-md">
      <div class="row">
        <div class="col">
          <q-card class="my-card no-shadow">
            <q-card-section class="">
              <div class="row">
                <div class="col text-flex">
                  <img
                    align="left"
                    src="//via.placeholder.com/100x100"
                    style="width: 100px; height: 100px; padding-right: 10px"
                  />
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged.
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col text-right">
                  <q-badge
                    squar
                    color="green-2 text-black  text-bold"
                    label="non-negotiable "
                  />
                  |
                  <q-badge
                    squar
                    color="blue-2 text-black text-bold"
                    label="size : "
                  />
                  <span class="text-weight-bold text-red"> 12 MM </span>

                  |
                  <q-badge
                    squar
                    color="blue-2 text-black  text-bold"
                    label="Price : "
                  />
                  <span class="text-weight-bold text-red">
                    200.00/- per Kg
                  </span>
                </div>
              </div>
              <br />
              <q-separator />
              <br />
              <div class="text-right">
                <div class="q-gutter-md">
                  <q-input
                      filled
                      v-model="price"
                      label="Price (Per Kg)"
                      mask="#.##"
                      fill-mask="0"
                      reverse-fill-mask
                      hint=" "
                      input-class="text-right text-bold text-subtitle1"
                      readonly=""
                    />

                    <q-input
                      filled
                      v-model="qty"
                      label="Qty in (Per Kg)"
                      mask="#.##"
                      fill-mask="0"
                      reverse-fill-mask
                      hint=" "
                      input-class="text-right text-blue text-bold text-subtitle1"
                    />

                     <q-input
                      filled
                      v-model="total"
                      label="Qty in (Per Kg)"
                      mask="#.##"
                      fill-mask="0"
                      reverse-fill-mask
                      hint=" "
                       input-class="text-right text-black text-bold text-subtitle1"
                      readonly=""
                    />
                    <span>This item Sold by <b class="text-black">Agrwal Group of Steel, New Delhi - 110021</b></span> <br />
                     <br />
                     <q-btn color="secondary" label="Add To Cart" style="width:100%" @click="goToCart()" />
                </div>
              </div>
            </q-card-section>
          </q-card>
        </div>
      </div>
    </q-page>
  </q-page-container>
</template>
<script>
import { userService } from "../services/userService";
import { ref } from "vue";
import { useQuasar, Dialog } from "quasar";

export default {
  name: "ProductDetail",
  data() {
    return {
      isLogged: userService.isLogged(),
      price:20000,
      qty:0,
    };
  },
  methods: {
    onSellerView: function () {},
  },
  created: function () {
    this.setPageTitle("TMT 12 MM");
    this.setToolbarTitle("TMT 12 MM");
  },
  setup() {
    const $q = useQuasar();
    return {
      tab: ref("one"),
    };
  },
  computed:{
    total: function(){
      return this.price * this.qty;
    }
  }
};
</script>
<style lang="sass" scoped>
.my-card
  width: 100%
</style>
<style lang="sass" scoped>
.row-1 > div
  padding: 10px 15px

  border: 1px solid rgba(86,61,124,.2)
</style>
