<template>
    <q-page-container class="bg-container">
        <q-page class="q-pa-xs-md q-gutter-md">

        <q-card class="my-card no-shadow">
        <q-card-section class="text-bold" v-for="item in 5" v-bind:key="item">
          <div class="row">
          <div class="col">
                <q-img
              src="https://via.placeholder.com/65x65"
              spinner-color="project-tertiary"
              style="height: 50px; width: 50px"
              class="q-mt-xs"
              fit="contain"
            ></q-img>
            <br /><br />
          </div>  
            <div class="col text-left">
              Product Xyz - {{ item }}<br />
              <q-badge color="green" class="text-caption" label="10,000/- Per Ton" />
            </div>
            <div class="col text-right text-red">
                <q-btn color="red" rounded size="9px" label="remove"  />
            </div>
          </div>
           <q-separator />
        </q-card-section>
      </q-card>
    </q-page>
</q-page-container>
</template>
<script>
    export default {
        name: "WishListPage",
        data() {
            return {
               
            };
        },
        created: function () {

        },
        beforeMount: function () {
            this.showLoading();
        },
        mounted: function () {
            this.setToolbarTitle("My Wish List");
            this.setPageTitle("My Wish List");

            this.hideLoading();
        },
    };
</script>
