<template>
  <q-layout view="lHh Lpr lFf">
    <q-page-container>
      <q-page class="flex flex-center">
       <strong>Resource Not Found ! <br /><br />
           <router-link to="/home">Click to Go Back...</router-link>
       </strong>
      </q-page>
    </q-page-container>
  </q-layout>
</template>
<script>
import { userService } from "../services/userService";
export default {
  name: "LogoutPage",
  created: function(){
      userService.logout();
      this.goToHome();
  }
};
</script>
