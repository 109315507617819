<template>
  <q-page-container>
    <q-page class="q-pa-xs-md q-gutter-md">
      <!--
      <p
        class="text-center text-bold text-red"
        v-show="kycSubmitted && !kycVerified"
      >
        <br /><br />
        Your KYC is under review. Once KYC Verfied, You are able to sell and buy
        products.
      </p>

      <div
        class="text-center text-black"
        v-show="!kycSubmitted && !kycVerified"
      >
        <br /><br />
        Complete your Shop
        <a @click="goToKyc()" class="text-red text-bold">
          <span class="text-blue">KYC</span></a
        >
        to sell and buy product
      </div>
      -->
      <!-- ################################################################################### -->
      <!--<div v-if="kycVerified">-->
      <div>
        <div class="row">
          <div class="col-12">
            <q-input
              dense
              v-model="searchText"
              outlined
              :placeholder="'Type 3 letter of ' + searchFor"
              rounded
              bg-color="white"
              @keyup.enter="onClickSearch"
            >
              <template v-slot:prepend>
                <b
                  class="text-caption text-black text-bold"
                  @click="searchOptions()"
                  ><q-badge rounded color="green" :label="searchFor"
                /></b>
                <q-icon name="arrow_drop_down" @click="searchOptions()" />
              </template>
              <template v-slot:append>
                <q-icon name="search" @click="onClickSearch()" />
              </template>
            </q-input>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col">
            <q-list>
              <div v-for="(item, index) in data" v-bind:key="item">
                <q-item clickable v-ripple @click="goToSellerProduct(item.api_id,this.productQuery)">
                  <q-item-section avatar>
          <q-avatar square>
                      <!--{{ item.first_letter }}-->
                      <img :src="brandPhotoUrl + item.img"/>
                    </q-avatar>
        </q-item-section>
                  <q-item-section
                    ><strong>{{ item.name }}</strong>
                    <span class="text-caption text-grey">Total Products ({{item.total_products}})</span>
                    <!--<q-item-label caption>Products(Caption</q-item-label> -->
                  </q-item-section>
                  <q-item-section avatar :id="index">
                    <q-icon color="primary" name="navigate_next" />
                  </q-item-section>
                </q-item>
                <q-separator />
              </div>
            </q-list>
          </div>
        </div>
      </div>
    </q-page>
  </q-page-container>
</template>
<script>
import { userService } from "../../services/userService";
import { ref } from "vue";
import { useQuasar, Dialog } from "quasar";
import { searchService } from "../../services/searchService";

import mitt from "mitt";

const emitter = mitt();

export default {
  name: "SellerPage",
  data() {
    return {
      isLogged: userService.isLogged(),
      kycSubmitted: false,
      kycVerified: false,
      searchText: "",
      data: null,
      productQuery: this.$route.query._product,
      brandPhotoUrl: process.env.VUE_APP_BRAND_IMG_URL,
    };
  },
  methods: {
    onSellerSearchSuccess: function (data) {
      //console.log(data);
      this.data = data;
    },
    onSellerSearchFail: function (data) {},
    /*prompt: function () {
      Dialog.create({
        component: [SearchFormComp],
        ok: {
          push: true,
        },
        cancel: {
          color: "negative",
        },
        persistent: true,
      });
    },*/
    onClickSearch: function () {
      //alert(this.searchFor +"=="+ this.searchText);
      this.pullSellers(this.searchText, this.searchFor);
    },
    pullSellers: function (keyword, type) {
      emitter.on("onSellerSearchSuccess", this.onSellerSearchSuccess);
      emitter.on("onSellerSearchFail", this.onSellerSearchFail);
      let city = userService.getUserCity();
      //console.log(city);
      searchService.getSeller({ keyword: keyword, in: type ,product:this.productQuery,
        city_id:city.id}, this, emitter);
    },
  },
  created: function () {
    this.setPageTitle("Sellers - Brands");
    this.setToolbarTitle("Brands");
  },
  mounted: function () {
    if (this.$route.query.in != undefined) {
      this.searchFor = this.$route.query.in;
    }
    if (this.$route.query.keyword != undefined) {
      this.searchText = this.$route.query.keyword;
    }

    this.pullSellers(this.searchText, this.searchFor);
  },
  unmounted: function () {
    emitter.off("onSellerSearchSuccess");
    emitter.off("onSellerSearchFail");
  },
  setup() {
    const $q = useQuasar();
    function searchOptions() {
      $q.dialog({
        title: "Search Options",
        message: "Choose an option to search:",
        options: {
          type: "radio",
          model: "opt5",
          // inline: true
          items: [
            { label: "Product", value: "Product", color: "secondary" },
            { label: "Location", value: "Location", color: "secondary" },
            { label: "Brand", value: "Brand", color: "secondary" },
            { label: "Category", value: "Category", color: "secondary" },
            { label: "Grade", value: "Grade", color: "secondary" },
          ],
        },
        cancel: true,
        persistent: true,
      })
        .onOk((data) => {
          console.log(">>>> OK, received", data);
          this.searchFor = data;
        })
        .onCancel(() => {
          // console.log('>>>> Cancel')
        })
        .onDismiss(() => {
          // console.log('I am triggered on both OK and Cancel')
        });
    }

    return {
      searchOptions,
      searchFor: ref("Product"),
    };
  },
};
</script>
