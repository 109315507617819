<template>
  <q-page-container class="bg-container">
    <q-page class="q-pa-xs-sm q-gutter-sm">
      <!-- if no order-->
      <div class="row" v-if="data == null || data.data.length == 0">
        <div class="text-center text-bold text-h4 text-grey">
            <br /><br /><br /><br />
            There is no recent sell order(s) found !!.
      </div>
      </div>

      <!--else order found-->
      <div class="row" v-if="data != null">
        <div class="col">
          <q-card class="no-shadow">
            <div v-for="(item, index) in data.data" v-bind:key="item">
              <q-card-section
                :class="
                  index % 2 == 0
                    ? 'bg-green-1  rounded-borders'
                    : 'bg-grey-4 rounded-borders'
                "
              >
                <div class="row" v-if="item.is_paid == 'yes'">
                  <div class="col text-caption text-bold text-left">
                    <q-badge
                      :label="'Order # ' + item.order_num"
                      color="grey"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col q-pa-sm q-gutter-sm">
                    <span class="text-bold color-blue text-uppercase">{{
                      item.customer.business_name
                    }}</span
                    ><br />

                    <span class="text-grey text-weight-light">{{
                      item.customer.address
                    }}</span>
                    ,
                    <span class="text-grey text-weight-light"
                      >{{ item.seller.city }}, {{ item.customer.state }}</span
                    >
                  </div>
                </div>
                <q-separator />
                <div class="row">
                  <div class="col q-pa-sm q-gutter-sm">    
                    <q-badge
                      :label="'Rs. ' + item.total_amount"
                      color="red-6"
                    />
                    <q-badge
                      v-if="item.order_num=='' || item.order_num==null"
                      :label="'Status: ' + item.status"
                      color="green-6"
                    />
                    <q-badge
                      v-if="item.order_num=='' || item.order_num==null"
                      :label="item.is_paid == 'no' ? 'unpaid' : 'paid'"
                      :color="item.is_paid == 'no' ? 'grey-6' : 'green'"
                    />
                    <q-badge
                      v-if="
                        item.delivery_status == 'shipped' ||
                        item.delivery_status == 'received'
                      "
                      size="12px"
                      icon="done_all"
                      :label="'Order Status ' + item.delivery_status"
                      color="green"
                    />
                  </div>
                </div>
                <q-separator />
                <div class="q-pa-sm q-gutter-sm text-center">
                  <!--order view-->
                  <q-btn
                    rounded
                    size="9px"
                    color="blue"
                    label="Order View"
                    @click="goToOrderRecievedView(item.api_id)"
                  />
                  <!--confirm order-->
                  <q-btn
                    v-if="item.status != 'confirm' && item.status != 'declined'"
                    rounded
                    size="9px"
                    color="green"
                    label="Process Order"
                    @click="goToOrderRecievedView(item.api_id)"
                  />

                  <!--view Invoice-->
                  <q-btn
                    v-if="item.is_invoiced == '1'"
                    rounded
                    size="9px"
                    color="blue"
                    label="Invoice View"
                    @click="goToInvoiceView(item.invoice.api_id)"
                  />

                  <!--Generate Invoice-->
                  <q-btn
                    v-if="(item.is_invoiced == '0' || item.is_invoiced == 'null') && item.status == 'confirm' && item.is_paid == 'yes'"
                    rounded
                    size="9px"
                    color="grey"
                    label="Generate Invoice ?"
                    @click="onGenerateInvoice(item.api_id)"
                  />
                  <template v-if="item.crdr_note">
                    <!--drcr note-->
                    <q-btn
                      v-if="
                        item.crdr_note != 'null' &&
                        item.crdr_note.paid_to == 'customer' &&
                        item.crdr_note.status == 'pending'
                      "
                      rounded
                      size="9px"
                      color="red"
                      label="Debit Note"
                      @click="payCrNote(item.crdr_note.amount,item.crdr_note.api_id)"
                    />

                    <!--drcr note recieve-->
                    <q-btn
                      v-if="
                        item.crdr_note != 'null' &&
                        item.crdr_note.paid_to == 'seller' &&
                        item.crdr_note.status == 'pending'
                      "
                      rounded
                      size="9px"
                      color="blue"
                      label="Credit Note"
                      @click="showCrNote(item.crdr_note.amount)"
                    />
                  </template>
                  <!-- shippment -->
                  <span
                    v-if="item.is_invoiced == '1'"
                    class="q-pa-sm q-gutter-md text-left"
                  >
                    <q-btn
                      icon="local_shipping"
                      rounded
                      v-if="
                        item.delivery_status == null ||
                        item.delivery_status == ''
                      "
                      size="9px"
                      color="pink"
                      label="Change - In Loading"
                      @click="onChangeDeliveryStatus(item.api_id, 'in-loading')"
                    />

                    <q-btn
                      icon="done_all"
                      rounded
                      v-if="item.delivery_status == 'in-loading'"
                      size="9px"
                      color="green"
                      label="Change In Shipped"
                      @click="onChangeDeliveryStatus(item.api_id, 'shipped')"
                    />
                  </span>
                </div>
              </q-card-section>
            </div>
          </q-card>
        </div>
      </div>
    </q-page>
  </q-page-container>
</template>
<script>
import { userService } from "../../services/userService";
import { ref } from "vue";
import { useQuasar, Dialog } from "quasar";
import mitt from "mitt";
import { orderService } from "../../services/orderService";
import { invoiceService } from '../../services/invoiceService';

const emitter = mitt();

export default {
  name: "OrderRecivedPage",
  data() {
    return {
      isLogged: false,
      data: null,
      apiUrl: process.env.VUE_APP_API_URL,
    };
  },
  methods: {
    onRecivedOrderListSuccess: function (data) {
      //console.log(data);
      this.data = data;
    },

    onRecivedOrderListFail: function (error) {
      this.showError(error);
    },
    pullOrders: function () {
      emitter.on("onRecivedOrderListSuccess", this.onRecivedOrderListSuccess);
      emitter.on("onRecivedOrderListFail", this.onRecivedOrderListFail);
      orderService.recivedOrderList(this, emitter);
    },
    onChangeDeliveryStatus: function (__api_id, status) {
      if (status == "in-loading") {
        emitter.on("onOrderILSuccess", this.onOrderILSuccess);
        emitter.on("onOrderILFail", this.onOrderILFail);
        orderService.orderInLoading({ api_id: __api_id }, this, emitter);
      } else if (status == "shipped") {
        //console.log(stata);
        emitter.on("onOrderShippedSuccess", this.onOrderShippedSuccess);
        emitter.on("onOrderShippedFail", this.onOrderShippedFail);
        orderService.orderShipped({ api_id: __api_id }, this, emitter);
      }
    },
    onOrderILSuccess: function (data) {
      this.showInfo("Delivery Status Changed.");
      this.pullOrders();
    },
    onOrderILFail: function (error) {
      this.showError("Unable to change delivery status");
    },

    onOrderShippedSuccess: function (data) {
      this.showInfo("Delivery Status Changed.");
      this.pullOrders();
    },
    onOrderShippedFail: function (error) {
      this.showError("Unable to change delivery status");
    },
    onGenerateInvoice: function (_id) {
      this.goToGenerateInvoice(_id);
    },
    showCrNote: function (amount) {
      this.$q
        .dialog({
          title: "Credit Note",
          message: "Amount : " + amount + " will be settled by buyer.",
        })
        .onOk(() => {
          // console.log('OK')
        })
        .onCancel(() => {
          // console.log('Cancel')
        })
        .onDismiss(() => {
          // console.log('I am triggered on both OK and Cancel')
        });
    },
    payCrNote: function (amount,api_id) {
        this.$q.dialog({
          title: 'Paid To Customer',
          message: 'For amount '+amount+' , Enter UTR #',
          prompt: {
            model: '',
            type: 'text' // optional
          },
          cancel: true,
          persistent: true
        }).onOk(data => {
          // console.log('>>>> OK, received', data)
          //alert(data);
          emitter.on("onCrDrCustSuccess",this.onCrDrCustSuccess);
          emitter.on("onCrDrCustFail",this.onCrDrCustFail);
          invoiceService.crdrToCustomer({'utr_num':data,'api_id':api_id},this,emitter);
        }).onCancel(() => {
          // console.log('>>>> Cancel')
        }).onDismiss(() => {
          // console.log('I am triggered on both OK and Cancel')
        })
    },
    onCrDrCustSuccess:function(data){
      this.showInfo("Utr submitted successfully.");
      this.pullOrders();
    }
  },
  created: function () {
    this.setPageTitle("Orders Recived");
    this.setToolbarTitle("Orders Recived");
  },
  mounted: function () {
    this.isLogged = userService.isLogged();
    this.pullOrders();
  },
  unmounted: function () {
    
    emitter.off("onRecivedOrderListSuccess");
    emitter.off("onRecivedOrderListFail");

    //emitter.off("onRecievedOrderDeclinedSuccess");
    //emitter.off("onRecievedOrderDeclinedFail");

    //emitter.off("onRecievedOrderConfirmSuccess");
    //emitter.off("onRecievedOrderConfirmFail");

    emitter.off("onOrderILSuccess");
    emitter.off("onOrderILFail");

    emitter.off("onOrderShippedSuccess");
    emitter.off("onOrderShippedFail");

    emitter.off("onCrDrCustSuccess");
    emitter.off("onCrDrCustFail");
  },
  setup() {},
};
</script>

