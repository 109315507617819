<template>
  <q-page-container class="bg-container">
     <q-page class="flex flex-center" v-if="submitted">
      <span class="text-bold text-subtitle1 text-center text-green">
        <q-icon name="check" size="80px" /> <br />

        Message has been sent successfully !
        <br /><br />
        <q-btn
          color="primary"
          size="sm"
          label="Click to go Dashboard"
          @click="goToHome()"
        />
      </span>
    </q-page>

    <q-page class="q-pa-xs-md q-gutter-md" v-if="!submitted">
       <div>
       <b>Email Address : info@steeldalal.com</b> <br />
       <b>Mobile # : 9839651123</b> <br />

       <br /><br />
       <p class="text-bold">Address</p>
       <p>
        Steel Dalal Private Limited , <br />
        Office no 3, 2nd Floor, Sanghvi Jewels, <br />Underai, Off S V Road , Malad (W),<br />
        Mumbai - 400064<br />
        Maharashtra ,India<br />
      </p>
      <br />
      </div>
      <div class="row">
        <div class="col-12">
             <q-form
            @submit="onSubmit"
            class="q-gutter-md"
            enctype="multipart/form-data"
          >
            <q-input
              outlined=""
              dense
              input-class="text-black text-bold"
              label="Business Name*"
              type="text"
              v-model="form.business_name"
              :rules="[(val) => (val && val.length > 0) || '']"
            />

            <q-input
              outlined=""
               dense
              input-class="text-black text-bold"
             
              label="Contact Person Name *"
              v-model="form.contact_person"
              type="text"
              :rules="[(val) => (val && val.length > 0) || '']"
            />

            <q-input
              outlined=""
               dense
              input-class="text-black text-bold"
             
              label="Email *"
              v-model="form.email"
              type="text"
              :rules="[(val) => (val && val.length > 0) || '']"
            />

            <q-input
              outlined=""
               dense
              input-class="text-black text-bold"
             
              label="Mobile #*"
              v-model="form.mobile"
              type="text"
              :rules="[(val) => (val && val.length > 0) || '']"
            />

            <q-input
              outlined=""
               dense
              input-class="text-black text-bold"
             
              label="Message*"
              type="textarea"
              v-model="form.message"
              lazy-rules
              :rules="[(val) => (val && val.length > 0) || 'Enter Message ']"
            />
            <q-btn
              size="md"
              label="Submit"
              type="submit"
              color="blue"
            />
          </q-form>
          
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-right">&nbsp;</div>
      </div>
      <!--
      <div class="row">
        <div class="col-12 text-right">
          <b>
            <a href="#" class="text-grey-6" @click="goToLogin()">Back to Login ?</a>
          </b>
        </div>
      </div>
      -->
    </q-page>
  </q-page-container>
</template>
<script>
//import { ref } from "vue";
//import { useQuasar } from 'quasar'
import { ref } from "vue";
import validationMixin from "../../mixins/validationMixin";
import { emailService } from "../../services/emailService";
import { userService } from "../../services/userService";
import mitt from "mitt";
const emitter = mitt();

export default {
  name: "ContactUsPage",
  mixins: [validationMixin],
  data() {
    return {
      data: null,
      form: {
        business_name: "",
        contact_person: "",
        email: "",
        mobile: "",
        message: "",
      },
      submitted: false,
    };
  },
  created: function () {},
  mounted: function () {
    this.setToolbarTitle("Contact us");
    this.setPageTitle("Contact us ?");
    emitter.on("onUserViewSuccess", this.onUserViewSuccess);
    //userService.view(this, emitter);
    if(userService.isLogged()){
      userService.view(this, emitter);
    }
  },
  unmounted: function () {
    emitter.off("onUserViewSuccess");
    emitter.off("onContactEmailSuccess");
  },
  methods: {
    onSubmit: function () {
        emitter.on("onContactEmailSuccess",this.onContactEmailSuccess)
        emailService.contactEmail(this,this.form,emitter);
    },
    onUserViewSuccess: function (data) {
      this.data = data;
      this.form = data;
      this.form.email = "";
    },
    onContactEmailSuccess: function(data){
        this.submitted = true;
    }
  },
};
</script>
