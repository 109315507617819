<template>
  <q-page-container class="bg-container">
    <q-page class="q-pa-xs-md q-gutter-md">
      <div class="row">
        <div class="col-12">
          <q-input
            v-model="search"
            rounded
            dense
           outlined=""
            placeholder="Search Order # "
            @click="prompt"
          >
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
          <br />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <q-btn
            rounded
            size="md"
            flat
            class="
              full-width
              text-center text-bold text-caption
              c-bg-color
              text-white
              rounded-bordered
            "
            label="Customer Orders"
          />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <q-card class="no-shadow">
            <q-card-section v-for="item in 3" v-bind:key="item" class="text-bold">
              <div class="row">
                <div class="col text-caption">Order # {{item + 100}} <br /> 12 April 2022</div>
                <div class="col">
                  <q-span class="text-bold  color-blue">Custoer-{{ item }} Steel Pvt Ltd. </q-span><br />
                  <span class="text-grey text-weight-light">New Delhi - 11000{{ item }}</span><br />
                  <q-badge label="Dispatched" color="green-6" />
                </div>
                <div class="col-5 text-right">
                    <br />
                  <q-btn
                    size="9px"
                    color="blue"
                    label="View"
                    @click="goToOrderPlacedView()"
                  /><br /><br />
                </div>
              </div>
              <q-separator />
            </q-card-section>  
          </q-card>
        </div>
      </div>
    </q-page>
  </q-page-container>
</template>
<script>
import { userService } from "../../services/userService";
import { ref } from "vue";
import { useQuasar, Dialog } from "quasar";

export default {
  name: "ShipmentPage",
  //components:[
  //    SearchFormComp
  // ],
  data() {
    return {
      isLogged: userService.isLogged(),
    };
  },
  methods: {
  },
  created: function () {
    this.setPageTitle("Shipments");
    this.setToolbarTitle("Shipments");
  },
  setup() {
    const $q = useQuasar();
    return {
      tab: ref("one"),
    };
  },
};
</script>
<style lang="sass" scoped>
.my-card
    width: 100%
</style>
<style lang="sass" scoped>
.row-1 > div
    padding: 10px 15px

    border: 1px solid rgba(86,61,124,.2)
</style>
