import axiosNonLogged from "../helpers/axiosNonLogged";
import axiosLogged from "../helpers/axiosLogged";

function getSeller(payload, ob, emitterObj) {
    ob.showLoading();
    axiosNonLogged.post("/search/sellers", payload)
        .then(response => {
            let res = response.data.data;
            if (res.head.success == "true") {
                emitterObj.emit('onSellerSearchSuccess', res.body);
            } else {
                emitterObj.emit('onSellerSearchFail', res.body.error.msg);
            }
            ob.hideLoading();
        }).catch(error => {
            ob.showError("Unable to Connect with the Server, Please try again !!")
            return Promise.reject(error);

        });
}
export const searchService = {
    getSeller
};