<template>
  <q-page-container class="bg-container">
    <q-page class="q-pa-xs-md q-gutter-md">
      <div class="row">
        <div class="col-12 text-center text-bold">
          <q-btn round class="">
            <q-avatar size="130px">
              <img src="../assets/img/splash.jpg" style="width: 130px" />
            </q-avatar>
          </q-btn>
        </div>
      </div>
      <div class="text-center">
        <span class="text-h5 text-bold text-center text-grey-7">
          Welcome back !</span
        ><br />
        <span class="text-body text-bold text-center text-grey-5"
          >Login to your account</span
        >
      </div>
      <q-separator />
      <br />
      <div class="row">
        <div class="col-12">
          <q-input
            prefix="+91"
            outlined
            v-model="form.mobileNum"
            label="Mobile # *"
            maxlength="10"
            type="numeric"
            :rules="[
              (val) => (val && val.length >= 10) || 'Enter mobile number !',
            ]"
            hint=" "
            inputClass="text-bold"
          >
            <template v-slot:prepend>
              <q-icon name="phone" />
            </template>
          </q-input>
          <!-- captacha -->
          <div class="row" v-show="!smsSent">
            <div class="col">
              <div id="recaptcha-container"></div>
            </div>
          </div>
          <q-input
            outlined
            type="password"
            v-model="form.gOtp"
            label="OTP"
            lazy-rules
            :rules="[(val) => (val && val.length > 0) || 'Enter Otp !']"
            hint=" "
            v-show="smsSent"
          >
            <template v-slot:prepend>
              <q-icon name="lock" />
            </template>
          </q-input>
          <div class="q-gutter-xs" v-show="smsSent">
            <q-checkbox v-model="cAgree" label=" " color="blue" /> I Agree -
            <span class="text-blue" @click="showToggleDialog(true)"
              >Terms & Conditions</span
            >
            <br />
          </div>
          <div class="text-center">
            <br />
            <q-btn
              size="md"
              style="width: 30%"
              rounded=""
              label="Submit"
              type="button"
              color="green"
              @click="onFormSubmit"
              v-show="smsSent"
            />
            &nbsp;
            <q-btn
              size="md"
              style="width: auto"
              rounded=""
              :label="otpBtnLabel"
              type="button"
              id="log-in"
              class="c-bg-color text-white"
              v-show="otpBtn"
              :disable="enableOtpBtn"
              @click="sendOTP(false)"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-right">&nbsp;</div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <a href="#" class="text-grey-7 text-bold text-body">Call Us? </a>
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <a href="#" class="text-grey-7 text-bold" @click.stop="goToRegister()"
            >Create a New Account ?</a
          >
        </div>
      </div>
      <!-- terms and conditions dialog --->
      <div>
        <q-dialog
          v-model="dialog"
          persistent
          full-width
          v-show="dialog"
          class="bg-black"
        >
          <q-card class="text-black">
            <q-card-section>
              <p class="text-center text-h5 text-underline">
                Terms & Conditions
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras
                ornare neque id neque viverra scelerisque. Nulla vel nunc
                mollis, suscipit ligula vitae, efficitur sapien. Nunc sed neque
                massa. Duis ultrices nisi sit amet erat molestie scelerisque id
                vitae ex. Maecenas et tincidunt est, quis aliquam est. Phasellus
                ut metus tortor. Duis posuere felis orci, quis vulputate turpis
                bibendum vel. Vivamus dignissim imperdiet diam, sed egestas
                urna. Morbi sollicitudin nulla lectus, et pharetra libero
                pellentesque non. Vestibulum magna justo, pellentesque sagittis
                dignissim ac, laoreet volutpat tellus. Fusce in pretium lorem.
                Sed in metus a enim posuere hendrerit a eget tortor. Phasellus
                interdum, sem eget euismod maximus, risus elit luctus lacus,
                vitae sagittis arcu ligula in leo. Vestibulum lobortis rutrum
                ligula vitae convallis. Mauris volutpat est at magna volutpat
                laoreet. Aliquam tempus neque vel porta pellentesque.
              </p>
              <p>
                Mauris dignissim leo sit amet justo pellentesque consectetur
                eget a sem. Praesent risus ipsum, luctus dapibus nibh ac, varius
                tempus justo. Vestibulum nec sagittis ante, in dapibus diam.
                Curabitur dignissim tellus elit, at imperdiet turpis aliquet
                eget. Sed lacinia vulputate sodales. Duis porta imperdiet
                pellentesque. Integer sagittis blandit justo, sit amet euismod
                leo vestibulum vitae. Vestibulum felis orci, vehicula vitae ex
                ut, dignissim pharetra sapien. Curabitur quis pulvinar nunc, sit
                amet placerat sapien. Quisque faucibus sapien viverra augue
                fermentum ultricies. Mauris non ex ut nulla venenatis dignissim.
                Ut ornare lacus eget diam venenatis, vehicula convallis enim
                blandit. Duis quis eleifend lorem. Maecenas velit sem, tempor
                nec molestie nec, molestie eu odio. Ut malesuada imperdiet elit
                pharetra blandit. Vivamus ornare orci lacinia libero efficitur,
                eu scelerisque enim placerat.
              </p>
              <p>
                In eu neque ut sem iaculis tempus. Nam quis leo elit. In lacinia
                et lacus ac ultrices. Ut mi est, condimentum sit amet tempor
                sodales, gravida nec ipsum. Pellentesque porttitor lacus vitae
                risus pellentesque, in maximus enim rhoncus. Vivamus sed dictum
                lacus. Praesent tempor ligula tristique libero ornare, eget
                elementum dui tincidunt. Nulla quis ultricies dui, ac tincidunt
                velit. Vivamus faucibus egestas libero, in faucibus mauris
                tempus in. Suspendisse ullamcorper justo dolor, eget rutrum
                lorem finibus in. Pellentesque sed ligula sem.
              </p>
              <p>
                Vestibulum dignissim, arcu eget viverra finibus, nisi purus
                sollicitudin nisi, quis facilisis arcu urna quis leo. Sed quis
                erat placerat, lacinia sem in, laoreet ligula. Nullam ut
                ultricies lacus, a efficitur felis. Donec nibh dui, ultrices ac
                dapibus sit amet, congue pharetra sapien. Proin rhoncus nunc
                lorem, eget volutpat ex mattis non. Nulla eu diam quis lorem
                venenatis lacinia. Etiam in sem eget nisl varius tristique.
                Curabitur vel fermentum neque, quis porta tortor. Aenean sapien
                ligula, semper ut orci in, condimentum faucibus felis.
                Pellentesque sit amet maximus erat. Duis dignissim est sed purus
                varius, at pellentesque nulla rhoncus.
              </p>
              <p>
                Cras tincidunt sit amet libero vitae tincidunt. Proin mattis
                luctus sollicitudin. Suspendisse ut augue lorem. Pellentesque ac
                justo eget nibh maximus posuere. Integer vel lacus gravida,
                convallis dui in, sodales purus. Mauris dapibus velit ipsum, in
                tempor magna tristique ac. Vivamus eleifend orci et commodo
                varius.
              </p>
            </q-card-section>
            <br />
            &nbsp;
          </q-card>
          <q-card class="text-black text-center bg-black">
            <q-card-section>
              <q-btn
                size="10px"
                label="Close"
                color="primary"
                @click="showToggleDialog(false)"
              />
            </q-card-section>
          </q-card>
        </q-dialog>
      </div>
      <!-- alert comp -->
      <alert-comp :msg="alertMsg" :show="alertShow" v-show="alertShow" />
      <!-- /alert comp-->
    </q-page>
  </q-page-container>
</template>
<script>
import { ref } from "vue";
import { userService } from "../services/userService";
import mitt from "mitt";
import AlertComp from "../components/AlertComp.vue";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import {
  getAuth,
  signInWithPhoneNumber,
  RecaptchaVerifier,
} from "firebase/auth";

const emitter = mitt();

const OTP_TIMER = 120;

export default {
  name: "TestPage",
  components: {
    AlertComp,
  },
  data() {
    return {
      dialog: false,
      countryCode: "+91",
      form: {
        mobileNum: "9711439022",
        otp: "2022",
        gOtp: "",
      },
      resendBtn: false,
      otpBtn: true,
      resendTimer: OTP_TIMER,
      resendCloseTimer: null,
      enableResendBtn: true,
      cAgree: false,
      alertShow: false,
      alertMsg: "",
      otpBtnLabel: "Send OTP",
      
      confirmationResult: null,
      recaptchaVerifier: null,
      recaptchaWidgetId: null,
      confirmResult: null,
      smsSent: false,
      capatchVerified: false,
    };
  },
  created: function () {},
  mounted: function () {
    this.setToolbarTitle("Home");
    this.setPageTitle("Home");
    firebase.auth().useDeviceLanguage();
    this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier("log-in", {
      size: "invisible",
      callback: (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        this.capatchVerified = true;
        console.log(response);
      },
    });
  },
  methods: {
    showToggleDialog: function (bool) {
      this.dialog = bool;
    },
    onFormSubmit() {
      if (this.form.otp == "") {
        this.showAlert("Please enter Otp.");
        return;
      }
      if (this.cAgree == false) {
        this.showAlert("Please accept termns & conditions");
        return;
      }

      this.confirmResult
        .confirm(this.form.gOtp)
        .then((result) => {
          var user = result.user;
          emitter.on("onLoginSuccess", this.onLoginSuccess);
          emitter.on("onLoginFail", this.onLoginFail);
          userService.login(this.form, this, emitter);
          console.log(user);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onLoginSuccess(result) {
      this.goToDashboard();
    },
    showAlert: function (msg) {
      //this.alertShow = true;
      //this.alertMsg = msg;
      this.showError(msg);
    },
    onLoginFail(error) {
      this.showError(error);
    },
    mobileExist() {
      //emitter.on("OnValidateRegistrationScuccess", this.OnValidateRegistrationScuccess);
      //userService.validateRegistration(this.form.mobileNum, this, emitter);
    },
    OnValidateRegistrationScuccess(bool) {
      if (bool) {
        //this.enableOtpBtn = false;
        //firebase.auth().settings.appVerificationDisabledForTesting = true;
        /*
        this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
          "recaptcha-container"
        );*/
        
        this.recaptchaVerifier.render().then((widgetId) => {
          this.recaptchaWidgetId = widgetId;
        });

        var number = this.countryCode + this.form.mobileNum;
        firebase
          .auth()
          .signInWithPhoneNumber(number, this.recaptchaVerifier)
          .then((confirmationResult) => {
            this.confirmResult = confirmationResult;
            console.log(this.confirmResult);
            //alert("Sms Sent!");
            this.smsSent = true;
          })
          .catch((error) => {
            console.log("Sms not sent", error.message);
          });

          this.enableOtpBtn = true;
          this.countDownTimer();
      } else {
        this.showError(this.form.mobileNum + " - Not registered with us !");
      }
    },
    sendOTP: function (resend) {
      if (this.form.mobileNum.length < 10) {
        this.showAlert("Please enter proper mobile number.");
        //console.log("================");
        return;
      } else {
        this.smsSent = false;
        emitter.on(
          "OnValidateRegistrationScuccess",
          this.OnValidateRegistrationScuccess
        );
        userService.validateRegistration(this.form.mobileNum, this, emitter);
      }
      //if (resend == true) {
      //  this.resendTimer = OTP_TIMER;
      //} else {
      //  this.otpBtn = true;
     // }
      
    },
    countDownTimer: function () {
      if (this.resendTimer > 0) {
        this.resendCloseTimer = setTimeout(() => {
          this.resendTimer -= 1;
          this.countDownTimer();
        }, 1000);
      } else {
        this.enableOtpBtn = false;
      }
      this.otpBtnLabel = "Re-Send OTP ( " + this.resendTimer + " ) ";
    },
  },
  setup() {
    return {
      navPos: ref("bottom"),
      slide: ref("style"),
      autoplay: ref(true),
    };
  },
  unmounted: function () {
    emitter.off("onLoginSuccess");
    emitter.off("onLoginFail");
    emitter.off("onLoginFailError");
  },
  watch: {
    resendTimer: function () {
      //console.log(this.resendTimer);
      if (this.splashTimer == OTP_TIMER) {
        clearInterval(this.resendTimer);
        //this.$router.push({ name: "Home" });
        //this.goToHome();
      }
    },
  },
};
</script>
