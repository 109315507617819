<script>
import { userService } from "../../services/userService";
import { ref } from "vue";
import { useQuasar, Dialog } from "quasar";
import { buyService } from "../../services/buyService";
import mitt from "mitt";

const emitter = mitt();

export default {
  name: "SellerDetail",
  data() {
    return {
      isLogged: null,
      products: null,
      customer: null,
      productLoaded: false,
      productQuery: this.$route.query._product,
      typeOfProduct: "standard",
      productPhotoURL: process.env.VUE_APP_PRODUCT_IMG_URL,
    };
  },
  methods: {
    clickOnGo: function (item) {
      let api_id = item.api_id;
      let moq = item.moq_min;
      let moq_max = item.moq_max;
      let buyQty = item.buyQty;
      let materialType = "";
      let thickness = "";

      if (isNaN(buyQty)) {
        this.showError("You must enter quantity !");
        return;
      }
      if (this.typeOfProduct == "standard") {
        materialType = item.mType;
        if (materialType == null || materialType == "") {
          this.showError("You must select material !");
          return;
        }
      }
      /*
      if (this.typeOfProduct == "sheet") {
          thickness = item.thick;
          if (thickness == null || thickness == "") {
            this.showError("You must select thickness !");
          return;
        }  
      }*/
      //check qty should be greater than 1
      let msg = "Quantity must be greater than 0 !";
      if(moq > 1) msg = "Quantity must be greater than and equal to "+moq + " !";
      if(buyQty <=0){
          this.showError(msg);
          return;
      }
      //check for moq
      if(moq > 1){
          if(buyQty < moq ){
            this.showError(msg);
            return;
          }
      }
      //check for max
      if(buyQty > moq_max){
        this.showError("Quantity should not more than : " + moq_max);
        return;
      }

      this.goToSellerProductRate(api_id, buyQty, materialType, thickness);
    },
    onSellerView: function () {},
    onSellerProductsSuccess: function (data) {
      //console.log(data);
      if (data.data.length > 0) {
        this.products = data;
        this.typeOfProduct = data.data[0].size_type;
        //console.log(this.typeOfProduct);

        this.productLoaded = true;
        //this.customer = data.data[0].customer;
      }
    },
    onSellerProductsFail: function (error) {
      this.showError(error);
      //console.log(error);
    },
  },
  created: function () {
    //this.setPageTitle("Agrawal Steel Group");
    //this.setToolbarTitle("Agrawal Steel Group");
    //this.productPhotoUrl = process.env.VUE_APP_PRODUCT_IMG_URL;
    //this.setPageTitle("Seller - Products");
    //this.setToolbarTitle("Seller - Products");
    this.setPageTitle("Products");
    this.setToolbarTitle("Products");
  },
  mounted: function () {
    //this.isLogged = userService.isLogged();
    emitter.on("onSellerProductsSuccess", this.onSellerProductsSuccess);
    emitter.on("onSellerProductsFail", this.onSellerProductsFail);
    let city = userService.getUserCity();
    buyService.getSellerProducts(
      {
        api_id: this.$route.query._id,
        product: this.productQuery,
        city_id: city.id,
      },
      this,
      emitter
    );
    //alert(this.productPhotoURL);
  },
  unmounted: function () {
    emitter.off("onSellerProductsSuccess");
    emitter.off("onSellerProductsFail");
  },
  setup() {
    //const $q = useQuasar();
    //return {
    //};
  },
};
</script>
<style lang="sass" scoped>
.my-card
  width: 100%
</style>
<template>
  <q-page-container class="bg-container">
    <q-page class="q-pa-xs-md q-gutter-md">
      <div v-if="products == null && productLoaded" class="text-center">
        <br /><br />
        <p>Rates are not available !</p>
      </div>
      
      <div v-if="products !== null">
        <div
          v-for="(item, index) in products.data"
          v-bind:key="item"
          :id="index"
          class="q-pb-md"
        >
          <q-card
            class="my-card"
            :cclass="
              index % 2 == 0
                ? 'bg-green-1  rounded-borders'
                : 'bg-grey-4 rounded-borders'
            "
          >
            <img
              :src="productPhotoURL + item.mst_product.img"
              style="opacity: 0.9; height: 150px"
            />
            <q-card-section>
              <div class="text-subtitle1 text-bold">
                {{ item.category }} - {{ item.product }}, {{ item.grade }}
              </div>
              <div class="">
                <q-badge
                  color="grey"
                  class="text-light"
                  :label="'Brand - ' + item.brand"
                />
                &nbsp;
                <q-badge
                  color="red-3"
                  class="text-light"
                  :label="'Min Qty: ' + item.moq  +' MT, Max Qty : ' + item.moq_max+ ' MT'"
                />
              </div>
            </q-card-section>
            <q-separator />
            <q-card-section class="q-pt-sm">
              <!--FOR ALL -->
              <div class="row col-12">
                <div class="col q-pt text-caption text-grey-6 ">
                  <b>Quantity you want to purchase: </b>
                </div>
                <div class="col-3" style="width:29%;">
                  <q-input
                    outlined
                    class="bg-white"
                    type="number"
                    placeholder="Qty (ton)"
                    step="any"
                    input-class="text-right"
                    v-model="item.buyQty"
                    dense
                  ></q-input>
                </div>
              </div>
              <!--- TMT -->
              <div class="row" v-if="typeOfProduct == 'standard'">
                <div class="col">
                  <div class="q-gutter-sm">
                    <q-radio
                      v-model="item.mType"
                      checked-icon="task_alt"
                      unchecked-icon="panorama_fish_eye"
                      val="straight"
                      label="Straight Material"
                    />
                    <q-radio
                      v-model="item.mType"
                      checked-icon="task_alt"
                      unchecked-icon="panorama_fish_eye"
                      val="bend"
                      label="Bend Material"
                    />
                  </div>
                </div>
              </div>
              <!-- SHEET-->
              <div v-if="typeOfProduct == 'sheet'">
                <!--
                <br />
                <q-separator />
                <br />
                <div class="row">
                  <div class="col text-bold">Thickness</div>
                </div>
                <div class="row q-gutter-xs">
                  <div class="col">
                    <q-select
                      dense
                      filled
                      v-model="item.thick"
                      :options="item.thickness"
                      emit-value
                    />
                  </div>
                </div>
                 -->
              </div>

              <!-- FOR ALL-->
              <div class="row q-pt-xs">
                <div class="col text-right">
                  <q-btn
                    style="width: 100%"
                    color="green"
                    label="Show Prices"
                    @click="clickOnGo(item)"
                  />
                </div>
              </div>
            </q-card-section>
            <q-separator />
            <div class="row">
              <div class="col text-light text-caption text-red-5 text-center">
                *Price varies depending on volume you want to purchase
              </div>
            </div>
          </q-card>
        </div>
      </div>
    </q-page>
  </q-page-container>
</template>


