
<script>
import { userService } from "../../services/userService";
import { useQuasar } from "quasar";
import { ref } from "vue";
import { mandiRateService } from "../../services/mandiRateService";
import mitt from "mitt";
import { dashboardService } from "../../services/dashboardService";
import { cmsService } from "../../services/cmsService";
import MarqueeText from "vue-marquee-text-component";
//const emitter = mitt();
const emitterMandiRate = mitt();
const emitterProductRate = mitt();
const emitterBanner = mitt();
const emitterCity = mitt();
const emitterBlog = mitt();
const emitterPostal = mitt();

export default {
  name: "DashboardPage",

  components: {
    MarqueeText,
  },
  data() {
    return {
      isLogged: false,
      kycSubmitted: false,
      kycVerified: false,
      lorem: "Lorem ipsum dolor,",
      dialog: false,
      mRates: null,
      searchText: "",
      sliderMandiRate: 1,
      sliderAdvertisement: 1,
      sliderProductOne: 1,
      SliderProductTwo: 1,
      //searchFor: "Category",
      products: {
        one: null,
        two: null,
        three: null,
      },
      data: null,
      posts: null,
      blogPostUrl: process.env.VUE_APP_BLOG_IMG_URL,
      bannerPhotoUrl: process.env.VUE_APP_BANNER_IMG_URL,
      productPhotoUrl: process.env.VUE_APP_PRODUCT_IMG_URL,
      banners: null,
      cities: [],
      selectedCity: "",
      mPostalCode: "",
      prompt: false,
      blurStyle: "opacity:0.3",
      mandiRatesTicker: "",
      //profiledialog: ref(false),
      //maximizedToggle: ref(true),
    };
  },
  created: function () {},
  methods: {
    onChangeCity: function () {
      //console.log(e);
      //alert(this.selectedCity);
      this.loadPostalCodeData("", this.selectedCity.value);
    },
    showToggleDialog: function (bool) {
      this.dialog = bool;
    },
    onMandiRateSuccess: function (data) {
      let md = data.body.data;
      this.mRates = md; //md.replace(/[b]]/g, "<b>").replace(/[\b]/g, "</b>");
      for (let i = 0; i < this.mRates.length; i++) {
        //this.mandiRatesTicker+="<b style='padding:0px; background-color:#013F7A; color:white'>" + this.mRates[i].key + ":</b>"+"<span style='padding:0px; background-color:#013F7A; color:white;'>"+this.mRates[i].val+'</span>'+" ";
        this.mandiRatesTicker +=
          "<q-chip class='glossy' square color='black' text-color='white' icon='' style='background:black;color:white;font-weight:bold;'>" +
          this.mRates[i].key +
          ":</q-chip>" +
          "<span style='padding:0px; background-color:#013F7A; color:white;'>" +
          " " +
          this.mRates[i].val +
          " " +
          "</span>" +
          "";
        //this.mandiRatesTicker+=`<b>${this.mRates[i].key}</b>`;
      }

      //console.log(data.body);
      emitterMandiRate.off("onMandiRateSuccess");
      emitterMandiRate.off("onMandiRateFailed");
    },
    onProductRateSuccess: function (data) {
      //console.log(data);
      this.data = data;
      this.products.one = data.data[0];
      this.products.two = data.data[1];
      if (data.data[2] == undefined) {
        this.products.three = data.data[2];
      } else {
        this.products.three = null;
      }
      //console.log(this.data.productPhotoURL)
      //console.log(this.products.three==undefined);
      emitterProductRate.off("onProductRateSuccess");
      emitterProductRate.off("onProductRateFail");
    },
    onProductRateFail: function (error) {
      emitterProductRate.off("onProductRateSuccess");
      emitterProductRate.off("onProductRateFail");
    },
    onBlogPostSuccess: function (data) {
      if (data.data.length > 0) {
        this.posts = data.data;
        //console.log(this.posts);
      }
    },
    onBlogPostFail: function (error) {},
    onBannerSuccess: function (data) {
      if (data.data.length > 0) {
        this.banners = data.data;
        //console.log(this.posts);
      }
    },
    onBannerFail: function (error) {},
    onClickSearch: function () {
      //alert(this.searchFor +"=="+ this.searchText);
      //this.pullSellers(this.searchText,this.searchFor);
      this.goToSellerSearch(this.searchText, this.searchFor);
    },

    onCitySuccess: function (data) {
      var d = data.data;
      for (var i = 0; i < d.length; i++) {
        this.cities.push({
          label: d[i].name,
          value: d[i].id,
          color: "secondary",
        });
      }
      //console.log(this.cities);
    },
    onCityFail: function (data) {},
    loadPostalCodeData: function (postalCode, cityId) {
      emitterPostal.on("onPostalCodeSuccess", this.onPostalCodeSuccess);
      emitterPostal.on("onPostalCodeFail", this.onPostalCodeFail);
      userService.checkPostalCode(postalCode, cityId, this, emitterPostal);
    },
    submitPostalCode: function () {
      if (this.mPostalCode == "") {
        this.showError("Please enter pin code.");
        return;
      }
      //this.loadPostalCodeData(this.mPostalCode, "");
    },
    onPostalCodeSuccess: function (data) {
      if (data == null) {
        console.log(data);
      } else {
        //alert(data.data.api_id);
        //console.log(data.data.api_id);
        userService.setUserCity(data.data);
        let city = userService.getUserCity();
        //console.log(city);
        if (city.city_found == "false") {
          this.showInfo("City Not Found but you can change city top menu !");
          this.selectedCity = city.name;
        } else {
          this.selectedCity = city.name;
        }
        this.blurStyle = "";
        this.prompt = false;
      }
      emitterPostal.off("onPostalCodeSuccess");
      emitterPostal.off("onPostalCodeFail");
      this.loadDashboardProduct();
    },
    onPostalCodeFail: function (error) {
      emitterPostal.off("onPostalCodeSuccess");
      emitterPostal.off("onPostalCodeFail");
    },
    loadDashboardProduct: function () {
      emitterProductRate.on("onProductRateSuccess", this.onProductRateSuccess);
      emitterProductRate.on("onProductRateFail", this.onProductRateFail);
      let city = userService.getUserCity();
      //alert(city.id)
      dashboardService.getProductRates(emitterProductRate, city);
    },
  },
  mounted: function () {
    //if not customer redirect;
    if (!userService.isCustomer() && userService.isLogged()) {
      this.goToMyBiz();
    }

    this.kycSubmitted =
      userService.getValue("kyc_submitted") == "yes" ? true : false;
    this.kycVerified =
      userService.getValue("kyc_approved") == "yes" ? true : false;

    this.setPageTitle("Dashboard");
    if (userService.isLogged()) {
      this.setToolbarTitle(
        "Namaste " + userService.getValue("contact_person") + " !"
      );
    } else {
      this.setToolbarTitle("Dashboard");
    }
    this.loadDashboardProduct();

    mandiRateService.getRates(emitterMandiRate);
    emitterMandiRate.on("onMandiRateSuccess", this.onMandiRateSuccess);
    //emitterMandiRate.on("onMandiRateFail", this.onMandiRateFailed);

    emitterBlog.on("onBlogPostSuccess", this.onBlogPostSuccess);
    emitterBlog.on("onBlogPostFail", this.onBlogPostFail);
    cmsService.blogPosts(this, emitterBlog);

    emitterBanner.on("onBannerSuccess", this.onBannerSuccess);
    emitterBanner.on("onBannerFail", this.onBannerFail);
    cmsService.getBanners(this, emitterBanner);

    emitterCity.on("onCitySuccess", this.onCitySuccess);
    emitterCity.on("onCityFail", this.onCityFail);
    dashboardService.getCities(emitterCity);

    if (userService.getUserCity() == null && !userService.isLogged()) {
      //this.prompt = true; //open popup
      this.loadPostalCodeData("", "");
    } else {
      //set city
      let city = userService.getUserCity();
      this.selectedCity = city.name;
      this.blurStyle = "";
      //console.log(city);
    }

    this.isLogged = userService.isLogged();
  },
  unmounted: function () {
    emitterMandiRate.off("onMandiRateSuccess");
    //emitterMandiRate.off("onMandiRateFailed");
    emitterProductRate.off("onProductRateSuccess");
    emitterProductRate.off("onProductRateFail");
    emitterBanner.off("onBannerSuccess");
    emitterBanner.off("onBannerFail");

    emitterBlog.off("onBlogPostSuccess");
    emitterBlog.off("onBlogPostFail");

    emitterCity.off("onCitySuccess");
    emitterCity.off("onCityFail");

    emitterPostal.off("onPostalCodeSuccess");
    emitterPostal.off("onPostalCodeFail");
  },
  /*watch: {
    selectedCity(newSelectedCity, oldSelectedCity) {
      //console.log(newSelectedCity);
      //alert(oldSelectedCity);
      if (oldSelectedCity != "") {
          if(newSelectedCity!==undefined)
          //alert(newSelectedCity.value);
          this.loadPostalCodeData("", newSelectedCity.value);
      }
    },
  },*/
  setup() {
    const $q = useQuasar();
  },
};
</script>
<style lang="sass" scoped>
.example-item
  width: 150px
  height: 150px
</style>
<template>
  <q-page-container class="bg-container">
    <q-page class="q-pa-xxs q-gutter-xxs" :style="blurStyle">
      <!--
      <div
        class="text-center text-black"
        v-show="!kycSubmitted && !kycVerified"
      >
        Complete your Shop
        <a @click="goToKyc()" class="text-red text-bold">
          <span class="text-blue">KYC</span></a
        >
        to enable all features
      </div>
      -->

      <div class="col-12 q-mb-xs" id="cities">
        <q-card
          class="row rounded"
          style="padding-left: 0px; padding-right: 2px;"
        >
          <q-select
            v-model="selectedCity"
            :options="cities"
            dropdown-icon="expand_more"
            transition-show="flip-up"
            transition-hide="flip-down"
            color="text-bold text-primary"
            @update:model-value="onChangeCity()"
            options-dense
            style="width:100%"
            input-class="text-bold text-subtitle"
            
          >
            <template v-slot:prepend>
              <!--<q-chip name="" square class="bg-blue-1 text-bold">My City</q-chip>-->
              <q-chip name="" square class="bg-white text-bold">Location:-</q-chip>
            </template>
          </q-select>

          <template v-slot:option="scope">
            <q-item v-bind="scope.itemProps">
              <q-item-section>
                <q-item-label>{{ scope.opt.label }}</q-item-label>
              </q-item-section>
            </q-item>
          </template>
        </q-card>
      </div>

      <!--<q-select
        filled
        v-model="selectedCity"
        :options="cities"
        label="Choose City"
        transition-show="flip-up"
        transition-hide="flip-down"
        class="text-bold text-primary"
        @update:model-value="onChangeCity()"
      >
        <template v-slot:option="scope">
          <q-item v-bind="scope.itemProps">
            <q-item-section>
              <q-item-label>{{ scope.opt.label }}</q-item-label>
            </q-item-section>
          </q-item>
        </template>
      </q-select>
      -->
      <!--
        <div class="row q-pa-none" v-if="!isLogged">
        <div class="col text-right">
          <a @click="goToHome()" class="text-blue text-underline">Login</a> |
          <a @click="goToRegister()" class="text-blue text-underline"
            >Create an Account?</a
          >
        </div>
      </div>
      -->
      <!-- top men -->
      <!--<div class="row">
        <div class="row col-6 text-left demo" @click="goToRequiredDemo()">                    
          <q-btn stack color="text-white" outline style="width: 100%">
            <div class="row col-12">
            <div class="col-7 text-left" style="margin-top:20px;">
                Schedule a Demo ? 
            </div>

            <div class="col-5" style="margin-top:25px;">            
                <q-icon size="md" name="alarm" style="right"/>          
          </div> 
            </div>
          </q-btn>
        </div>
        <div class="1">&nbsp;</div>
        <div class="col text-left" @click="showToggleDialog(true)">
          <q-btn stack color="grey" outline style="width: 100%;border: 1px solid #b7d6f7;">
            <q-icon color="red" size="md" name="ion-logo-youtube" />How It
            <br />
            works ?
          </q-btn>
        </div>
      </div> -->
      <!-- mandi rates;-->

      <!--<div class="row text-white text-caption" v-show="mRates != null">
        <div class="col-12 bg-primary q-pa-md">
               
        <marquee-text :duration="90" :repeat="100" class="q-pt-sm">
          
          <div v-html='mandiRatesTicker' />
          
        </marquee-text>
                mandiRatesTicker
      </div>
        
      </div>-->

      <q-card
        class="rounded q-mt-md q-mb-md"
        v-show="mRates != null"
        style="background: #013f7a"
      >
        <q-card-section>
          <marquee-text :duration="90" repeat="100">
            <span v-html="mandiRatesTicker" />
          </marquee-text>
        </q-card-section>
      </q-card>
      <q-separator class="sepetaror" />
      <!-- end top menu -->
      <!-- product slider-1 -->
      <!--#####################################################################################################################-->
      <div v-if="data != null" class="row-form products q-mt-xs">
        <template
          v-for="(item, index) in products.one"
          v-bind:key="item"
          :id="'dashboard-' + index"
        >
          <div
            class="col-12 text-left"
            @click="goToBrands(item.mst_product.name)"
          >
            <q-card class="col-12 q-mt-sm rounded">
              <q-card-section>
                <div class="card row rounded-borders">
                  <div class="col-3 row">
                    <q-avatar size="60px" no-shadow>
                      <img
                        :src="productPhotoUrl + item.mst_product.img"
                        style="width: 150px"
                      />
                    </q-avatar>
                  </div>
                  <div class="col-9 row">
                    <div class="row col-12 text-bold q-mt-xs">
                      {{ item.product }}
                    </div>
                    <div class="row col-12 q-mt-sm">
                      <div class="col-8 text-blue">
                        Price : {{ item.price }}/-
                      </div>
                      <div class="col-4 pull-right">
                        <q-btn
                          color="primary"
                          class="text-capitalize"
                          style="font-size: 9px; pointer-events: none"
                          >Per Ton</q-btn
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </q-card-section>
            </q-card>
          </div>
          <!--<div class="col-12" v-if="(index+1 % 2)==0"><q-separator /></div>-->
        </template>
      </div>

      <!-- product slider-1 -->
      <!-- advertisement -->
      <q-card class="col-12 q-mt-md rounded">
        <q-card-section>
          <div v-if="banners != null">
            <q-carousel
              v-model="sliderAdvertisement"
              transition-prev="slide-left"
              transition-next="slide-right"
              swipeable
              animated
              control-color="black"
              padding
              infinite
              :autoplay="true"
              height="auto"
              class="bg-white text-white no-shadow rounded-borders q-ma-none"
            >
              <q-carousel-slide
                v-for="(item, index) in banners"
                v-bind:key="item"
                :name="index + 1"
              >
                <q-img
                  :src="bannerPhotoUrl + item.img"
                  style="width: 100%; height: 200px"
                />
              </q-carousel-slide>
            </q-carousel>
          </div>
        </q-card-section>
      </q-card>
      <!-- /end advertisement -->
      <!-- product slider 2-->
      <div v-if="data != null && products.two" class="q-mt-sm">
        <template
          v-for="(item, index) in products.two"
          v-bind:key="item"
          :id="'dashboard-' + index"
        >
          <div class="col-6 text-center" @click="goToSeller()">
            <div class="rounded-borders bg-blue-1">
              <br />
              <q-avatar size="70px" no-shadow>
                <img
                  :src="productPhotoURL + item.mst_product.img"
                  style="width: auto"
                />
              </q-avatar>
              <br />
              <div class="text-bold q-mt-xs" style="height: 30px">
                {{ item.product }}
              </div>
              <br />
              <span class="text-body2 text-center text-blue text-bold">
                Price : {{ item.price }}/- <br /><q-badge
                  rounded
                  color="grey"
                  label="per ton"
                />
              </span>
            </div>
          </div>
          <!--<div class="col-12" v-if="(index+1 % 2)==0"><q-separator /></div>-->
        </template>
      </div>
      <!--#####################################################################################################################-->
      <!-- // stats-->

      <div class="row col-12 q-gutter-xs q-mt-sm">
        <q-card class="col rounded">
          <q-card-section>
            <div class="col text-left">
              <q-btn
                stack
                text-color="primary"
                class=""
                flat
                rounded
                style="width: 100%"
              >
                <q-icon size="md" name="inventory_2" />
                <span style="text-transform: capitalize; color: black">
                  Orders Delivered <br />(1050+)
                </span>
              </q-btn>
            </div>
          </q-card-section>
        </q-card>
        <q-card class="col rounded">
          <q-card-section>
            <div class="col-12 text-left">
              <q-btn
                stack
                text-color="primary"
                class=""
                flat
                rounded
                style="width: 100%"
              >
                <q-icon size="md" name="people_alt" />
                <span style="text-transform: capitalize; color: black">
                  Total Customers <br />(520+)
                </span>
              </q-btn>
            </div>
          </q-card-section>
        </q-card>
      </div>

      <!-- New -->
      <div class="row q-gutter-xs q-mt-xs">
        <q-card class="col rounded">
          <q-card-section>
            <div class="col text-left">
              <q-btn
                stack
                text-color="primary"
                class=""
                flat
                rounded
                style="width: 100%"
              >
                <q-icon size="md" name="local_shipping" />
                <span style="text-transform: capitalize; color: black">
                  Orders In Process <br />(1030+)
                </span>
              </q-btn>
            </div>
          </q-card-section>
        </q-card>
        <q-card class="col rounded">
          <q-card-section>
            <div class="col-12 text-left">
              <q-btn
                stack
                text-color="primary"
                class=""
                flat
                rounded
                style="width: 100%"
              >
                <q-icon size="md" name="category" />
                <span style="text-transform: capitalize; color: black">
                  Available Brands <br />(60+)
                </span>
              </q-btn>
            </div>
          </q-card-section>
        </q-card>
      </div>
      <!--End New -->

      <!--// end stats-->
      <div v-if="posts">
        <!-- blogs -->
        <div class="row">
          <div class="col">
            <!--<q-btn
              rounded
              size="md"
              flat
              class="full-width text-center text-bold text-caption c-bg-color text-white rounded-bordered"
              label="Latest News and Blogs "
            />-->

            <!--<p class="text-primary q-mt-xs" style="font-size:25px; "><strong>Latest News And Blogs</strong></p>-->
            <q-card
              class="rounded q-mt-md q-mb-md"
              style="background: #013f7a; color: white"
            >
              <q-card-section>
                <strong>Latest News And Blogs</strong>
              </q-card-section>
            </q-card>
          </div>
        </div>
        <div
          class="row"
          v-for="(item, index) in posts"
          v-bind:key="item"
          :id="'blogPost-' + index"
        >
          <div class="col-12">
            <q-card class="blog-bg no-shadow">
              <q-card-section>
                <div class="text-h6">{{ item.title }}</div>
                <div class="text-italic text-grey-6">
                  by
                  <!--{{item.user.name}}-->
                  steeldalal.com
                </div>
              </q-card-section>
              <q-card-section class="q-pt-none">
                <img :src="blogPostUrl + item.img" style="width: 100%" />
                {{ item.short_description }}
                <br />
                <br />
                <i class="text-grey-5">Source : {{ item.source }}</i>
              </q-card-section>
              <q-separator />
            </q-card>
          </div>
        </div>
        <!-- dialog for postal code -->
        <q-dialog v-model="prompt" persistent position="top">
          <q-card>
            <q-card-section class="bg-grey-3">
              <div class="text-h6">Enter Your City Pin Code</div>
            </q-card-section>
            <q-card-section class="q-pt-sm">
              <div class="row">
                <div class="col-8">
                  <q-input
                    outlined=""
                    dense
                    v-model="mPostalCode"
                    autofocus
                    type="number"
                    mask="#######"
                    fill-mask="#"
                    @keyup.enter="submitPostalCode()"
                  />
                </div>
                <div class="col-3 q-pl-xs">
                  <q-btn label="Go" @click="submitPostalCode()" color="green" />
                </div>
              </div>
            </q-card-section>
            <q-separator />
            <div class="row">
              <div class="col text-center text-bold text-subtitle1">OR</div>
            </div>
            <div class="row q-pa-sm">
              <div class="col text-center text-bold">
                <q-btn
                  label="Register"
                  size="11px"
                  stack
                  color="blue"
                  @click="goToRegister()"
                ></q-btn>
              </div>
              <div class="col text-center text-bold">
                <q-btn
                  label="Login"
                  size="11px"
                  stack
                  color="blue"
                  @click="goToHome()"
                ></q-btn>
              </div>
            </div>
          </q-card>
        </q-dialog>
        <!--you dialog-->
        <!-- youtube vedios --->
        <q-dialog v-model="dialog" persistent full-width v-show="dialog">
          <q-card class="bg-black text-white text-center q-pb-sm">
            <q-card-section>
              <q-video
                style="height: 350px"
                src="https://www.youtube.com/embed/WI5LLBteInI"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </q-card-section>
            <q-btn
              size="10px"
              label="Close"
              color="primary"
              @click="showToggleDialog(false)"
            />
          </q-card>
        </q-dialog>
        <!-- /end vedios -->
      </div>
    </q-page>
  </q-page-container>
</template>
