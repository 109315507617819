<script>
import { ref } from "vue";
import { userService } from "../../../services/userService";
const menuList = [
  {
    id: "Dashboard",
    icon: "ion-stats",
    label: "Dashboard",
    separator: true,
  },
  {
    id: "WishList",
    icon: "ion-heart",
    iconColor: "primary",
    label: "My Wish list",
    separator: false,
  },
  {
    id: "Account",
    icon: "ion-contact",
    iconColor: "primary",
    label: "My Account",
    separator: false,
  },
  {
    id: "FAQ",
    icon: "quiz",
    iconColor: "primary",
    label: "FAQs",
    separator: false,
  },
  {
    id: "AboutUs",
    icon: "note",
    iconColor: "primary",
    label: "About Us",
    separator: false,
  },
  {
    id: "Toc",
    icon: "note",
    iconColor: "primary",
    label: "Terms & Conditions",
    separator: false,
  },
  {
    id: "PrivacyPolicy",
    icon: "note",
    iconColor: "primary",
    label: "Privacy Policy",
    separator: false,
  },
  {
    id: "RefundPolicy",
    icon: "note",
    iconColor: "primary",
    label: "Refund Policy",
    separator: false,
  },
  {
    id: "ContactUs",
    icon: "note",
    iconColor: "primary",
    label: "Contact Us",
    separator: false,
  },
  {
    id: "Logout",
    icon: "lock",
    iconColor: "primary",
    label: "Logout",
    separator: true,
  },
  {
    id: "DeleteMyAccount",
    icon: "delete",
    iconColor: "primary",
    label: "Delete My Account",
    separator: false,
  },
];

import mitt from "mitt";
import MenuNonLogged from './menus/MenuNonLogged.vue';
import MenuLogged from './menus/MenuLogged.vue';

const emitter = mitt();

export default {
  components: { 
    MenuNonLogged,
    //MenuLogged
    },
  name: "Footer",
  //props:['userName'],
  props: ["cartTotalItems"],
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
      userName: "",
      location: "",
      mobile: "",
      tab: "",
      isCustomer: true,
      cartItemCount: 0,
      openNonLogged:false,
      //openLogged:false,
    };
  },
  mounted: function () {
    this.userName = userService.getValue("contact_person");
    this.mobile = userService.getValue("mobile");
    this.isCustomer = userService.isCustomer();
    this.location =
      userService.getValue("city") + ", " + userService.getValue("state");
    this.isLooged = userService.isLogged();
  },
  methods: {
    showDrawer: function () {
      if(this.isLooged){
        this.drawer = true;
      }
      //console.log(this.isLooged);
      //console.log("FOOOOOOOOOOOOOOOOOOOOOOOTER");
      emitter.on("onUserViewSuccess", this.onUserViewSuccess);
      emitter.on("onUserViewFail", this.onUserViewFail);
      userService.view(this, emitter);
    },
    onUserViewSuccess: function (data) {
      this.userName = userService.getValue("contact_person");
      this.mobile = userService.getValue("mobile");
      this.location =
        userService.getValue("city") + ", " + userService.getValue("state");
    },
    toggleNonLoggedMenu: function(val){
      this.openNonLogged = val;
    },
    toggleLoggedMenu: function(val){
      this.openLogged = val;
    }
  },
  unmounted: function () {
    emitter.off("onUserViewSuccess");
    emitter.off("onUserViewFail");
  },
  setup() {
    return {
      drawer: ref(false),
      menuList,
    };
  },
};
</script>
<template>
  <q-footer
    bordered
    class="c-toolbar no-shadow text-caption"
    elevated
    style="height: auto"
  >
    <q-tabs
      no-caps=""
      active-color="white"
      indicator-color="transparent"
      class="text-white text-caption"
      v-model="tab"
    >
      <q-tab
        class=""
        :ripple="false"
        icon="home"
        label="Home"
        @click="goToDashboard()"
      />

      <q-separator gray vertical inset />
      
      <q-tab
        :ripple="false"
        icon="ion-basket"
        label="Buy"
        v-show="true"
        @click="goToAllProduct()"
      />
      <!--
      <q-separator gray vertical inset />
      <q-tab :ripple="false" icon="money" label="Sell" @click="goToProduct()" />
      -->
      <template v-if="isCustomer">
      <q-separator gray vertical inset />
      <q-tab
        :ripple="false"
        icon="business"
        label="My Biz"
        @click="goToMyBiz()"
      />
      </template>
      <q-separator gray vertical inset />

      <q-tab
        :ripple="false"
        icon="shopping_cart"
        label="Cart"
        @click="goToCart()"
      >
        <q-badge color="red" floating v-show="cartTotalItems > 0">{{
          cartTotalItems
        }}</q-badge>
      </q-tab>

      <q-separator gray vertical inset />
      <!-- is looged -->
      <template v-if="isLooged">
      <q-tab
        :ripple="false"
        icon="fa fa-bars"
        label="More"
        @click="showDrawer()"
        v-show="isCustomer"
   
      />
      </template>
      <!-- if non logged -->
      <template v-if="!isLooged">
      <q-tab
        :ripple="false"
        icon="fa fa-bars"
        label="More"
        @click="toggleNonLoggedMenu(true)"
      />
      </template>
    </q-tabs>
  </q-footer>
  <q-drawer  
   v-show="isLooged"  
    v-model="drawer"
    :breakpoint="500"
    full-width
    bordered
    overlay
    side="right"
    class="bg-grey-3"
  >
    <q-scroll-area
      class="fit"
      style="
        height: calc(100% - 150px);
        margin-top: 150px;
        border-right: 1px solid #ddd;
      "
    >
      <q-list>
        <template v-for="(menuItem, index) in menuList" :key="index">
          <q-item
            clickable
            :active="menuItem.label === menuItem.id"
            v-ripple
            @click="goToX(menuItem.id)"
          >
            <q-item-section avatar>
              <q-icon :name="menuItem.icon" />
            </q-item-section>
            <q-item-section
              :class="
                menuItem.id == 'DeleteMyAccount' ? 'text-red' : 'text-blue'
              "
            >
              {{ menuItem.label }}
            </q-item-section>
          </q-item>
          <q-separator :key="'sep' + index" v-if="menuItem.separator" />
        </template>
      </q-list>
    </q-scroll-area>
    <q-img
      class="absolute-top"
      src="../../../assets/img/avatar-bg.jpg"
      style="height: 150px"
    >
      <div class="absolute-bottom bg-transparent">
        <q-avatar size="56px" class="q-mb-sm">
          <img src="../../../assets/img/avatar.jpg" />
        </q-avatar>
        <div class="text-weight-bold">
          <q-icon name="badge"></q-icon> {{ this.userName }}
        </div>
        <div><q-icon name="place"></q-icon> {{ location }}</div>
        <div><q-icon name="phone"></q-icon> {{ mobile }}</div>
      </div>
    </q-img>
  </q-drawer>
  <span v-if="!isLooged"><menu-non-logged :open="openNonLogged" @close="toggleNonLoggedMenu"></menu-non-logged></span>
  <!--<span v-if="isLooged"><menu-logged :open="openLogged" @close="toggleLoggedMenu"></menu-logged></span>-->
</template>

