import axiosLogged from "../helpers/axiosLogged";

function placeOrder(payload, ob, emitterObj) {
    ob.showLoading();
    axiosLogged.post("/order/place-order", payload)
        .then(response => {
            if(response.data.data.head.success=="true"){
                emitterObj.emit('onPlaceOrderSuccess', response.data.data.body);
            }else{
                emitterObj.emit('onPlaceOrderFail', response.data.data.body.error.msg);    
            }
            ob.hideLoading();
        }).catch(error => {
            ob.hideLoading();
            emitterObj.emit('onPlaceOrderFail', Promise.reject(error));
        });
}


export const checkoutService = {
    placeOrder    
};