<template>
  <q-page-container>
    <q-page class="q-pa-xs-md q-gutter-md">
      <div class="row">
        <div class="col-12">
          <q-form
            @submit="onSubmit"
            v-model="formObj"
            class="q-gutter-md"
            enctype="multipart/form-data"
          >
            <q-select
              rounded
              outlined
              label="Brands"
              v-model="form.mst_brand_id"
              :options="brands"
              option-label="name"
              emit-value
              map-options
              @update:model-value="OnBrandChange()"
              :rules="[ val => !!val || 'Choose Brand' ]"
            />

            <q-select
              rounded
              outlined
              label="Manufacturer"
              v-model="form.mst_manufacturer_id"
              :options="manufacturer"
              clearable
              option-label="name"
              emit-value
              map-options
              @update:model-value="OnManufacturerChange()"
              :rules="[ val => (!!val) || 'Choose Manufacturer' ]"
            />

            <q-select
              rounded
              outlined
              label="Category"
              v-model="form.mst_category_id"
              :options="categories"
              clearable
              option-label="name"
              emit-value
              map-options
              @update:model-value="OnCategoryChange()"
              :rules="[ val => (!!val) || 'Choose Category' ]"
            />

            <q-select
              rounded
              outlined
              label="Product"
              v-model="form.mst_product_id"
              :options="products"
              clearable
              option-label="name"
              emit-value
              map-options
              @update:model-value="OnProductChange()"
              :rules="[ val => (!!val) || 'Choose Product' ]"
            />

            <q-select
              rounded
              outlined
              label="Grade"
              v-model="form.mst_product_grade_id"
              :options="productGrades"
              clearable
              option-label="name"
              emit-value
              map-options
              :rules="[ val => (!!val) || 'Choose Grade' ]"
            />

            <br />
            <q-btn
              size="md"
              rounded
              label="Save & Next..."
              type="submit"
              class="c-bg-color text-white"
            />
          </q-form>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-right">&nbsp;</div>
      </div>
    </q-page>
  </q-page-container>
</template>
<script>
import { ref } from "vue";
import { productService } from "../../../services/productService";

import mitt from "mitt";
const emitter = mitt();

export default {
  name: "ProductAddPage",
  data() {
    return {
      form: {
        mst_brand_id: "",
        mst_manufacturer_id: "",
        mst_category_id: "",
        mst_product_id: "",
        mst_product_grade_id: "",
      },
      brands:null,
      manufacturer:null,
      categories:null,
      products:null,
      productGrades:null,
      params: null,
      formObj:null,
    };
  },
  created: function () {
    //emitter.on()
   // this.goToProductAddRateTMT("0771f6465dd528a720f7b6cad4b14d70");
  },
  mounted: function () {
    emitter.on("onParamsSuccess", this.onParamsSuccess);
    productService.getParams(this, emitter);

    this.setToolbarTitle("Product Add");
    this.setPageTitle("Product Add");
  },
  methods: {
    onParamsSuccess: function (data) {
      //console.log(data);
      this.params = data.data;
      this.brands = this.params;
    },
    OnBrandChange: function(){
        //console.log();
        //console.log(this.form.mst_brand_id);
        
        this.form.mst_manufacturer_id = null;
        this.form.mst_category_id = null;
        this.form.mst_product_id = null;
        this.form.mst_product_grade_id = null;
        this.categories = null;
        this.products  = null;
        this.productGrades = null;
        this.manufacturer = null;
        
        this.manufacturer = this.form.mst_brand_id.manufacturers;
        
        
 
    },
    OnManufacturerChange: function(){
        this.categories = this.form.mst_manufacturer_id.categories;
    },
    OnCategoryChange: function(){
        this.products = this.form.mst_category_id.products;
    },
    OnProductChange: function(){
        this.productGrades = this.form.mst_product_id.mst_product_grades;
    },
    onSubmit: function () {
      //console.log(this.form);
      //alert(this.form.mst_manufacturer_id.id);
      let payload = {
        mst_brand_id: this.form.mst_brand_id.id,
        mst_manufacturer_id: this.form.mst_manufacturer_id.id,
        mst_category_id: this.form.mst_category_id.id,
        mst_product_id: this.form.mst_product_id.id,
        mst_product_grade_id: this.form.mst_product_grade_id.id,
      };

      emitter.on("onAddProductSuccess",this.onAddProductSuccess);
      emitter.on("onAddProductFail",this.onAddProductFail);
      
      productService.addProduct(this,payload,emitter)
    },
    onAddProductSuccess:function(data){
        //console.log(data);
        if(data.size_type=="standard"){
            this.goToProductAddRateTMT(data.api_id);
        }else if(data.size_type=="sheet"){
            this.goToProductAddRateSheet(data.api_id);
        }if(data.size_type=="coil"){
            this.goToProductAddRateCoil(data.api_id);
        }else{
          this.showInfo("Product Save !");
        }
    },
    onAddProductFail: function(data){
        this.showError("Unable to Add Product.");
    }
  },
  setup() {},
  unmounted: function () {
    emitter.off("onParamsSuccess");
    //emitter.off("onUserViewFaile");
  },
};
</script>
