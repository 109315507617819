<template>
  <q-page-container>
    <q-page class="q-pa-xs-md">
      <div v-if="data == null" class="text-center text-bold text-body1">
        <span class="text-blue">
        <br /><br />
        <h5>Cart is Empty !</h5>
        <h6 class="text-grey">Last Minute Buy - SteelDalal Making Procurements hassle-free.</h6>
      </span>
        <q-btn class="text-white bg-primary" @click="goToAllProduct()">Click to Start Shopping</q-btn>
      </div>
      <div v-if="data != null">
        <q-card class="no-shadow q-pb-md">
          <q-card-section class="bg-grey text-white text-bold text-subtitle rounded-borders">
            <template v-if="!paymentExpired">
            Hurry!!, Cart will be expired : <q-badge :label="payTimeOut + ' minutes'" class="text-bold text-subtitle" size="15" color="black" />
            </template>
            <template v-if="paymentExpired">
              <span class="text-black">Cart Expired !</span>
            </template>
          </q-card-section>
        </q-card>
        <div v-for="(item, index) in items" v-bind:key="item">
          <q-card
            :id="index"
            :class="
              index % 2 == 0
                ? 'bg-green-1  rounded-borders'
                : 'bg-grey-4 rounded-borders'
            "
            no-shadow
          >
            <q-card-section>
              <div class="row">
                <div class="col">
                  <span class="text-caption text-bold">{{
                    item.mst_product.name
                  }}</span
                  ><br />
                  <q-chip
                    rounded
                    color="blue-2"
                    size="9px"
                    class="text-black text-caption"
                    :label="item.product.brand"
                  />
                  <q-chip
                    rounded
                    color="blue-2"
                    size="9px"
                    class="text-black text-caption"
                    :label="item.product.category"
                  />
                  <q-chip
                    rounded
                    color="blue-2"
                    size="9px"
                    class="text-black text-caption"
                    :label="item.product.grade"
                  />
                </div>
                <div class="col-2 text-right">
                  <q-btn
                    dense
                    icon="delete_outline"
                    size="md"
                    round
                    color="red-4"
                    @click="removeItem(item.api_id)"
                  />
                </div>
              </div>
              <q-separator />
              <div class="row q-pa-sm">
                <div class="col-4">
                  <q-avatar size="70px">
                    <img
                      no-shadow
                      :src="data.productPhotoURL + item.mst_product.img"
                    />
                  </q-avatar>
                </div>
                <div class="col">
                  <div class="text-caption text-italic text-black">
                    <span v-if="item.product.size_type == 'standard'">
                      <br />
                      {{ item.product_rate.size }} MM
                    </span>

                    <span v-if="item.product.size_type == 'coil'">
                      <q-badge squar color="grey-5 text-black" label="Width " />
                      {{
                        item.product_rate.width +
                        " " +
                        item.product_rate.width_uom
                      }}
                      <br />
                      <q-badge
                        squar
                        color="grey-5 text-black"
                        label="Thickness : "
                      />
                      {{
                        item.product_rate.thickness +
                        " " +
                        item.product_rate.thickness_uom
                      }}
                    </span>
                    <span
                      class="text-regular text-bold"
                      v-if="item.product.size_type == 'sheet'"
                    >
                      <q-badge
                        squar
                        color="grey-5 text-black"
                        label="Width : "
                      />
                      {{
                        item.product_rate.width +
                        " Ft"
                      }}
                      <br />
                      <q-badge
                        squar
                        color="grey-5 text-black"
                        label="Thickness : "
                      />
                      {{
                        item.product_rate.thickness +
                        " MM"
                      }}
                      <br />
                      <q-badge
                        squar
                        color="grey-5 text-black"
                        label="Length : "
                      />
                      {{
                        item.product_rate.length +
                        " Ft "                       }}
                    </span>
                    <span  v-if="item.product.mst_color_id > 0">
                        <br /> Color: {{ item.product.color_name }}
                    </span>
                    <span  v-if="item.product.cw_5_10 > 0 || item.product.cw_10_22 > 0 || item.product.cw_22_27 > 0">
                        <br /> Weight: 
                         <span v-if="item.product.cw_5_10 > 0 "> 5-10 ,</span> &nbsp;
                        <span v-if="item.product.cw_10_22 > 0 ">10-22 ,</span> &nbsp;
                        <span v-if="item.product.cw_22_27 > 0 ">22-27</span> &nbsp;
                    </span>
                  </div>
                </div>
              </div>
              <q-separator />
              <div class="row q-pt-sm">
                <div class="col-2 text-left">
                  <q-chip
                    square=""
                    color="green text-white text-bold"
                    :label="'#' + (index + 1)"
                  />
                </div>
                <div class="col text-right">
                  <span class="text-caption text-bold">
                    <!--
                    {{ item.qty }} {{ item.product_rate.qty_uom }} x
                    {{ item.price }} =
                    -->
                    {{ item.price }} x {{ item.qty }} MT
                     =
                  </span>
                  <q-chip
                    squar
                    icon="currency_rupee"
                    square=""
                    color="white text-blue text-bold"
                    :label="item.total"
                  />
                  <br /><span class="text-caption text-grey-9"><small>excl. 18% GST</small></span>
                </div>
              </div>
            </q-card-section>
          </q-card>
          <q-separator />
          <br />
        </div>
        <div class="q-pa-sm">
          <div class="row text-pink text-subtitle1">
            <div class="col text-right text-subtitle1">
              <span class="text-black">Total Cost</span>
            </div>
            <div class="col text-right text-bold">
              <q-icon name="currency_rupee" /> {{ total }} <br />
              <span class="text-caption text-grey-9"><small>excl. 18% GST</small></span>
            </div>
          </div>
          <q-separator />
          <br />
          <div class="row">
            <div class="col text-center text-subtitle text-bold">
              <q-icon name='fas fa-truck' size="30px" color="green"></q-icon>&nbsp;&nbsp;&nbsp;&nbsp;Order delivery time 2-5 days
              
              <!--
              <span class="text-grey-6 text-regular"
                >All above products sold by</span
              ><br />
              &nbsp;
              
              <b
                >{{ items[0].seller.trading_name }},
                {{ items[0].seller.city }}, {{ items[0].seller.state }}</b
              >
              <br />
              -->
            </div>
          </div>
        </div>
        <br /><br />
        <div class="row">
          <div class="col">
            <q-btn
              size="12px"
              rounded
              color="primary"
              label="Continue Shopping"
              @click="goToSeller()"
            />
            <!--
            <q-btn
              size="12px"
              rounded
              color="primary"
              label="Continue Shopping"
              @click="goToSellerProduct(items[0].seller.api_id)"
            />
            -->
          </div>
          <div class="col text-right">
            <q-btn
              size="12px"
              rounded
              color="green"
              label="Checkout >>"
              @click="goToCheckout()"
            />
          </div>
        </div>
      </div>
    </q-page>
  </q-page-container>
</template>
<script>
import { userService } from "../services/userService";
import { ref } from "vue";
import { useQuasar, Dialog } from "quasar";
import mitt from "mitt";
import { cartService } from "../services/cartService";

const emitter = mitt();

export default {
  name: "Cart",
  data() {
    return {
      isLogged: null,
      data: null,
      items: null,
      total: 0.0,
      gst: localStorage.getItem("gst"),
      paymentTimeRemain:null,
      payTimeOut:0,
      closeTimer: null,  
      paymentExpired:false,
    };
  },
  methods: {
    removeItem: function (api_id) {
      this.$q
        .dialog({
          title: "Confirm",
          message: "Are you sure to remove it ?",
          cancel: true,
          persistent: true,
        })
        .onOk(() => {
          // console.log('>>>> OK')
          emitter.on("onCartRemoveSuccess", this.onCartRemoveSuccess);
          emitter.on("onCartRemoveFail", this.onCartRemoveFail);
          cartService.removeFromCart({ api_id: api_id }, this, emitter);
          this.data = null;
          this.items = null;
        })
        .onCancel(() => {
          // console.log('>>>> Cancel')
        })
        .onDismiss(() => {
          // console.log('I am triggered on both OK and Cancel')
        });
    },
    onCartRemoveSuccess: function (data) {
      this.showInfo("Item removed.");
      //this.pullCartItems();
      this.$router.go();
    },
    onCartRemoveFail: function (error) {},
    onCartGetSuccess: function (data) {
      //console.log(data);
      if (data.data.length > 0) {
        this.data = data;
        this.items = data.data;
        for (let i = 0; i < this.items.length; i++) {
          this.total = parseFloat(this.total) + parseFloat(this.items[i].total);
        }
      } else {
        localStorage.setItem("cart.seller_id", "");
      }
      if(this.data.paymentValidity.expired=="false")
      {
        this.paymentTimeRemain = this.data.paymentValidity.time_remain;
        this.countDownTimer();
      }else{
        this.showError("Cart epxired.");
        //this.goToDashboard();
        this.paymentExpired = true;
      }
    },
    onCartGetFail: function (error) {
      //this.showError(error);
    },
    pullCartItems: function () {
      emitter.on("onCartGetSuccess", this.onCartGetSuccess);
      emitter.on("onCartGetFail", this.onCartGetFail);
      cartService.getCartItems("", this, emitter);
    },
    countDownTimer: function () {
      if (this.paymentTimeRemain > 0) {
        this.closeTimer = setTimeout(() => {
          this.paymentTimeRemain -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
  },
  created: function () {
    this.setPageTitle("Cart");
    this.setToolbarTitle("Cart");
  },
  mounted: function () {
    this.pullCartItems();
    this.isLogged = userService.isLogged();
  },
  unmounted: function () {
    emitter.off("onCartGetSuccess");
    emitter.off("onCartGetFail");
    emitter.off("onCartRemoveSuccess");
    emitter.off("onCartRemoveFail");
  },
  watch: {
    paymentTimeRemain: function () {
      //console.log(this.splashTimer);
      if(this.paymentTimeRemain!=null){
        if ((this.paymentTimeRemain == 1)) {
          clearInterval(this.closeTimer);
          this.showError("Cart time epxired.");
          this.paymentExpired = true;
          //this.goToDashboard();
        }
        this.payTimeOut = new Date(this.paymentTimeRemain * 1000).toISOString().substring(14, 19);
      }
    },
  },
  setup() {},
};
</script>
