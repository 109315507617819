import axiosLogged from "../helpers/axiosLogged";
import mitt from 'mitt';

function getProductList(ob, emitterObj) {
    ob.showLoading();
    axiosLogged.post("/account/products", "")
        .then(response => {
            ob.hideLoading();
            emitterObj.emit('onProductListSuccess', response.data.data.body);
        }).catch(error => {
            ob.hideLoading();
            emitterObj.emit('onProductListFail', Promise.reject(error));
        });
}

function getParams(ob, emitterObj) {
    ob.showLoading();
    axiosLogged.get("/account/product-params", "")
        .then(response => {
            ob.hideLoading();
            emitterObj.emit('onParamsSuccess', response.data.data.body);
        }).catch(error => {
            ob.hideLoading();
            emitterObj.emit('onParamsFail', Promise.reject(error));
        });
}

function getTMTParams(ob, payload, emitterObj) {
    ob.showLoading();
    axiosLogged.post("/account/tmt-params", payload)
        .then(response => {
            ob.hideLoading();
            emitterObj.emit('onTMTParamsSuccess', response.data.data.body.data);
        }).catch(error => {
            ob.hideLoading();
            emitterObj.emit('onTMTParamsFail', Promise.reject(error));
        });
}

function getSheetParams(ob, payload, emitterObj) {
    ob.showLoading();
    axiosLogged.post("/account/sheet-params", payload)
        .then(response => {
            ob.hideLoading();
            emitterObj.emit('onSheetParamsSuccess', response.data.data.body.data);
        }).catch(error => {
            ob.hideLoading();
            emitterObj.emit('onSheetParamsFail', Promise.reject(error));
        });
}

function getCoilParams(ob, payload, emitterObj) {
    ob.showLoading();
    axiosLogged.post("/account/coil-params", payload)
        .then(response => {
            ob.hideLoading();
            emitterObj.emit('onCoilParamsSuccess', response.data.data.body.data);
        }).catch(error => {
            ob.hideLoading();
            emitterObj.emit('onCoilParamsFail', Promise.reject(error));
        });
}

function getProduct(ob, payload, emitterObj) {
    ob.showLoading();
    axiosLogged.post("/account/product-detail", payload)
        .then(response => {
            ob.hideLoading();
            emitterObj.emit('onGetProductSuccess', response.data.data.body);
        }).catch(error => {
            ob.hideLoading();
            emitterObj.emit('onGetProductFail', Promise.reject(error));
        });
}

function getProductRate(ob, payload, emitterObj) {
    ob.showLoading();
    axiosLogged.post("/account/product-rate", payload)
        .then(response => {
            ob.hideLoading();
            emitterObj.emit('onGetProductRateSuccess', response.data.data.body);
        }).catch(error => {
            ob.hideLoading();
            emitterObj.emit('onGetProductRateFail', Promise.reject(error));
        });
}

function addProduct(ob, payload, emitterObj) {
    ob.showLoading();
    axiosLogged.post("/account/product-add", payload)
        .then(response => {
            ob.hideLoading();
            emitterObj.emit('onAddProductSuccess', response.data.data.body);
        }).catch(error => {
            ob.hideLoading();
            emitterObj.emit('onAddProductFail', Promise.reject(error));
        });
}

function addProductRate(ob, payload, emitterObj) {
    ob.showLoading();
    axiosLogged.post("/account/product-rate-add-edit", payload)
        .then(response => {
            ob.hideLoading();
            if(response.data.data.head.success=="true"){
                emitterObj.emit('onAddProductRateSuccess', response.data.data.body);
            }else{
                emitterObj.emit('onAddProductRateFail', response.data.data.body.error.msg);    
            }
        }).catch(error => {
            ob.hideLoading();
            emitterObj.emit('onAddProductRateFail', Promise.reject(error));
        });
}

export const productService = {
    getProductList,
    getParams,
    getTMTParams,
    addProduct,
    addProductRate,
    getProduct,
    getProductRate,
    getSheetParams,
    getCoilParams
};