<template>
  <q-page-container class="bg-container">
    <q-page class="q-pa-xs-md q-gutter-md">
      <div class="row">
        <div class="col">
          <!--
          <q-input
            v-model="search"
            outlined
            dense
            rounded
            placeholder="Search Products"
          >
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
          -->
        </div>
      </div>
      <p
        class="text-center text-bold text-red"
        v-show="kycSubmitted && !kycVerified"
      >
        <br /><br />
        Your KYC is under review. Once KYC Verfied, You are able to sell and buy
        products.
      </p>

      <div
        class="text-center text-black"
        v-show="!kycSubmitted && !kycVerified"
      >
        <br /><br />
        Complete your Shop
        <a @click="goToKyc()" class="text-red text-bold">
          <span class="text-blue">KYC</span></a
        >
        to sell and buy product
      </div>
      <div v-show="kycVerified">
        <!--
        <q-card
          class="my-card c-bg-color text-center text-bold text-white"
          style="height:50px;"
          flat
        >
          <div class="row" >
            <div class="col"><br />+</div>
            <div class="col"><br />Detail</div>
            <div class="col text-center"><br />Act<br /><br /></div>
          </div>
          <div class="row">
              <div class="col text-green">
                <br /> <br />
                  There is  no product added by you !! <br />
              </div>
          </div>
        </q-card>
           -->
        <div class="row" v-if="products==null || products.data == null || products.data.length <=0">
              <div class="col text-green text-body1 text-center text-bold">
                <br /> <br />
                  Opps !! <br />
                  There is  no product added by you !! <br />
              </div>
          </div>
        <q-card class="my-card no-shadow" v-if="products !==null ">
          <q-card-section
            class="text-bold"
            v-for="item in products.data"
            v-bind:key="item"
          >
            <div class="row">
              <div class="col">
                <q-btn round no-shadow>
                  <q-avatar size="80px" no-shadow>
                    <img :src="products.productPhotoURL + item.mst_product.img" style="width:auto" />
                  </q-avatar>
                </q-btn>
                <br /><br />
              </div>
              <div class="col text-left">
                {{ item.product }} - <span class="text-grey text-caption">({{ item.category}})</span><br />
                <span class="text-grey">{{ item.brand}}</span> <br />
                <q-badge color="red" :label="'Price Valid : '+ item.price_valid_date" />
              </div>
              <div class="col text-right text-red" v-if="item.size_type=='standard'">
                <q-btn color="green" rounded size="9px" label="edit" @click="goToProductAddRateTMT(item.api_id)" />
              </div>
              <div class="col text-right text-red" v-if="item.size_type=='sheet'">
                <q-btn color="green" rounded size="9px" label="edit" @click="goToProductAddRateSheet(item.api_id)" />
              </div>
              <div class="col text-right text-red" v-if="item.size_type=='coil'">
                <q-btn color="green" rounded size="9px" label="edit" @click="goToProductAddRateCoil(item.api_id)" />
              </div>
            </div>
            <q-separator />
          </q-card-section>
        </q-card>

        <!-- product add button -->
        <q-page-sticky position="bottom-right" :offset="[18, 18]">
          <q-btn fab icon="add" color="red" @click="goToProductAdd()" />
        </q-page-sticky>
      </div>
    </q-page>
  </q-page-container>
</template>
<script>
import { userService } from "../../../services/userService";
import { productService } from "../../../services/productService";
import mitt from "mitt";

const emitter = mitt();

export default {
  name: "ProductPage",
  data() {
    return {
      kycSubmitted: false,
      kycVerified: false,
      products: null,
    };
  },
  created: function () {
    
    emitter.on("onProductListSuccess",this.onProductListSuccess)
    emitter.on("onProductListFail",this.onProductListFail)
    productService.getProductList(this,emitter);
  },
  beforeMount: function () {
    this.showLoading();
  },
  mounted: function () {
    this.setToolbarTitle("My Products");
    this.setPageTitle("My Products");
    this.kycSubmitted =
      userService.getValue("kyc_submitted") == "yes" ? true : false;
    this.kycVerified =
      userService.getValue("kyc_approved") == "yes" ? true : false;

  },
  unmounted: function(){
      emitter.off("onProductListSuccess");
      emitter.off("onProductListFail");
  },
  methods:{
      onProductListSuccess: function(data){
          //console.log("onProductListSuccess==================================");
          //console.log(data);
          this.products = data;
      },
      onProductListFail: function(data){
          //console.log("onProductListSuccess==================================");
          //console.log(data);
          //this.showError(data);
      }
  }
};
</script>
