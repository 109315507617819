<template>
  <q-page-container>
    <q-page class="q-pa-xs-md">
      <div v-if="seller != null">
        <div class="row">
          <div class="col">
            <q-btn round flat>
              <q-avatar size="100px">
                <img src="../../assets/img/splash.jpg" style="width: auto" />
              </q-avatar>
            </q-btn>
          </div>
          <div class="col text-right q-pa-md" v-if="data.data.order_num != ''">
            <!--order reciept-->
            <br />
            <q-btn
              icon="file_download"
              size="9px"
              color="green"
              label="Download"
              @click="
                downloadOrderReceipt(data.data.api_id, data.data.order_num)
              "
            />
          </div>
        </div>
        <q-separator />

        <q-list>
          <q-item v-if="data.data.order_num != ''">
            <q-item-section class="q-pa-xs text-bold">Order #</q-item-section>
            <q-item-section class="text-left">
              <span class="text-uppercase">{{ data.data.order_num }} </span>
            </q-item-section>
          </q-item>
          <q-separator />
          <q-item>
            <q-item-section class="q-pa-xs text-bold">Customer</q-item-section>
            <q-item-section class="text-left">
              <span class="text-uppercase"
                >{{ data.data.customer.business_name }}
              </span>
            </q-item-section>
          </q-item>
          <q-separator />
          <q-item>
            <q-item-section class="q-pa-xs text-bold"
              >City / State</q-item-section
            >
            <q-item-section class="text-left">
              <span
                >{{ data.data.customer.city }} ,
                {{ data.data.customer.state }}</span
              >
            </q-item-section>
          </q-item>
          <q-separator />
          <q-item>
            <q-item-section class="q-pa-xs text-bold">Status</q-item-section>
            <q-item-section class="text-left text-bold">
              <span
                ><q-chip square color="blue" class="text-white">{{
                  data.data.status
                }}</q-chip></span
              >
            </q-item-section>
          </q-item>
          <q-separator />
        </q-list>
      </div>
      <br />
      <div class="text-body1 text-center text-bold text-primary q-pa-md">
        Order Items
      </div>
      <div v-if="data != null">
        <div v-for="(item, index) in items" v-bind:key="item">
          <!--
       
        <q-card-section>
            
        </q-card-section>
        </q-card>
         -->
          <q-card
            class="my-card no-shadow"
            :id="index"
            :class="
              index % 2 == 0
                ? 'bg-green-1  rounded-borders'
                : 'bg-grey-4 rounded-borders'
            "
            no-shadow
          >
            <q-card-section>
              <div class="row">
                <div class="col">
                  <b>{{ item.product }} </b> -
                  <span class="text-caption">{{ item.brand }}</span
                  ><br />
                  <span
                    class="text-caption text-italic text-grey"
                    v-if="item.size_type == 'standard'"
                  >
                    {{ item.size }} MM</span
                  >
                  <span
                    class="text-regular text-bold"
                    v-if="item.size_type == 'coil'"
                  >
                    <q-badge squar color="grey-5 text-black" label="W : " />
                    {{ item.width + " " + item.width_uom }} <br />
                    <q-badge squar color="grey-5 text-black" label="T : " />
                    {{ item.thickness + " " + item.thickness_uom }}
                  </span>
                  <span
                    class="text-regular text-bold"
                    v-if="item.size_type == 'sheet'"
                  >
                    <q-badge squar color="grey-5 text-black" label="W : " />
                    {{ item.width + " " + item.width_uom }} <br />
                    <q-badge squar color="grey-5 text-black" label="L : " />
                    {{ item.length + " " + item.length_uom }} <br />
                    <q-badge squar color="grey-5 text-black" label="T : " />
                    {{ item.thickness + " " + item.thickness_uom }}
                  </span>
                  <span class="text-caption">
                    <br />
                    Grade: {{ item.grade }}
                  </span>
                  <span class="text-caption" v-if="item.color_name">
                    <br />
                    Color: {{ item.color_name }}
                  </span>
                  <span
                    class="text-caption"
                    v-if="
                      item.cw_5_10 > 0 || item.cw_10_22 > 0 || item.cw_22_27 > 0
                    "
                  >
                    <br />
                    Weight:
                    <span v-if="item.cw_5_10 > 0"> 5-10 ,</span>
                    <span v-if="item.cw_10_22 > 0">10-22 ,</span>
                    <span v-if="item.cw_22_27 > 0">22-27</span>
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col text-right">
                  <q-separator />

                  <q-chip
                    square=""
                    size="10px"
                    class="text-white"
                    color="grey-7"
                    :label="'Qty: ' + item.qty + ' ' + item.qty_uom"
                  />
                  X

                  <q-chip
                    size="10px"
                    square=""
                    class="text-white"
                    color="grey-7"
                    :label="'Price: ' + item.price"
                  />
                  =
                  <q-chip
                    squar
                    icon="currency_rupee"
                    square=""
                    color="white text-blue text-bold"
                    :label="item.total"
                  />
                </div>
              </div>
            </q-card-section>
          </q-card>
          <q-separator />
          <br />
        </div>
        <div class="q-pr-md">
          <div class="row">
            <div
              class="col text-right text-caption text-bold text-uppercase"
              style="width: auto"
            >
              Sub Total
            </div>
            <div class="col text-right text-bold text-subtitle1 text-pink">
              <q-icon name="currency_rupee" /> {{ data.data.sub_total }}
            </div>
          </div>
          <q-separator />
        </div>
        <div class="q-pr-md" v-if="data.data.igst_amount > 0">
          <div class="row">
            <div
              class="col text-right text-caption text-bold text-uppercase"
              style="width: auto"
            >
              IGST @ {{ data.data.igst_percent }} %
            </div>
            <div class="col text-right text-bold text-subtitle1 text-pink">
              <q-icon name="currency_rupee" /> {{ data.data.igst_amount }}
            </div>
          </div>
          <q-separator />
        </div>
        <div class="q-pr-md" v-if="data.data.cgst_amount > 0">
          <div class="row">
            <div
              class="col text-right text-caption text-bold text-uppercase"
              style="width: auto"
            >
              CGST @ {{ data.data.cgst_percent }} %
            </div>
            <div class="col text-right text-bold text-subtitle1 text-pink">
              <q-icon name="currency_rupee" /> {{ data.data.cgst_amount }}
            </div>
          </div>
          <q-separator />
        </div>
        <div class="q-pr-md" v-if="data.data.sgst_amount > 0">
          <div class="row">
            <div
              class="col text-right text-caption text-bold text-uppercase"
              style="width: auto"
            >
              CGST @ {{ data.data.cgst_percent }} %
            </div>
            <div class="col text-right text-bold text-subtitle1 text-pink">
              <q-icon name="currency_rupee" /> {{ data.data.cgst_amount }}
            </div>
          </div>
          <q-separator />
        </div>
        <div class="q-pr-md" v-if="data.data.convenience_fee > 0">
          <div class="row">
            <div
              class="col text-right text-caption text-bold text-uppercase"
              style="width: auto"
            >
              Convenience Fee
            </div>
            <div class="col text-right text-bold text-subtitle1 text-pink">
              <q-icon name="currency_rupee" /> {{ data.data.convenience_fee }}
            </div>
          </div>
          <q-separator />
        </div>
        <div class="q-pr-md" v-if="data.data.delivery_charges > 0">
          <div class="row">
            <div
              class="col text-right text-caption text-bold text-uppercase"
              style="width: auto"
            >
              Delivery Charges
            </div>
            <div class="col text-right text-bold text-subtitle1 text-pink">
              <q-icon name="currency_rupee" /> {{ data.data.delivery_charges }}
            </div>
          </div>
          <q-separator />
        </div>
        
        <div class="q-pr-md">
          <div class="row">
            <div
              class="col text-right text-caption text-bold text-uppercase"
              style="width: auto"
            >
              Total Amount
            </div>
            <div class="col text-right text-bold text-subtitle1 text-pink">
              <q-icon name="currency_rupee" /> {{ data.data.total_amount }}
            </div>
          </div>
          <q-separator />
        </div>
        <br />
        <!-- for order process-->
        <div v-if="order != null">
          <div v-if="order.status=='pending'">
          <q-input
            outlined
            label="Delivery Charges"
            type="number"
            step="any"
            hint=""
            v-model="form.delivery_charges"
            input-class="text-right text-bold text-red text-subtitle1"
            :rules="[(val) => !!val || 'Required city name.']"
          />
          <div class="row">
            <div class="col text-right text-bold text-subtitle1 text-blue">
              Grand Total :
              {{
                parseFloat(data.data.total_amount) +
                parseFloat(
                  form.delivery_charges != "" ? form.delivery_charges : 0
                )
              }}
            </div>
          </div>
          <q-separator />

          <div class="row q-pt-md q-pb-md">
            <div class="col text-center">
              <!--confirm order-->
              <q-btn
                v-if="order.status != 'confirm' && order.status != 'declined'"
                rounded
                size="11px"
                color="green"
                label="Confirm Order ?"
                @click="onOrderConfirm(order.api_id)"
              />
            </div>
            <div class="col text-center">
              <!--declined order-->
              <q-btn
                v-if="order.status != 'confirm' && order.status != 'declined'"
                rounded
                size="11px"
                color="red"
                label="Decliend Order ?"
                @click="onOrderDecliend(order.api_id)"
              />
            </div>
          </div>
        </div>
        </div>
        <!--//end order process-->
       
      </div>
    </q-page>
  </q-page-container>
</template>
<script>
import { userService } from "../../services/userService";
import mitt from "mitt";
import { orderService } from "../../services/orderService";

const emitter = mitt();

export default {
  name: "OrderRecievedViewPage",
  data() {
    return {
      isLogged: null,
      data: null,
      items: null,
      seller: null,
      order: null,
      form: {
        delivery_charges: 0.0,
        status: "pending",
      },
    };
  },
  methods: {
    onGetOrderSuccess: function (data) {
      //console.log(data);
      if (data != "undefined") {
        this.data = data;
        this.items = data.data.order_details;
        this.seller = data.data.seller;
        this.order = data.data;
        console.log(this.data.data.status);
      }
    },
    onGetOrderFail: function (error) {
      this.showError(error);
    },
    downloadOrderReceipt: function (api_id, order_num) {
      this.order_num = order_num;
      //emitter.on("onOrderReceiptSuccess", this.onOrderReceiptSuccess);
      //emitter.on("onOrderReceiptFail", this.onOrderReceiptFail);
      //orderService.orderReceipt(api_id, this, emitter);
      //window.location = process.env.VUE_APP_API_URL+"/order/order-receipt?api_id=" + api_id;
      orderService.downloadOrderReceipt(api_id, userService.getAuthToken());
    },

    onOrderConfirm: function (api_id) {
      emitter.on(
        "onRecievedOrderConfirmSuccess",
        this.onRecievedOrderConfirmSuccess
      );
      emitter.on(
        "onRecievedOrderConfirmFail",
        this.onRecievedOrderConfirmSuccess
      );
      orderService.recievedOrderConfirm({ api_id: api_id,delivery_charges: this.form.delivery_charges }, this, emitter);
    },

    onOrderDecliend: function (api_id) {
      emitter.on(
        "onRecievedOrderDeclinedSuccess",
        this.onRecievedOrderDeclinedSuccess
      );
      emitter.on(
        "onRecievedOrderDeclinedFail",
        this.onRecievedOrderDeclinedFail
      );
      orderService.recievedOrderDeclined({ api_id: api_id }, this, emitter);
    },

    onRecievedOrderConfirmSuccess: function (data) {
      console.log(data);
      //this.goToOrderRecieved();
      this.pullOrders();
    },

    onRecievedOrderConfirmFail: function (error) {
      this.showError(error);
    },
    onRecievedOrderDeclinedSuccess: function (data) {
      console.log(data);
      //this.goToOrderRecieved();
      this.pullOrders();
    },

    onRecievedOrderDeclinedFail: function (error) {
      this.showError(error);
    },
    pullOrders: function () {
      this.goToOrderRecieved();
    },
  },
  created: function () {
    this.setPageTitle("Order View");
    this.setToolbarTitle("Order View");
  },
  mounted: function () {
    emitter.on("onGetOrderSuccess", this.onGetOrderSuccess);
    emitter.on("onGetOrderFail", this.onGetOrderFail);
    orderService.getOrder({ api_id: this.$route.query._id }, this, emitter);
    this.isLogged = userService.isLogged();
  },
  unmounted: function () {
    emitter.off("onGetOrderSuccess");
    emitter.off("onGetOrderFail");
    emitter.off("onOrderReceiptSuccess");
    emitter.off("onOrderReceiptFail");

    emitter.off("onRecievedOrderDeclinedSuccess");
    emitter.off("onRecievedOrderDeclinedFail");

    emitter.off("onRecievedOrderConfirmSuccess");
    emitter.off("onRecievedOrderConfirmFail");
  },
  setup() {},
};
</script>
