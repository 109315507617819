export default {
    data() {
        return {
            NOTIFY_INFO_MSG:null,
            NOTIFY_ERROR_MSG:null,
            _toolbarTitle: process.env.VUE_APP_TITLE,
        };
    },
    methods: {
        getPageTitle: function (vm) {
            const { title } = vm.$options
            if (title) {
                return typeof title === 'function'
                    ? title.call(vm)
                    : title
            }
        },
        setPageTitle: function (title) {
            document.title = process.env.VUE_APP_TITLE + " - " + title;
        },
        getToolbarTitle: function () {
            return this._toolbarTitle;
        },
        setToolbarTitle: function (title) {
            this._toolbarTitle = title;
            this.$emit("updateToolbarTitle", title)
        },

        /*** Loading ****************************************************/
        showLoading: function () {
            this.$q.loading.show({
                //message: 'Some important process  is in progress. Hang on...'
            });
        },
        hideLoading: function () {
            this.$q.loading.hide();
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        showError: function(msg){
            if(this.NOTIFY_ERROR_MSG){
                this.NOTIFY_ERROR_MSG();
            }
            this.NOTIFY_ERROR_MSG = this.$q.notify({
                type: 'negative',
                position:'top',
                message: msg,
                noDismiss: false,
                timeout:1000,
                closeBtn:false
            });
        },
        showInfo: function(msg)
        {
            if(this.NOTIFY_INFO_MSG){
                this.NOTIFY_INFO_MSG();
            }

            this.NOTIFY_INFO_MSG= this.$q.notify({
                type: 'info',
                color:"green",
                textColor:'white',    
                position:'top',
                message: msg,
                noDismiss: false,
                timeout:1000,
                closeBtn:false
            });
        },
        /*** End Loading ****************************************************/


        /*** Navigation ****************************************************/
        //goToLogin: function () {
            //this.$router.push({ name: "Login" });
        //    this.$router.push({ name: "Home" });
        //},

        //new added
        goToPrivacyPolicy: function () {
            this.$router.push({ name: 'PrivacyPolicy' });
        },
        goToAboutUs: function () {
            this.$router.push({ name: 'AboutUs' });
        },
        goToHowItWorks: function () {
            this.$router.push({ name: 'HowItWorks' });
        },
        goToBecomeSeller: function () {
            this.$router.push({ name: 'BecomeSeller' });
        },
        goToFAQ: function () {
            this.$router.push({ name: 'FAQ' });
        },
        //end new added
        goToHome: function () {
            this.$router.push({ name: "Home" });
        },
        goToRegister: function () {
            this.$router.push({ name: "Register" });
        },
        goToForgotPassword: function () {
            this.$router.push({ name: "ForgotPassword" });
        },
        goToDashboard: function () {
            this.$router.push({ name: "Dashboard" });
        },
        goToLogout: function () {
            this.$router.push({ name: "Logout" });
        },
        myTest: function () {
            alert("hi");
        },
        goToSearch: function () {
            this.$router.push({ name: "Search" });
        },
        goToSellerProductRate: function (_id,_qty,_materialType,thickness) {
            this.$router.push({ name: "SellerProductRate",query:{'_id':_id,"_qty":_qty,
            "_material":_materialType,"_thickness":thickness}});
        },
        goToAllProduct: function () {
            this.$router.push({ name: "AllProduct"});
        },
        goToProductDetail: function () {
            this.$router.push({ name: "ProductDetail"});
        },
        goToCart: function () {
            this.$router.push({ name: "Cart" });
        },
        goToCalculatePrice: function () {
            this.$router.push({ name: "CalculatePrice" });
        },
        
        goToCheckout: function () {
            this.$router.push({ name: "Checkout" });
        },
        goToRequiredDemo: function () {
            this.$router.push({ name: "RequiredDemo" });
        },
        goToKyc: function () {
            this.$router.push({ name: "Kyc" });
        },
        goToOrderRecieved: function () {
            this.$router.push({ name: "OrderRecieved" });
        },
        goToOrderRecievedView: function (_id) {
            this.$router.push({ name: "OrderRecievedView",query:{'_id':_id} });
        },
        goToMyBiz: function () {
            this.$router.push({ name: "MyBiz" });
        },
        goToOrderPlacedView: function (_id) {
            this.$router.push({ name: "OrderPlacedView",query:{'_id':_id}});
        },
        goToOrderPlacedMessage: function (_id) {
            this.$router.push({ name: "OrderPlacedMessage",query:{'_id':_id} });
        },
        goToOrderPlaced: function () {
            this.$router.push({ name: "OrderPlaced" });
        },
        goToFinanceSupport: function () {
            this.$router.push({ name: "FinanceSupport" });
        },
        goToTransportSupport: function () {
            this.$router.push({ name: "TransportSupport" });
        },
        goToContactUs: function () {
            this.$router.push({ name: "ContactUs" });
        },
        goToShortInventory: function () {
            this.$router.push({ name: "ShortInventory" });
        },
        goToShipment: function () {
            this.$router.push({ name: "Shipment" });
        },
        goToX: function (iname) {
            this.$router.push({ name: iname });
        },
        goToProduct: function () {
            this.$router.push({ name: 'Product' });
        },
        goToProductAdd: function () {
            this.$router.push({ name: 'ProductAdd' });
        },
        goToProductAddRateTMT: function (_id) {
            this.$router.push({ name: 'ProductAddRateTMT',query:{'_id':_id} });
        },
        goToProductAddRateSheet: function (_id) {
            this.$router.push({ name: 'ProductAddRateSheet',query:{'_id':_id} });
        },
        goToProductAddRateCoil: function (_id) {
            this.$router.push({ name: 'ProductAddRateCoil',query:{'_id':_id} });
        },
        goToSeller: function () {
            this.$router.push({ name: 'Seller' });
        },
        goToBrands: function (_product) {
            this.$router.push({ name: 'Seller',query:{'_product':_product} });
        },
        goToSellerSearch: function (keyword,type) {
            this.$router.push({ name: 'Seller',query:{'keyword':keyword,'in':type}});
        },
        goToSellerProduct: function (_id,_product) {
            this.$router.push({ name: 'SellerProduct',query:{'_id':_id,"_product":_product} });
        },
        goToPayment: function (_id) {
            this.$router.push({ name: 'Payment',query:{'_id':_id} });
        },
        goToPaymentMessage: function () {
            this.$router.push({ name: 'PaymentMessage'});
        },
        goToNotification: function () {
            this.$router.push({ name: 'Notification'});
        },

        goToGenerateInvoice: function (_id) {
            this.$router.push({ name: 'GenerateInvoice',query:{'_id':_id} });
        },
        goToInvoiceView: function (_id) {
            this.$router.push({ name: 'InvoiceView',query:{'_id':_id} });
        },
        formatDate: function(dt){
           var date = new Date(dt);
            console.log(date);
            var months =  ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            return date.getUTCDate() + ' ' + months[date.getUTCMonth()] 
            + ' ' + date.getUTCFullYear();
        },
        formatTime: function(dt){
            var date = new Date(dt);
             console.log(date);
             var months =  ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                     'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
             /*return date.getUTCHours() + ' : ' + date.getUTCMinutes()  
             + ' : ' + date.getUTCSeconds();*/
             //new
             return date.toLocaleString([], {
                hour: '2-digit',
                minute: '2-digit'
            });
            //end new
         }


        /*** End Navigation ****************************************************/

    }
}