import axios from 'axios';
import Quasar from 'quasar';

const ax = axios;
ax.interceptors.request.use(req => {
    req.baseURL = process.env.VUE_APP_API_URL;
    req.headers['Content-Type'] = 'application/json;charset=utf-8';
    req.headers["app"] = "android";
    return req;
}, res => {
    return res;
});
export default ax;
