import axiosNonLogged from "../helpers/axiosNonLogged";

function getToc(ob, emitterObj) {
    ob.showLoading();
    axiosNonLogged.post("/cms/toc", "")
        .then(response => {
            ob.hideLoading();
            emitterObj.emit('onTocSuccess', response.data.data.body);
        }).catch(error => {
            ob.hideLoading();
            emitterObj.emit('onTocFail', Promise.reject(error));
        });
}

function blogPosts(ob, emitterObj){
    ob.showLoading();
    axiosNonLogged.post("/blog/posts", "")
    .then(response => {
        ob.hideLoading();
        emitterObj.emit('onBlogPostSuccess', response.data.data.body);
    }).catch(error => {
        ob.hideLoading();
        emitterObj.emit('onBlogPostFail', Promise.reject(error));
    });
}

function getBanners(ob, emitterObj){
    ob.showLoading();
    axiosNonLogged.post("/cms/banners", "")
    .then(response => {
        ob.hideLoading();
        emitterObj.emit('onBannerSuccess', response.data.data.body);
    }).catch(error => {
        ob.hideLoading();
        emitterObj.emit('onBannerFail', Promise.reject(error));
    });
}

export const cmsService = {
    getToc,
    blogPosts,
    getBanners
};