<template>
  <q-page-container class="bg-container">
    <q-page class="q-pa-xs-md q-gutter-md">
      <div class="text-center text-bold text-h4 text-grey"  v-if="orders==null || orders.length =='0'">
            <br /><br /><br /><br />
            There is no recent placed order(s) found !!.
      </div>
      <div class="row" v-if="data != null">
        <div class="col">
          <q-card class="no-shadow">
            <div v-for="(item, index) in data.data" v-bind:key="item">
              <q-card-section
                :class="
                  index % 2 == 0
                    ? 'bg-green-1  rounded-borders'
                    : 'bg-grey-4 rounded-borders'
                "
              >
              <!-- New -->
              <div class="row col-12">
              <div class="col-9">
              <div class="row">
                  <div class="col">
                    <div class="col text-caption text-bold text-left">
                      <q-badge color="grey">
                        Order Date: {{formatDate(item.created_at) }} {{formatTime(item.created_at) }}
                      </q-badge>
                    </div>
                  </div>
                </div>
              <!-- End New -->
                <div class="row" v-if="item.is_paid == 'yes'">
                  <div class="col">
                    <div class="col text-caption text-bold text-left">
                      <q-badge
                        :label="'Order # ' + item.order_num"
                        color="grey"
                      />
                    </div>
                  </div>
                  
                </div>
                <div class="row">
                  <div class="col q-pa-sm q-gutter-sm">
                    <span class="text-bold color-blue text-uppercase">
                    {{item.seller.business_name}}
                    </span><br />
                    <span class="text-grey text-weight-light"
                      >{{ item.seller.city }}, {{ item.seller.state }}</span
                    >
                    </div>
                </div>
                <q-separator />
                <div class="row">
                  <div class="col q-pa-sm q-gutter-sm">   
                    <q-badge
                      :label="'Rs. ' + item.total_amount"
                      color="red-6"
                    />
                   
                   <q-badge
                       v-if="item.order_num=='' || item.order_num==null"
                      :label="'Status: ' + item.status"
                      color="green-6"
                    />

                    <q-badge
                       v-if="item.order_num=='' || item.order_num==null"
                      :label="item.is_paid == 'no' ? 'unpaid' : 'paid'"
                      :color="item.is_paid == 'no' ? 'grey-6' : 'green'"
                    />

                    <q-badge
                      v-if="
                        item.delivery_status == 'in-loading' ||
                        item.delivery_status == 'shipped' ||
                        item.delivery_status == 'received'
                      "
                      size="12px"
                      icon="done_all"
                      :label="'Order  ' + item.delivery_status"
                      color="green"
                    />
                  </div>
                </div>
                <q-separator />
                <div class="q-pt-sm q-gutter-sm text-center">
                  <q-btn
                     icon="pageview"
                    rounded
                    size="9px"
                    color="blue"
                    label="View Order"
                    @click="goToOrderPlacedView(item.api_id)"
                  />
             
                  <!-- order recipt-->
                  

                  <!--make payment-->
                  <q-btn
                    v-if="item.status == 'confirm' && item.is_paid == 'no'"
                    rounded
                    size="9px"
                    color="blue"
                    label="Make Payment"
                    @click="makePayment(item.api_id)"
                  />

                  <template v-if="item.crdr_note">
                    <!--drcr note pay-->
                    <q-btn
                      v-if="
                        item.crdr_note != 'null' &&
                        item.crdr_note.paid_to == 'seller' &&
                        item.crdr_note.status == 'pending'
                      "
                      rounded
                      size="9px"
                      color="red"
                      label="Debit Note"
                      @click="payCrNote(item.crdr_note.amount,item.crdr_note.api_id)"
                    />

                    <!--drcr note recieve-->
                    <q-btn
                      v-if="
                        item.crdr_note != 'null' &&
                        item.crdr_note.paid_to == 'customer' &&
                        item.crdr_note.status == 'pending'
                      "
                      rounded
                      size="9px"
                      color="blue"
                      label="Credit Note"
                      @click="showCrNote(item.crdr_note.amount)"
                    />
                  </template>

                  <!--view Invoice-->
                  <q-btn
                    v-if="item.is_invoiced == '1'"
                    icon="pageview"
                    rounded
                    size="9px"
                    color="blue"
                    label="Invoice View"
                    @click="goToInvoiceView(item.invoice.api_id)"
                  />

                  <!--order recieved confirmation-->
                  <q-btn
                    v-if="item.delivery_status == 'shipped'"
                    rounded
                    size="9px"
                    color="blue"
                    label="Order Received"
                    @click="onChangeDeliveryStatus(item.api_id)"
                  />
                </div>
              </div>
              <!-- new -->
              <div class="col-3">
                <!-- new -->
                  <div class="col-12">
                    <div class="col text-caption text-bold text-left">
                      <!--<q-chip class="glossy" square dense size="sm">Add to calendar</q-chip>-->
                      <q-chip class="glossy full-width"  dense size="sm" label="Pending" :color="item.status == 'confirm' || item.status == 'pending'? 'green'  : ''" text-color="" :icon="item.status == 'confirm' || item.status == 'pending'? 'check'  : '&#215;'"/>
                      <q-chip class="glossy full-width"  dense size="sm" label="Confirm" :color="item.status == 'confirm' ? 'green' : ''" text-color="" :icon="item.status == 'confirm'? 'check'  : '&#215;'"/>
                      <q-chip class="glossy full-width"  dense  size="sm" label="Paid" :color="item.is_paid == 'yes' ? 'green' : ''" text-color="" :icon="item.is_paid == 'yes' ? 'check'  : '&#215;'"/>
                      <q-chip class="glossy full-width"  dense size="sm" label="InLoading" :color="item.delivery_status == 'in-loading' || item.delivery_status == 'shipped' || item.delivery_status == 'received' ? 'green' : ''" text-color="" :icon="item.delivery_status == 'in-loading' || item.delivery_status == 'shipped' || item.delivery_status == 'received'? 'check'  : '&#215;'"/>
                      <q-chip class="glossy full-width"  dense size="sm" label="Shipped" :color="item.delivery_status == 'shipped'  || item.delivery_status == 'received' ? 'green' : ''" text-color="" :icon="item.delivery_status == 'shipped'  || item.delivery_status == 'received' ? 'check'  : '&#215;'"/>
                      <q-chip class="glossy full-width"  dense size="sm" label="Received" :color="item.delivery_status == 'received' ? 'green' : ''" text-color="" :icon="item.delivery_status == 'received' ? 'check'  : '&#215;'"/>
                    </div>
                  </div>
                  <!-- end new -->
              </div>
              </div>
              <!-- end new -->

              </q-card-section>
            </div>            
            <!-- -->
          </q-card>
        </div>
      </div>
    </q-page>
  </q-page-container>
</template>
<script>
import { userService } from "../../services/userService";
import { ref } from "vue";
import { useQuasar, Dialog } from "quasar";
import mitt from "mitt";
import { orderService } from "../../services/orderService";
import { invoiceService } from "../../services/invoiceService";

const emitter = mitt();

export default {
  name: "OrderPlacedPage",
  data() {
    return {
      isLogged: false,
      data: null,
      order_num: null,
      orders:null,
    };
  },
  methods: {
    pullOrders: function () {
      emitter.on("onPlacedOrderListSuccess", this.onPlacedOrderListSuccess);
      emitter.on("onPlacedOrderListFail", this.onPlacedOrderListFail);
      orderService.placedOrderList(this, emitter);
    },
    onPlacedOrderListSuccess: function (data) {
      this.data = data;
      this.orders = data.data;
    },

    onPlacedOrderListFail: function (error) {
      this.showError(error);
    },
    makePayment: function (_id) {
      this.goToPayment(_id);
    },
    onChangeDeliveryStatus: function (__api_id) {
      emitter.on("onOrderDeliveredSuccess", this.onOrderDeliveredSuccess);
      emitter.on("onOrderDeliveredFail", this.onOrderDeliveredFail);
      orderService.orderDelivered({ api_id: __api_id }, this, emitter);
    },
    onOrderDeliveredSuccess: function (data) {
      this.pullOrders();
      this.showInfo("Order Status Changed");
    },
    onOrderDeliveredFail: function (error) {
      this.showError("Unable to change order status.");
    },
    downloadOrderReceipt: function (api_id, order_num) {
      this.order_num = order_num;
      emitter.on("onOrderReceiptSuccess", this.onOrderReceiptSuccess);
      emitter.on("onOrderReceiptFail", this.onOrderReceiptFail);
      orderService.orderReceipt(api_id, this, emitter);
    },
    onOrderReceiptSuccess: function (data) {
      //console.log(data);
      /*
       let blob = new Blob([data.data], { type: 'application/pdf' });
       let link = document.createElement('a');
       document.body.appendChild(link);
       link.style = "display: none";
       link.href = window.URL.createObjectURL(blob);
       link.download = this.order_num + '.pdf';
       link.click();
       window.URL.revokeObjectURL( link.href);
       */
      const blob = new Blob([data.data], { type: "application/pdf" });
      var filename = this.order_num + ".pdf";
      if (window.navigator["msSaveOrOpenBlob"]) {
        window.navigator["msSaveBlob"](blob, filename);
      } else {
        const elem = window.document.createElement("a");
        elem.href = window.URL.createObjectURL(blob);
        elem.download = filename;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      }
    },
    onOrderReceiptFail: function (error) {
      console.log(error);
    },
    showCrNote: function (amount) {
      this.$q
        .dialog({
          title: "Credit Note",
          message: "Amount : " + amount + " will be settled by seller.",
        })
        .onOk(() => {
          // console.log('OK')
        })
        .onCancel(() => {
          // console.log('Cancel')
        })
        .onDismiss(() => {
          // console.log('I am triggered on both OK and Cancel')
        });
    },
    payCrNote: function (amount,api_id) {
      this.$q.dialog({
          title: 'Paid To Seller',
          message: 'For amount '+amount+' , Enter UTR #',
          prompt: {
            model: '',
            type: 'text' // optional
          },
          cancel: true,
          persistent: true
        }).onOk(data => {
          // console.log('>>>> OK, received', data)
          //alert(data);
          emitter.on("onCrDrSellertSuccess",this.onCrDrSellerSuccess);
          emitter.on("onCrDrSellerFail",this.onCrDrSellerFail);
          invoiceService.crdrToSeller({'utr_num':data,'api_id':api_id},this,emitter);
        }).onCancel(() => {
          // console.log('>>>> Cancel')
        }).onDismiss(() => {
          // console.log('I am triggered on both OK and Cancel')
        })
    },
    onCrDrSellerSuccess:function(data){
      this.showInfo("Utr submitted successfully.");
      this.pullOrders();
    }
  },
  created: function () {
    this.setPageTitle("Orders Placed");
    this.setToolbarTitle("Orders Placed");
  },
  mounted: function () {
    this.isLogged = userService.isLogged();
    this.pullOrders();
  },
  unmounted: function () {
    emitter.off("onPlacedOrderListSuccess");
    emitter.off("onPlacedOrderListFail");
    emitter.off("onOrderDeliveredSuccess");
    emitter.off("onOrderDeliveredFail");
    emitter.off("onOrderReceiptSuccess");
    emitter.off("onOrderReceiptFail");

    emitter.off("onCrDrSellertSuccess");
    emitter.off("onCrDrSellerFail");
         
  },
  setup() {},
};
</script>

