<template>
    <q-dialog
      v-model="alert"  v-if="show"
    >
      <q-card style="width: 300px">
        <q-card-section>
          <div class="text-h6 text-bold text-red">Error <q-separator /></div>
        </q-card-section>
        <q-card-section class="q-pt-none">
          {{msg}}
        </q-card-section>
        <q-card-actions align="right" class="bg-white text-teal">
          <q-btn flat label="OK"  v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
</template>
<script>
export default {
  props:['msg',"show"],
  name: "AlertComp",
  data() {
    return {
      alert:'',
    }
  }
};
</script>