import axiosLogged from "../helpers/axiosLogged";
import axios from "axios";

function generateInvoice(ob, payload, emitterObj) {
    ob.showLoading();
    axiosLogged.post("/invoice/generate-invoice", payload)
        .then(response => {
            //console.log(response.data.data);
            if (response.data.data.head.success == "true") {
                emitterObj.emit('onInvoiceGenerateSuccess', response.data.data.body);
            } else {
                emitterObj.emit('onInvoiceGenerateFail', response.data.data.body.error.msg);
            }
            ob.hideLoading();
        }).catch(error => {
            ob.hideLoading();
            emitterObj.emit('onInvoiceGenerateFail', Promise.reject(error));
        });
}

function invoiceReceipt(api_id, ob, emitterObj) {
    ob.showLoading();
    axiosLogged.get("/invoice/invoice-receipt?api_id=" + api_id, {
        responseType: 'blob',
    })
        .then(res => {
            ob.hideLoading();
            emitterObj.emit('onOrderReceiptSuccess', res);
        }).catch(error => {
            ob.hideLoading();
            emitterObj.emit('onOrderReceiptFail', Promise.reject(error));
        });
}

function getInvoice(payload, ob, emitterObj) {
    ob.showLoading();
    axiosLogged.post("/invoice/get-invoice", payload)
        .then(response => {
            if (response.data.data.head.success == "true") {
                emitterObj.emit('onGetInvoiceSuccess', response.data.data.body);
            } else {
                emitterObj.emit('onGetInvoiceFail', response.data.data.body.error.msg);
            }
            ob.hideLoading();
        }).catch(error => {
            ob.hideLoading();
            emitterObj.emit('onGetInvoiceFail', Promise.reject(error));
        });
}
/* paid to customer */
function crdrToCustomer(payload, ob, emitterObj) {
    ob.showLoading();
    axiosLogged.post("/invoice/crdr-note-customer", payload)
        .then(response => {
            if (response.data.data.head.success == "true") {
                emitterObj.emit('onCrDrCustSuccess', response.data.data.body);
            } else {
                emitterObj.emit('onCrDrCustFail', response.data.data.body.error.msg);
            }
            ob.hideLoading();
        }).catch(error => {
            ob.hideLoading();
            emitterObj.emit('onCrDrCustFail', Promise.reject(error));
        });
}

function crdrToSeller(payload, ob, emitterObj) {
    ob.showLoading();
    axiosLogged.post("/invoice/crdr-note-seller", payload)
        .then(response => {
            if (response.data.data.head.success == "true") {
                emitterObj.emit('onCrDrSellerSuccess', response.data.data.body);
            } else {
                emitterObj.emit('onCrDrSellerFail', response.data.data.body.error.msg);
            }
            ob.hideLoading();
        }).catch(error => {
            ob.hideLoading();
            emitterObj.emit('onCrDrSellerFail', Promise.reject(error));
        });
}

function downloadInvoiceReceipt(api_id,token){
    console.log(process.env.VUE_APP_API_URL+"/invoice/invoice-receipt?api_id=" + api_id + "&token="+token);
    window.location = process.env.VUE_APP_API_URL+"/invoice/invoice-receipt?api_id=" + api_id + "&token="+token;
}



export const invoiceService = {
    invoiceReceipt,
    generateInvoice,
    getInvoice,
    crdrToCustomer,
    crdrToSeller,
    downloadInvoiceReceipt
};