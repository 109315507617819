<template>
      <div class="row">
        <h1>Helllow  </h1>
      </div>
</template>
<script>

export default {
  name: "SearchFormComp",
};
</script>
