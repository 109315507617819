import axiosLogged from "../helpers/axiosLogged";
import axios from "axios";

/* paid to customer */
function getOrders(payload, ob, emitterObj) {
    ob.showLoading();
    axiosLogged.post("/transporter/my-orders", payload)
        .then(response => {
            if (response.data.data.head.success == "true") {
                emitterObj.emit('onOrderSuccess', response.data.data.body);
            } else {
                emitterObj.emit('onOrderFail', response.data.data.body.error.msg);
            }
            ob.hideLoading();
        }).catch(error => {
            ob.hideLoading();
            emitterObj.emit('onOrderFail', Promise.reject(error));
        });
}




export const transporterService = {
    getOrders
};