<template>
  <q-page-container class="bg-container" :style="(paymentExpired) ? 'pointer-events: none;opacity: 0.3;':''">
    <q-page class="q-gutter-md">
      <div v-if="data != null">
        <q-card class="no-shadow q-pb-md">
          <q-card-section class="bg-grey text-white text-bold text-subtitle rounded-borders">
            <template v-if="!paymentExpired">
            Order payment valid upto : <q-badge :label="payTimeOut + ' minutes'" class="text-bold text-subtitle" size="15" color="black" />
            </template>
            <template v-if="paymentExpired">
              <span class="text-black">Your timeframe of making the payment is expired. Please place the order again !</span>
            </template>
          </q-card-section>
        </q-card>
        <q-card class="no-shadow">
          <q-card-section class="bg-green-1 rounded-borders">
            <div class="row">
              <div class="col">
                <q-span
                  class="text-bold color-blue text-uppercase text-body1"
                  >{{ data.seller.trading_name }}</q-span
                >
                <q-separator />
                <!--
                <span class="text-grey text-weight-light"
                  >{{ data.seller.address }}<br
                /></span>-->
                <span class="text-grey text-weight-light"
                  >{{ data.seller.city }}, {{ data.seller.state }}<br
                /></span>
                <div class="text-body text-left q-pt-xs q-gutter-sm">
                  <div class="row">
              <div class="col text-center">
                    <q-icon name="mdi-shield-lock-outline" class="text-primary" rounded size="50px"></q-icon>
              </div>
        </div>

                   <q-radio @update="onChangePaymentType()" class="text-body1 bg-red-1 text-bold  text-blue" v-model="paymentType" val="full" 
                    :label="'Full Amount - ('+data.total_amount+')'" style="width:100%" selected /> 
                    
                    <q-radio @update="onChangePaymentType()" class="text-body1 bg-red-1  text-bold text-blue" v-model="paymentType" val="partial" 
                    :label="'Booking Amount - ('+data.partial_amount+')'" style="width:100%" /> 
                   <!--
                   <q-badge
                    class="text-body1"
                    :label="'Full Amount - Rs. ' + data.total_amount"
                    color="red-6"
                  />
                  -->
                
                </div>
              </div>
            </div>
          </q-card-section>
          <br />
        </q-card>

        <div class="q-pa-md bg-grey-3">
          <div class="q-gutter-sm text-bold">
            <div class="text-bold text-blue">Choose Payment Mode:</div>
            <q-radio v-model="payMode" val="online" label="Pay Online" />
            <q-radio
              v-model="payMode"
              val="offline"
              label="Branch Banking"
            />
          </div>
        </div>
        <q-list v-if="payMode == 'offline'" class="q-pt-md">
          <q-item>
            <q-item-section>
              <q-item-label class="text-uppercase text-bold"
                >Beneficiary Name</q-item-label
              >
              <q-item-label caption lines="2"
                >STEELDALAL PRIVATE LIMITED</q-item-label
              >
            </q-item-section>
          </q-item>
          <q-separator spaced inset />
          <q-item>
            <q-item-section>
              <q-item-label class="text-uppercase text-bold"
                >Bank Name</q-item-label
              >
              <q-item-label caption lines="2">HDFC BANK LTD</q-item-label>
            </q-item-section>
          </q-item>
          <q-separator spaced inset />
          <q-item>
            <q-item-section>
              <q-item-label class="text-uppercase text-bold"
                >Branch</q-item-label
              >
              <q-item-label caption lines="2"
                >KALYANI DEVI ,PRAYAGRAJ ,UTTAR PRADESH</q-item-label
              >
            </q-item-section>
          </q-item>
          <q-separator spaced inset />

          <q-item>
            <q-item-section>
              <q-item-label class="text-uppercase text-bold"
                >Acc NO</q-item-label
              >
              <q-item-label caption lines="2">STLDAL572317</q-item-label>
            </q-item-section>
          </q-item>
          <q-separator spaced inset />
          <q-item>
            <q-item-section>
              <q-item-label class="text-uppercase text-bold"
                >IFSC Code
              </q-item-label>
              <q-item-label caption lines="2">HDFC0009651</q-item-label>
            </q-item-section>
          </q-item>
          <q-separator spaced inset />
          <br />
          <div class="text-center">
            <q-btn
              label="Go To Dashbobard"
              @click="goToHome()"
              color="primary"
              size="sm"
            ></q-btn>
          </div>
        </q-list>
        <!--form-->
        <div class="q-pt-md">
          <q-form
            @submit="onSubmit"
            @reset="onReset"
            class="q-gutter-md"
            v-if="payMode == 'online'"
          >
            <q-input
              outlined
              dense
              v-model="form.first_name"
              label="First Name *"
              hint=" "
              lazy-rules
              :rules="[
                (val) => (val && val.length > 0) || 'Please type first name',
              ]"
            />

            <q-input
              outlined
              dense
              v-model="form.last_name"
              label="Last Name *"
              hint=" "
              lazy-rules
              :rules="[
                (val) => (val && val.length > 0) || 'Please type last name',
              ]"
            />

            <q-input
              outlined
              dense
              v-model="form.email"
              label="Email Address *"
              hint=" "
              lazy-rules
              :rules="[
                (val) => (val && val.length > 0) || 'Please type email address',
              ]"
            />

            <q-input
              outlined
              dense
              v-model="form.mobile"
              label="Mobile Number *"
              hint=" "
              type="number"
              maxlength="10"
              lazy-rules
              :rules="[
                (val) => (val && val.length > 0) || 'Please type mobile number',
              ]"
            />
            <span>
              <q-toggle v-model="form.accept" label="" class="text-blue" />
              <span @click="showToggleDialog(true)" class="text-blue"
                >I accept the terms and conditions</span
              ></span
            >
            <div>
              <q-btn label="Pay Now." type="submit" color="primary" />
              <q-btn
                label="Reset"
                type="reset"
                color="primary"
                flat
                class="q-ml-sm"
              />
            </div>
          </q-form>
        </div>
        <!--end form-->
      </div>
      <!--############################ dynamic form ##################################-->
      <form ref="payForm" action="//secure.payu.in/_payment" method="post">
        <input type="hidden" name="key" :value="payData.key" />
        <input type="hidden" name="txnid" :value="payData.txnid" />
        <input type="hidden" name="productinfo" :value="payData.productinfo" />
        <input type="hidden" name="amount" :value="payData.amount" />
        <input type="hidden" name="email" :value="payData.email" />
        <input type="hidden" name="firstname" :value="payData.firstname" />
        <input type="hidden" name="lastname" :value="payData.lastname" />
        <input type="hidden" name="surl" :value="payData.surl" />
        <input type="hidden" name="furl" :value="payData.furl" />
        <input type="hidden" name="phone" :value="payData.phone" />
        <input type="hidden" name="udf1" :value="payData.udf1" />
        <input type="hidden" name="udf2" :value="payData.udf2" />
        <input type="hidden" name="hash" :value="payData.hash" />
        <!--
          <input type="submit" value="submit">
          -->
        <a href="#" v-on:click="submit"></a>
      </form>
      <!--end dynamic form ############################################################-->
      <div>
        <q-dialog
          v-model="dialog"
          persistent
          full-width
          v-show="dialog"
          class="bg-black"
        >
          <q-card>
            <q-card-section v-html="tocContents"> </q-card-section>
          </q-card>
          <q-card class="text-black text-center bg-black">
            <q-card-section>
              <q-btn
                size="10px"
                label="Close"
                color="primary"
                @click="showToggleDialog(false)"
              />
            </q-card-section>
          </q-card>
        </q-dialog>
      </div>
    </q-page>
  </q-page-container>
</template>
<script>
import { userService } from "../../services/userService";
import mitt from "mitt";
import { orderService } from "../../services/orderService";
import { paymentService } from "../../services/paymentService";
import { cartService } from "../../services/cartService";
import { cmsService } from "../../services/cmsService";

const emitter = mitt();

export default {
  name: "PaymentPage",
  data() {
    return {
      dialog: false,
      tocContents: "",
      isLogged: false,
      data: null,
      form: {
        api_id:'',
        first_name: "",
        last_name: "",
        email: "",
        mobile: "",
        accept: false,
        payment_type:'full'
      },
      payData: {
        url: "",
        key: "",
        txnid: "",
        productinfo: "",
        amount: 0,
        email: "",
        firstname: "",
        lastname: "",
        surl: "",
        furl: "",
        phone: "",
        hash: "",
        udf1: "",
        udf2:"",
      },
      paymentForm: "pay-form",
      payMode: "online",
      paymentType:'full',
      paymentTimeRemain:null,
      payTimeOut:0,
      closeTimer: null,
      paymentExpired:false,
    };
  },
  methods: {
    countDownTimer: function () {
      if (this.paymentTimeRemain > 0) {
        this.closeTimer = setTimeout(() => {
          this.paymentTimeRemain -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
    showToggleDialog: function (bool) {
      this.dialog = bool;
    },
    onTocSuccess: function (data) {
      //alert(data);
      this.tocContents = data;
    },
    onSubmit: function () {
      if (this.form.accept != true) {
        this.showError("Please accept terms & conditions.");
        return;
      }
      this.form.api_id = this.$route.query._id;
      this.form.payment_type = this.paymentType;
      emitter.on("onMakePaymentSuccess", this.onMakePaymentSuccess);
      emitter.on("onMakePaymentFail", this.onMakePaymentFail);
      paymentService.makePayment(this.form, this, emitter);
    },
    goToPayU: function () {
      //this.$refs.payForm.submit();

      setTimeout(() => {
        this.$refs.payForm.submit();
        //this.hideLoading();
      }, 500);
    },
    onMakePaymentSuccess: function (data) {
      this.payData = data.data;
      this.showLoading();
      this.goToPayU();
    },
    onMakePaymentFail() {},
    onGetOrderSuccess: function (data) {
      this.data = data.data;
      if (this.data.is_paid == "yes") {
        this.goToOrderPlaced();
      }
      if(data.paymentValidity.expired=="false")
      {
        this.paymentTimeRemain = data.paymentValidity.time_remain;
        this.countDownTimer();
      }else{
        this.showError("Payment time epxired.");
        //this.goToDashboard();
        this.paymentExpired = true;
      }
    },

    onGetOrderFail: function (error) {
      this.showError(error);
    },
  },
  created: function () {
    this.setPageTitle("Make Payment");
    this.setToolbarTitle("Make Payment");
  },
  mounted: function () {
    this.isLogged = userService.isLogged();
    emitter.on("onGetOrderSuccess", this.onGetOrderSuccess);
    emitter.on("onGetOrderFail", this.onGetOrderFail);
    orderService.getOrder({ api_id: this.$route.query._id }, this, emitter);

    emitter.on("onTocSuccess", this.onTocSuccess);
    cmsService.getToc(this, emitter);
  },
  unmounted: function () {
    emitter.off("onGetOrderSuccess");
    emitter.off("onGetOrderFail");
    emitter.off("onMakePaymentSuccess");
    emitter.off("onMakePaymentFail");
    emitter.off("onTocSuccess");
  },
  watch: {
    paymentTimeRemain: function () {
      //console.log(this.splashTimer);
      if(this.paymentTimeRemain!=null){
        if ((this.paymentTimeRemain == 1)) {
          clearInterval(this.closeTimer);
          this.showError("Payment time epxired.");
          this.paymentExpired = true;
          //this.goToDashboard();
        }
        //console.log(this.paymentTimeRemain);
        this.payTimeOut = new Date(this.paymentTimeRemain * 1000).toISOString().substring(14, 19);
      }
    },
  },
  setup() {},
};
</script>

