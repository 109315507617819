<template>
  <q-page-container>
    <q-page class="q-pa-xs-md">
      <div>
        <strong>VISION</strong> 

<p>
To become one of the best companies in the world encompassing the entire gamut of trade across all the commodities providing a most transparent, reliable and seamless platform to all sections of the Society irrespective of their status. 
</p>

<strong>MISSION</strong>
<p> 
To provide each and every person including legal a legal entity desirous of buying and selling any item in metals especially Steel and all other commodities in general through an online business portal providing a completely seamless experience with the highest standards of efficiency and reliability. 
</p>


<strong>About Us</strong>
<p> 
SteelDalal.com is only online Iron & Steel Trading plateform which provides the latest and actual market trend of the iron and steel. SteelDalal brings multiple users both Buyers and Sellers at one negotiating plateform. </p>
<p>
Thus, providing the best suitable deals for the members where members get the real time rates and availability of material according to theirneeds. </p>
<p>
SteelDalal aims to transition the current metal trading ecosystem to the virtual world with focus on transparency, ease-of-use and an ability to trade on-the-go.</p> 

      </div>
    </q-page>
  </q-page-container>
</template>
<script>
export default {
  name: "AboutUs",
  data() {
    return {};
  },
  mounted:function(){
    this.setToolbarTitle("About Us");
    this.setPageTitle("About Us");
  },
  setup() {},
};
</script>
