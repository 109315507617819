<template>
  <q-page-container>
    <q-page class="q-pa-xs-md">
      <div>
        <b>Return and Refund Policy</b>
<hr />  
<br />
<b>Last updated: June 15, 2022</b><br />

<b>Thank you for shopping at SteelDalal.</b><br /><br />
<br />

<p>
If, for any reason, You are not completely satisfied with a purchase We invite
You to review our policy on refunds and returns. This Return and Refund Policy
has been created with the help of the [Return and Refund Policy
Generator](https://www.privacypolicies.com/return-refund-policy-generator/).
</p>

<p>The following terms are applicable for any products that You purchased with
Us.</p>

<b>Interpretation and Definitions</b>
<hr />  

<br />
<b>Interpretation</b><br />  <br />

<p>
The words of which the initial letter is capitalized have meanings defined
under the following conditions. The following definitions shall have the same
meaning regardless of whether they appear in singular or in plural.
</p>

<b>Definitions</b><br /><br />
  
<p>
For the purposes of this Return and Refund Policy:

  <ul>	
  <li> Company (referred to as either "the Company", "We", "Us" or "Our" in this
    Agreement) refers to SteelDalal Private Limited, Mumbai Maharashtra India.</li>

  <li> Goods refer to the items offered for sale on the Service.</li>

  <li> Orders mean a request by You to purchase Goods from Us.</li>

  <li> Service refers to the Website.</li>

  <li> Website refers to SteelDalal, accessible from
    [www.steeldalal.com](www.steeldalal.com)</li>

  <li> You means the individual accessing or using the Service, or the company,
    or other legal entity on behalf of which such individual is accessing or
    using the Service, as applicable.</li>
 </ul>
</p>


<b>Your Order Cancellation Rights</b>  
<hr />

<p>You are entitled to cancel Your Order within 7 days without giving any reason
for doing so.</p>

<p>The deadline for cancelling an Order is 7 days from the date on which You
received the Goods or on which a third party you have appointed, who is not
the carrier, takes possession of the product delivered.</p>

<p>In order to exercise Your right of cancellation, You must inform Us of your
decision by means of a clear statement. You can inform us of your decision by:</p>

 <b> By email: support@steeldalal.com </b><br /><br />

<p>We will reimburse You no later than 14 days from the day on which We receive
the returned Goods. We will use the same means of payment as You used for the
Order, and You will not incur any fees for such reimbursement.</p>

<b>Conditions for Returns</b>  
<hr />

<p>In order for the Goods to be eligible for a return, please make sure that:</p>
    <ul>
  <li> The Goods were purchased in the last 7 days </li>
  <li> The Goods are in the original packaging </li>
</ul>
<p>The following Goods cannot be returned:</p>
	
   <ul> 	
  <li> The supply of Goods made to Your specifications or clearly personalized.</li>
  <li> The supply of Goods which according to their nature are not suitable to be
    returned, deteriorate rapidly or where the date of expiry is over.</li>
  <li> The supply of Goods which are not suitable for return due to health
    protection or hygiene reasons and were unsealed after delivery.</li>
  <li> The supply of Goods which are, after delivery, according to their nature,
    inseparably mixed with other items.</li>
  </ul>
<p>  
We reserve the right to refuse returns of any merchandise that does not meet
the above return conditions in our sole discretion.
</p>

<p>
Only regular priced Goods may be refunded. Unfortunately, Goods on sale cannot
be refunded. This exclusion may not apply to You if it is not permitted by
applicable law.
</p>

<b>Returning Goods</b>
<hr />  

<p>You are responsible for the cost and risk of returning the Goods to Us. You
should send the Goods at the following address:</p>

<b>To the Seller</b>
<br /><br />
<p>
We cannot be held responsible for Goods damaged or lost in return shipment.
Therefore, We recommend an insured and trackable mail service. We are unable
to issue a refund without actual receipt of the Goods or proof of received
return delivery.
</p>

<b>Gifts</b> <br /><br />



<p>If the Goods were marked as a gift when purchased and then shipped directly to
you, You'll receive a gift credit for the value of your return. Once the
returned product is received, a gift certificate will be mailed to You.</p>

<p>If the Goods weren't marked as a gift when purchased, or the gift giver had
the Order shipped to themselves to give it to You later, We will send the
refund to the gift giver.</p>

<b>Contact Us</b>
<br /><br />

<p>If you have any questions about our Returns and Refunds Policy, please contact
us:</p>

  <b> By email: support@steeldalal.com</b>


      </div>
    </q-page>
  </q-page-container>
</template>
<script>
export default {
  name: "RefundPolicy",
  data() {
    return {};
  },
  mounted:function(){
    this.setToolbarTitle("Refund Policy");
    this.setPageTitle("Refund Policy");
  },
  setup() {},
};
</script>
