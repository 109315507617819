<template>
  <q-page-container>
    <q-page class="q-pa-xs-md q-gutter-md" v-if="data != null">
      <q-card class="my-card bg-black" no-shadow>
        <img
          :src="productPhotoURL + product.mst_product.img"
          style="opacity: 0.9; height: 50%"
        />
      </q-card>
      <!--
      <div class="row">
        <div class="col text-center;" style="max-width:100%;">
          <img
            :src="data.productPhotoURL + product.mst_product.img"
            style="overflow:hidden;"
          />
        </div>
      </div>
      -->
      <q-list>
        <q-item>
          <q-item-section class="q-pa-xs text-bold">Brand</q-item-section>
          <q-item-section class="text-left">
            <span>{{ product.brand }}</span>
          </q-item-section>
        </q-item>
        <q-separator />
        <q-separator />
        <q-item>
          <q-item-section class="q-pa-xs text-bold">Category</q-item-section>
          <q-item-section class="text-left">
            <span>{{ product.category }}</span>
          </q-item-section>
        </q-item>
        <q-separator />
        <q-item>
          <q-item-section class="q-pa-xs text-bold">Grade</q-item-section>
          <q-item-section class="text-left">
            <span>{{ product.grade }}</span>
          </q-item-section>
        </q-item>
        <template v-if="product.mst_color_id > 0">
          <q-separator />
          <q-item>
            <q-item-section class="q-pa-xs text-bold">Color</q-item-section>
            <q-item-section class="text-left">
              <span>{{ product.color_name }}</span>
            </q-item-section>
          </q-item>
        </template>
        <template
          v-if="
            product.cw_5_10 > 0 || product.cw_10_22 > 0 || product.cw_22_27 > 0
          "
        >
          <q-separator />
          <q-item>
            <q-item-section class="q-pa-xs text-bold">Weight</q-item-section>
            <q-item-section class="text-left">
              <span v-if="product.cw_5_10 > 0"> 5-10 ,</span>
              <span v-if="product.cw_10_22 > 0">10-22 ,</span>
              <span v-if="product.cw_22_27 > 0">22-27</span>
            </q-item-section>
          </q-item>
        </template>
        <q-separator />
        <q-item v-if="product.min_sale_qty > 0">
          <q-item-section class="q-pa-xs text-bold">MOQ</q-item-section>
          <q-item-section class="text-left">
            <span>{{ product.min_sale_qty }}</span>
          </q-item-section>
        </q-item>
        <q-separator />
        <q-item>
          <q-item-section class="text-left">
            <div>
              {{ product.description.substring(0, 80) }}.....<strong
                class="text-primary"
                @click="showToggleDialog(true)"
                >[ + ]</strong
              >
            </div>
          </q-item-section>
        </q-item>
      </q-list>
      <div class="row">
        <div class="col">
          <q-btn
            squre
            size="md"
            flat
            class="full-width text-center text-bold text-caption c-bg-color btn-primary text-white rounded-bordered"
            label="Available Product variations"
          />
        </div>
      </div>
      <!-- FILTER-->
      <q-card
        flat
        bordered
        class="bg-grey-2"
        v-if="productType != '' && productType == 'sheet'"
      >
        <q-card-section>
          <div class="text-overline">Filter</div>
          <br />
          <div class="row q-gutter-md">
            <div class="col">
              <q-select
                dense
                outlined
                v-model="productFilter.t"
                :options="productFilterOptions.t"
                label="Thickness"
                @update:model-value="onFilterSelectBoxChange('t')"
              />
            </div>
            <div class="col">
              <q-select
                dense
                outlined
                v-model="productFilter.w"
                :options="productFilterOptions.w"
                label="width"
                @update:model-value="onFilterSelectBoxChange('w')"
              />
            </div>
            <div class="col">
              <q-select
                dense
                outlined
                v-model="productFilter.l"
                :options="productFilterOptions.l"
                @update:model-value="onFilterSelectBoxChange('l')"
                label="Length"
              />
            </div>
          </div>
        </q-card-section>
        <q-separator />
        <q-card-actions>
          <q-btn
            flat
            color="primary"
            class="text-right"
            @click="filterSheetRate()"
          >
            Search
          </q-btn>
        </q-card-actions>
      </q-card>
      <!--//END FILLTER-->
      <q-card
        class="my-card no-shadow"
        v-for="(item, index) in rates"
        v-bind:key="item"
      >
        <q-card-section
          :class="
            index % 2 == 0
              ? 'bg-green-1  rounded-borders'
              : 'bg-grey-4 rounded-borders'
          "
          v-if="item.show"
        >
          <div class="row q-mb-xs" :id="index">
            <!-- ################# standard -->
            <div class="col" v-if="product.size_type == 'standard'" style="margin-top:10px;">
              <span class="text-regular text-bold" >
                <!--
                <q-badge squar color="blue-5 text-white" label="Size : " />
                <br />-->
                {{ item.size }}MM</span
              >
            </div>
            <!-- ################# sheet -->
            <div class="col" v-if="product.size_type == 'sheet'">
              <span class="text-regular text-bold">
                <q-badge squar color="grey-5 text-black" label="Thickness : " />
                {{ item.thickness }}<br />
                <q-badge squar color="grey-5 text-black" label="Width : " />
                {{ item.width }} <br />
                <q-badge squar color="grey-5 text-black" label="Length: " />
                {{ item.length }} <br />
              </span>
              <!--
              <br />
              <q-badge squar color="grey-5 text-black" label="Price: " />&nbsp;
              <span class="text-weight-bold text-red">
                {{ item.price }}/- per ton
              </span>-->
            </div>
            <!-- ################# coil -->
            <div class="col" v-if="product.size_type == 'coil'">
              <span class="text-regular text-bold">
                <q-badge squar color="grey-5 text-black" label="Thickness : " />
                {{ item.thickness + " " + item.thickness_uom }}<br />
                <q-badge squar color="grey-5 text-black" label="Width : " />
                {{ item.width + " " + item.width_uom }}
              </span>
              <br />
              <q-badge squar color="grey-5 text-black" label="Price : " />&nbsp;
              <span class="text-weight-bold text-red">
                <q-icon name="currency_rupee" />
                {{ item.price }}/- {{ item.price_uom }}
              </span>
            </div>
            <!-- ################# standard -->
            <template v-if="item.price > 0">
              <div class="col-3 text-left " v-if="item.size_type == 'standard'">
                <q-select
                  dense
                  outlined
                  class="bg-white"
                  model-value="ton"
                  v-model="item.qty_uom"
                  :options="options"
                  :disable="item.price == 0 ? true : false"
                  
                />
              </div>
              <div class="col text-right q-pl-sm">
                <q-input
                  outlined
                  class="bg-white"
                  type="number"
                  placeholder="Qty"
                  step="any"
                  input-class="text-right"
                  v-model="item.buyQty"
                  dense
                  @keydown="updateTotal"
                  @keyup="updateTotal"
                  :disable="item.price == 0 ? true : false"
                ></q-input>
              </div>
            </template>
            <!--if price not available-->
            <template v-if="item.price == 0">
                <q-badge
                  class="text-subtitle1"
                  squar
                  color="red"
                  size="10"
                  label="Item Sold Out"
                />
              </template>
          </div>
          <div class="row">
            <div class="col q-py-xs q-mb-xs">
              
              <q-separator />
              
            </div>
          </div>
          <div class="row">
            <div class="col text-bold text-subtitle1 text-right">
              <template v-if="item.price > 0">
                <q-badge
                  class="text-subtitle1 btn-primary"
                  squar
                  color="blue-5"
                  size="10"
                  :label="'Price: ' + item.price"
                />
                x {{ item.buyQty == null ? 0 : item.buyQty }} =
                {{ (item.buyQty == null ? 0 : item.buyQty) * item.price }}
                <br /><span class="text-caption text-grey-9"
                  ><small>excl. 18% GST</small></span
                >
              </template>
            </div>
          </div>
        </q-card-section>
      </q-card>
      <div class="q-pa-sm">
        <div class="row text-pink text-subtitle1">
          <div class="col text-right text-subtitle1">
            <span class="text-black text-grey-9 text-bold">Total</span>
          </div>
          <div class="col text-right text-bold">
            <q-icon name="currency_rupee" /> {{ total }} <br />
            <!--
              <span class="text-caption text-grey">(Ordered Qty : {{buyQty}}  ton)</span>
              -->
            <span class="text-caption text-grey-8">(excl. 18% gst)</span>
          </div>
        </div>
        <q-separator />
        <div class="row">
          <div class="col-12 text-right">
            <span class="text-caption text-grey">
              The Requested Order Quantity is
              <span class="text-bold text-red"
                >{{ this.requestBuyQty }} Ton </span
              >, prices May vary based on the Quantity ordered
              <!--
              * You have requested order quantity <span class="text-bold text-red">{{this.requestBuyQty}} Ton </span>, If any change in the order quantity, Price may change.
              -->
            </span>
          </div>
        </div>
      </div>
      <div class="col text-center">
        <q-btn
          rounded
          size="md"
          flat
          class="text-center text-bold text-caption text-white c-bg-color rounded-bordered btn-primary"
          icon="ion-calculator"
          label="Add To Cart"
          @click="onClickAddToCart(customer.api_id)"
        />
      </div>
      <br /><br />
      <!-- dialog -->
      <div class="bg-white">
        <q-dialog
          v-model="dialog"
          persistent
          full-width
          v-show="dialog"
          transition-show="flip-up"
            transition-hide="flip-down"
          id="seller-product-rate"
        >
          <q-card class="bg-white">
            <div class="text-right q-pa-xs">
              <q-btn
                size="12px"
                label="X"
                color="red"
                @click="showToggleDialog(false)"
              />
            </div>
            <q-card-section v-html="product.description"> </q-card-section>
          </q-card>
        </q-dialog>
      </div>
    </q-page>
  </q-page-container>
</template>
<script>
import { userService } from "../../services/userService";
import { useQuasar, Dialog } from "quasar";
import { buyService } from "../../services/buyService";
import mitt from "mitt";
import { cartService } from "../../services/cartService";

const emitter = mitt();

export default {
  name: "SellerProductRate",
  data() {
    return {
      isLogged: null,
      products: null,
      customer: null,
      rates: null,
      product: null,
      data: null,
      customerToken: null,
      form: {
        customer_id: null,
        seller_id: null,
        product_id: null,
        rate_ids: [],
      },
      sellerApiId: "",
      minQty: 0,
      dialog: false,
      options: ["ton"],
      requestBuyQty: this.$route.query._qty,
      buyQty: 0,
      total: 0,
      productPhotoURL: process.env.VUE_APP_PRODUCT_IMG_URL,
      productFilter: {
        t: "",
        w: "",
        l: "",
      },
      productFilterOptions: {
        t: ["all"],
        w: ["all"],
        l: ["all"],
      },
      productType: "standard",
    };
  },
  methods: {
    showToggleDialog: function (bool) {
      this.dialog = bool;
    },
    onSellerView: function () {},
    onSellerProductRatesSuccess: function (data) {
      //console.log(data);
      this.data = data;
      this.product = data.data;
      this.productType = this.product.size_type;
      this.productFilterOptions.t = ["all"];
      //this.productFilterOptions.w = ["all"];
      //this.productFilterOptions.l = ["all"];
      for (var j = 0; j < this.product.product_rates.length; j++) {
        this.product.product_rates[j].show = 1;
        this.product.product_rates[j].qty_uom = "ton";
        this.productFilterOptions.t.push(
          this.product.product_rates[j].thickness
        );
        //this.productFilterOptions.w.push(this.product.product_rates[j].width);
        //this.productFilterOptions.l.push(this.product.product_rates[j].length);
      }
      this.rates = this.product.product_rates;
      this.customer = this.product.customer;
      this.minQty = data.data.min_sale_qty;

      this.form.customer_id = userService.getValue("api_id");
      this.form.seller_id = this.customer.api_id;
      this.form.product_id = this.product.api_id;
    },
    onSellerProductRatesFail: function (error) {
      //console.log(error);
      this.showError(error);
    },
    onClickAddToCart: function (currentSeller) {
      //console.log(this.form);
      this.addIntoCart(true, currentSeller);
    },
    addIntoCart: function (bool, currentSeller) {
      if (bool) {
        this.form.rate_ids = [];
        let flag = false;
        let buyQty = 0;
        for (var i = 0; i < this.rates.length; i++) {
          if (this.rates[i].buyQty != "undefined" && this.rates[i].buyQty > 0) {
            flag = true;
            //break;
            let item = {
              product_rate_id: this.rates[i].api_id,
              qty_uom: this.rates[i].qty_uom,
              price_uom: this.rates[i].price_uom,
              qty: this.rates[i].buyQty,
              requested_qty: this.requestBuyQty,
              material: this.$route.query._material,
            };
            buyQty = buyQty + parseFloat(this.rates[i].buyQty);
            this.form.rate_ids.push(item);
          }
        }
        if (!flag) {
          this.showError("Please enter qty.");
          return;
        }
        if (buyQty != this.requestBuyQty) {
          this.showError(
            'Total quantity is "' +
              buyQty +
              '" what is being preferred to purchase in previous grade page. Please adjust desired quantities and proceed'
          );
          return;
        }
        /*
        if (this.minQty > 0) {
          if (buyQty < this.minQty) {
            this.showError("Minmum sale quantity allowed : " + this.minQty);
            return;
          }
        }*/
        emitter.on("onCalculatePriceSuccess", this.onCalculatePriceSuccess);
        emitter.on("onCalculatePriceFail", this.onCalculatePriceFail);
        cartService.calculatePrice(this.form, this, emitter);
        //console.log(this.form);
        //localStorage.setItem("cart.seller_id", currentSeller);
      }
    },
    onCalculatePriceSuccess: function (data) {
      //this.showInfo("Item added into cart !");
      //this.goToCalculatePrice();
      this.goToCart();
    },
    onCalculatePriceFail: function (error) {
      this.showError(error);
    },
    updateTotal: function () {
      this.total = 0;
      this.buyQty = 0;
      for (let i = 0; i < this.rates.length; i++) {
        if (this.rates[i].buyQty != "undefined")
          if (isNaN(this.rates[i].buyQty)) continue;
        console.log(this.rates[i].buyQty);
        this.total = this.total + this.rates[i].buyQty * this.rates[i].price;
        this.buyQty = this.buyQty + Number(this.rates[i].buyQty);
      }
    },
    filterSheetRate: function () {
      let t_i = this.productFilter.t;
      let w_i = this.productFilter.w;
      let l_i = this.productFilter.l;

      if (t_i == "all" || t_i == "") {
        //for thickness only.
        for (var j = 0; j < this.product.product_rates.length; j++) {
          this.product.product_rates[j].show = 1;
        }
        return;
      }

      for (j = 0; j < this.product.product_rates.length; j++) {
        let t = this.product.product_rates[j].thickness;
        let w = this.product.product_rates[j].width;
        let l = this.product.product_rates[j].length;

        this.product.product_rates[j].buyQty = 0;

        if (t_i == t) {
          this.product.product_rates[j].show = 1;
        } else {
          this.product.product_rates[j].show = 0;
        }

        //t and w
        if (w_i != "all" && w_i != "") {
          if (t_i == t && w_i == w) {
            this.product.product_rates[j].show = 1;
          } else {
            this.product.product_rates[j].show = 0;
          }
        }

        //t and w
        //if(w_i !="all" && w_i !="" && l_i!='all' && l_i!="" && t_i=="" && t_i=='all'){
        if (w_i != "all" && w_i != "" && l_i != "all" && l_i != "") {
          if (t_i == t && w_i == w && l_i == l) {
            this.product.product_rates[j].show = 1;
          } else {
            this.product.product_rates[j].show = 0;
          }
        }
      }
    },
    onFilterSelectBoxChange: function (which) {
      //console.log("call");
      let t = this.productFilter.t;
      let w = this.productFilter.w;
      let l = this.productFilter.l;
      let j = 0;
      if (which == "t") {
        this.productFilterOptions.w = ["all"];
        this.productFilterOptions.l = ["all"];
        this.productFilter.w = "";
        this.productFilter.l = "";
        if (t == "all") return;
        for (j = 0; j < this.product.product_rates.length; j++) {
          if (t == this.product.product_rates[j].thickness) {
            this.productFilterOptions.w.push(
              this.product.product_rates[j].width
            );
          }
          //this.productFilterOptions.l.push(this.product.product_rates[j].length);
        }
      }
      if (which == "w") {
        //this.productFilterOptions.w = ["all"];
        this.productFilterOptions.l = ["all"];
        if (w == "all") return;
        for (j = 0; j < this.product.product_rates.length; j++) {
          //this.productFilterOptions.w.push(this.product.product_rates[j].width);
          if (
            t == this.product.product_rates[j].thickness &&
            w == this.product.product_rates[j].width
          ) {
            this.productFilterOptions.l.push(
              this.product.product_rates[j].length
            );
          }
        }
      }
    },
  },
  created: function () {
    this.setPageTitle("Buy Product");
    this.setToolbarTitle("Available Variations");
  },
  mounted: function () {
    emitter.on("onSellerProductRatesSuccess", this.onSellerProductRatesSuccess);
    emitter.on("onSellerProductRatesFail", this.onSellerProductRatesFail);
    let city = userService.getUserCity();
    buyService.getSellerProductRates(
      {
        api_id: this.$route.query._id,
        _qty: this.$route.query._qty,
        _material: this.$route.query._material,
        _city_id: city.id,
        _thickness: this.$route.query._thickness,
      },
      this,
      emitter
    );
    this.isLogged = userService.isLogged();
    this.customerToken = userService.getValue("api_id");
  },
  unmounted: function () {
    emitter.off("onSellerProductRatesSuccess");
    emitter.off("onSellerProductRatesFail");
    emitter.off("onCalculatePriceSuccess");
    emitter.off("onCalculatePriceFail");
  },
  setup() {
    const $q = useQuasar();
    return {};
  },
};
</script>