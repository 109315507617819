<template>
  <q-page-container>
    <q-page class="q-pa-xs-md">
      <div v-if="data == null" class="text-center text-bold text-body1">
        <br /><br /><br /><br /><br />
        Opps !! There is no item found in the cart. <br />
        <span class="text-blue" @click="goToSeller()">Click to Shopping</span>
      </div>
      <div v-if="data != null" class="q-pa-none q-ma-none">
        <div v-for="(item, index) in items" v-bind:key="item">
          <q-card
            :id="index"
            :class="
              index % 2 == 0
                ? 'bg-green-1  rounded-borders'
                : 'bg-grey-4 rounded-borders'
            "
            no-shadow
          >
            <q-card-section>
              <div class="row">
                <div class="col">
                  <span class="text-caption text-bold">{{
                    item.mst_product.name
                  }}</span
                  ><br />
                  <q-chip
                    rounded
                    color="blue-2"
                    size="9px"
                    class="text-black text-caption"
                    :label="item.product.brand"
                  />
                  <q-chip
                    rounded
                    color="blue-2"
                    size="9px"
                    class="text-black text-caption"
                    :label="item.product.category"
                  />
                  <q-chip
                    rounded
                    color="blue-2"
                    size="9px"
                    class="text-black text-caption"
                    :label="item.product.grade"
                  />
                </div>
              </div>
              <q-separator />
              <div class="row">
                <div class="col-4">
                  <q-avatar size="70px">
                    <img
                      no-shadow
                      :src="data.productPhotoURL + item.mst_product.img"
                    />
                  </q-avatar>
                </div>
                <div class="col">
                  <div class="text-caption text-italic text-black">
                    <span v-if="item.product.size_type == 'standard'">
                      <br />
                      {{ item.product_rate.size }} MM
                    </span>

                    <span v-if="item.product.size_type == 'coil'">
                      <q-badge squar color="grey-5 text-black" label="Width " />
                      {{
                        item.product_rate.width +
                        " " +
                        item.product_rate.width_uom
                      }}
                      <br />
                      <q-badge
                        squar
                        color="grey-5 text-black"
                        label="Thickness : "
                      />
                      {{
                        item.product_rate.thickness +
                        " " +
                        item.product_rate.thickness_uom
                      }}
                    </span>
                    <!-- FOR SHEET-->
                    <span
                      class="text-regular text-bold"
                      v-if="item.product.size_type == 'sheet'"
                    >
                      <q-badge
                        squar
                        color="grey-5 text-black"
                        label="Width : "
                      />
                      {{
                        item.product_rate.width +
                        " ft"
                      }}
                      <br />
                      <q-badge
                        squar
                        color="grey-5 text-black"
                        label="Thickness : "
                      />
                      {{
                        item.product_rate.thickness +
                        " mm"
                      }}
                      <br />
                      <q-badge
                        squar
                        color="grey-5 text-black"
                        label="Length : "
                      />
                      {{
                        item.product_rate.length +
                        " ft"
                      }}
                    </span>
                    <span  v-if="item.product.mst_color_id > 0">
                        <br /> Color: {{ item.product.color_name }}
                    </span>
                    <span  v-if="item.product.cw_5_10 > 0 || item.product.cw_10_22 > 0 || item.product.cw_22_27 > 0">
                        <br /> Weight: 
                         <span v-if="item.product.cw_5_10 > 0 "> 5-10 ,</span> &nbsp;
                        <span v-if="item.product.cw_10_22 > 0 ">10-22 ,</span> &nbsp;
                        <span v-if="item.product.cw_22_27 > 0 ">22-27</span> &nbsp;
                    </span>
                  </div>
                </div>
              </div>
              <q-separator />
              <div class="row">
                <div class="col-2 text-left">
                  <q-chip
                    square=""
                    color="green text-white text-bold"
                    :label="'#' + (index + 1)"
                  />
                </div>
                <div class="col text-right">
                  <span class="text-caption text-bold">
                    {{ item.qty }} {{ item.product_rate.qty_uom }} x
                    {{ item.price }} =
                  </span>
                  <q-chip
                    squar
                    icon="currency_rupee"
                    square=""
                    color="white text-blue text-bold"
                    :label="item.total"
                  />
                </div>
              </div>
            </q-card-section>
          </q-card>
          <q-separator />
          <br />
        </div>
        <div class="q-pr-md" v-for="(item, name, index) in data.calc" v-bind:key="item">
              <div :id="index" class="row" v-if="data.calc[name].amount > 0">
                  <div class="col text-right text-caption text-bold text-uppercase" style="width:auto">
                      {{data.calc[name].label}}
                  </div>
                  <div class="col text-right text-bold  text-subtitle1 text-pink">
                     <q-icon name="currency_rupee" /> {{data.calc[name].amount}}
                  </div>
              </div>
              <q-separator />
        </div>
        <br />
        <div class="row">
          <div class="col text-right">
            <span class="text-grey-6 text-regular"
              >All above products sold by</span
            ><br />
            &nbsp;<b
              >{{ items[0].seller.trading_name }}, {{ items[0].seller.city }}, {{ items[0].seller.state }}</b
            >
          </div>
        </div>
        <br />
        <q-card class="my-card no-shadow text-bold">
          <q-card-section class="c-bg-color text-white">
            <div class="row">
              <div class="col text-left text-white text-subtitle1 text-bold">
                Billing Address
              </div>
              <div class="col text-right text-caption">
                 <q-btn size="sm" label="edit" color="green" @click="goToX('Account')"/>
              </div>
            </div>
          </q-card-section>
        </q-card>
        <br />
        <div>
          <q-input
            outlined
            label="Business Name"
            dense
            hint=" "
            readonly=""
            input-class="text-black text-bold"
            v-model="form.business_name"
            :rules="[(val) => !!val || 'Required business Name.']"
          />

          <q-input
            outlined
            dense
            label="GST #"
            readonly=""
            hint=" "
            input-class="text-black text-bold"
            v-model="form.gst_num"
          />

          <q-input
            aria-multiline="true"
            rows="2"
            dense
            outlined
            label="Address"
            hint=" "
            input-class="text-black text-bold"
            v-model="form.baddress"
            readonly=""
          />

          <q-input
            outlined
            dense
            label="City"
            hint=" "
            input-class="text-black text-bold"
            v-model="form.bcity" readonly
          />

          <q-input
            outlined
            dense
            label="State"
            hint=" "
            input-class="text-black text-bold"
            v-model="form.bstate" readonly
          />

          <q-input
            outlined
            dense
            label="Postal Code"
            hint=" "
            input-class="text-black text-bold"
            v-model="form.bpostal_code" readonly
          />

          <q-input
            outlined
            dense
            label="Contact #"
            hint=" "
            input-class="text-black text-bold"
            v-model="form.bcontact_num" readonly
          />
        </div>

        <q-card class="my-card no-shadow text-bold">
          <q-card-section class="c-bg-color text-white">
            <div class="row">
              <div class="col text-center text-white text-subtitle1 text-bold">
                Shipping Address
              </div>
              <div class="col text-right text-caption">
                 <q-checkbox v-model="billingInput" dark label=" Same as Billing" color="teal" @click="fillShippingAddr()" />
              </div>
            </div>
          </q-card-section>
        </q-card>
        <br />
        <div>
        <q-form
          @submit="onSubmit"
          v-model="formObj"
          class="q-gutter-md"
          enctype="multipart/form-data"
        >
          <q-input
            aria-multiline="true"
            rows="2"
            dense
            outlined
            label="Address"
            hint=" "
            input-class="text-black text-bold"
            v-model="form.address"
            :rules="[(val) => !!val || 'Required address']"
          />

          <q-input
            outlined
            dense
            label="City"
            hint=" "
            input-class="text-black text-bold"
            v-model="form.city"
            :rules="[(val) => !!val || 'Required city name.']"
          />

          <q-input
            outlined
            dense
            label="State"
            hint=" "
            input-class="text-black text-bold"
            v-model="form.state"
            :rules="[(val) => !!val || 'Required state name']"
          />

          <q-input
            outlined
            dense
            label="Postal Code"
            hint=" "
            input-class="text-black text-bold"
            v-model="form.postal_code"
            :rules="[(val) => !!val || 'Required postal code']"
          />

          <q-input
            outlined
            dense
            label="Contact #"
            hint=" "
            input-class="text-black text-bold"
            v-model="form.contact_num"
            :rules="[(val) => !!val || 'Required contact number']"
          />

          <div>
            <div class="q-gutter-sm">
              <q-checkbox
                v-model="form.transport_required"
                label="Required Transport ?"
                color="teal"
                input-class="text-bold"
              />
              <q-checkbox
                v-model="form.finance_required"
                label="Required Finance ?"
                color="teal"
                input-class="text-bold"
              />
            </div>
          </div>
          <br />
          <div class="text-center">
            <q-btn
              hint=" "
              text-right
              color="blue"
              rounded
              text-color="white"
              label="Place Order"
              type="submit"
            />
          </div>

          <br /><br />
        </q-form>
      </div>
      </div>
    </q-page>
  </q-page-container>
</template>
<script>
import { userService } from "../services/userService";
import { ref } from "vue";
import { useQuasar, Dialog } from "quasar";
import { cartService } from "../services/cartService";
import mitt from "mitt";
import { checkoutService } from "../services/checkoutService";

const emitter = mitt();

export default {
  name: "Checkout",
  data() {
    return {
      isLogged: false,
      data: null,
      items: null,
      total: 0.0,
      formObj: null,
      form: {
        business_name: "",
        address: "",
        city: "",
        state: "",
        postal_code:'',
        contact_num: "",
        gst_num:'',
        transport_required: false,
        finance_required: false,
        baddress:"",
        bcity:"",
        bstate:"",
        bpostal_code:"",
        bcontact_num: "",
      },
      billingInput:false
    };
  },
  methods: {
    onCartGetSuccess: function (data) {
      //console.log(data);
      if (data.data.length > 0) {
        this.data = data;
        this.items = data.data;
        let billing = data.billing;
        for (let i = 0; i < this.items.length; i++) {
          this.total = parseFloat(this.total) + parseFloat(this.items[i].total);
        }
        this.form.business_name = this.items[0].customer.business_name;
        this.form.gst_num = this.items[0].customer.gst_num;
     
        this.form.address = "";
        this.form.city = "";
        this.form.state = "";
        this.form.postal_code = "";
        this.form.contact_num = billing.mobile_num;

        this.form.baddress = billing.address;
        this.form.bcity = billing.city;
        this.form.bstate = billing.state;
        this.form.bpostal_code = billing.postal_code;
        this.form.bcontact_num = billing.mobile_num;
      
      }
    },
    onCartGetFail: function (error) {
      this.showError(error);
    },
    onSubmit: function () {
      //console.log(this.form);
      emitter.on("onPlaceOrderSuccess", this.onPlaceOrderSuccess);
      emitter.on("onPlaceOrderFail", this.onPlaceOrderFail);
      checkoutService.placeOrder(this.form, this, emitter);
      return false;
    },
    onPlaceOrderSuccess(data) {
      this.goToOrderPlacedMessage(data.data[0].api_id);
    },
    onPlaceOrderFail(error) {
      this.showError(error);
    },
    fillShippingAddr(){
      if(this.billingInput){
        this.form.address = this.form.baddress;
        this.form.city = this.form.bcity;
        this.form.state = this.form.bstate;
        this.form.postal_code = this.form.bpostal_code;
        this.form.contact_num = this.form.bcontact_num;
      }else{
        this.form.address = "";
        this.form.city = "";
        this.form.state = "";
        this.form.postal_code = "";
        this.form.contact_num = "";
      }
    }
  },
  created: function () {
    this.setPageTitle("Checkout");
    this.setToolbarTitle("Checkout");
  },
  mounted: function () {
    this.isLogged = userService.isLogged();
    if(!this.isLogged){
      this.goToHome();
    }
    emitter.on("onCartGetSuccess", this.onCartGetSuccess);
    emitter.on("onCartGetFail", this.onCartGetFail);
    cartService.getCartItems("", this, emitter);
  },
  unmounted: function () {
    emitter.off("onCartGetSuccess" );
    emitter.off("onCartGetFail");
    emitter.off("onPlaceOrderSuccess");
    emitter.off("onPlaceOrderFail");
  },
  setup() {},
};
</script>
