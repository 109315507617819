<template>
  <q-page-container>
    <q-page class="flex flex-center" v-if="submitted">
      <span class="text-bold text-subtitle1 text-center text-green">
        <q-icon name="check" size="80px" /> <br />

        Message has been sent successfully !
        <br /><br />
        <q-btn
          color="primary"
          size="sm"
          label="Click to go Dashboard"
          @click="goToHome()"
        />
      </span>
    </q-page>

    <q-page class="q-pa-xs-md q-gutter-md" v-if="!submitted">
      <div class="row">
        <div class="col-12">
          <p class="text-body1">
            If you are looking for transportation. Feel free to message us.
            <br /><br />
          </p>

          <q-form
            @submit="onSubmit"
            class="q-gutter-md"
            enctype="multipart/form-data"
          >
            <q-input
              outlined=""
              dense
              input-class="text-black text-bold"
              label="Business Name*"
              type="text"
              v-model="form.business_name"
              :rules="[(val) => (val && val.length > 0) || '']"
            />

            <q-input
              outlined=""
               dense
              input-class="text-black text-bold"
             
              label="Contact Person Name *"
              v-model="form.contact_person"
              type="text"
              :rules="[(val) => (val && val.length > 0) || '']"
            />

            <q-input
              outlined=""
               dense
              input-class="text-black text-bold"
             
              label="Email *"
              v-model="form.email"
              type="text"
              :rules="[(val) => (val && val.length > 0) || '']"
            />

            <q-input
              outlined=""
               dense
              input-class="text-black text-bold"
             
              label="Mobile #*"
              v-model="form.mobile"
              type="text"
              :rules="[(val) => (val && val.length > 0) || '']"
            />

            <q-input
              outlined=""
               dense
              input-class="text-black text-bold"
             
              label="Message*"
              type="textarea"
              v-model="form.message"
              lazy-rules
              :rules="[(val) => (val && val.length > 0) || 'Enter Message ']"
            />
            <q-btn
              size="md"
              label="Submit"
              type="submit"
              color="blue"
            />
          </q-form>
          <br /><br />
        </div>
      </div>
    </q-page>
  </q-page-container>
</template>
<script>
import { ref } from "vue";
import validationMixin from "../../mixins/validationMixin";
import { userService } from "../../services/userService";
import { emailService } from "../../services/emailService";
import mitt from "mitt";

const emitter = mitt();

export default {
  name: "TransportSupportPage",
  mixins: [validationMixin],
  data() {
    return {
      data: null,
      form: {
        business_name: "",
        contact_person: "",
        email: "",
        mobile: "",
        message: "",
      },
      submitted: false,
    };
  },
  created: function () {},
  mounted: function () {
    this.setToolbarTitle("Need Transport ?");
    this.setPageTitle("Need Transport ?");
    emitter.on("onUserViewSuccess", this.onUserViewSuccess);
    userService.view(this, emitter);
  },
  unmounted: function () {
    emitter.off("onUserViewSuccess");
    emitter.off("onFinanceEmailSuccess");
  },
  methods: {
    onSubmit: function () {
        emitter.on("onTransportEmailSuccess",this.onTransportEmailSuccess)
        emailService.transportEmail(this,this.form,emitter);
    },
    onUserViewSuccess: function (data) {
      this.data = data;
      this.form = data;
      this.form.email = "";
    },
    onTransportEmailSuccess: function(data){
        this.submitted = true;
    }
  },
};
</script>
