import axiosLogged from "../helpers/axiosLogged";

function calculatePrice(payload, ob, emitterObj) {
    ob.showLoading();
    axiosLogged.post("/cart/calculate-price", payload)
        .then(response => {
            if(response.data.data.head.success=="true"){
                emitterObj.emit('onCalculatePriceSuccess', response.data.data.body);
            }else{
                emitterObj.emit('onCalculatePriceFail', response.data.data.body.error.msg);    
            }
            ob.hideLoading();
        }).catch(error => {
            ob.hideLoading();
            emitterObj.emit('onCalculatePriceFail', Promise.reject(error));
        });
}

function addIntoCart(payload, ob, emitterObj) {
    ob.showLoading();
    axiosLogged.post("/cart/add", payload)
        .then(response => {
            if(response.data.data.head.success=="true"){
                emitterObj.emit('onCartAddSuccess', response.data.data.body);
            }else{
                emitterObj.emit('onCartAddFail', response.data.data.body.error.msg);    
            }
            ob.hideLoading();
        }).catch(error => {
            ob.hideLoading();
            emitterObj.emit('onCartAddFail', Promise.reject(error));
        });
}

function removeFromCart(payload, ob, emitterObj) {
    ob.showLoading();
    axiosLogged.post("/cart/remove", payload)
        .then(response => {
            if(response.data.data.head.success=="true"){
                emitterObj.emit('onCartRemoveSuccess', response.data.data.body);
            }else{
                emitterObj.emit('onCartRemoveFail', response.data.data.body.error.msg);    
            }
            ob.hideLoading();
        }).catch(error => {
            ob.hideLoading();
            emitterObj.emit('onCartRemoveFail', Promise.reject(error));
        });
}

function removeAll(ob, emitterObj) {
    ob.showLoading();
    axiosLogged.post("/cart/remove-all", "")
        .then(response => {
            if(response.data.data.head.success=="true"){
                emitterObj.emit('onCartRemoveAllSuccess', response.data.data.body);
            }else{
                emitterObj.emit('onCartRemoveAllFail', response.data.data.body.error.msg);    
            }
            ob.hideLoading();
        }).catch(error => {
            ob.hideLoading();
            emitterObj.emit('onCartRemoveAllFail', Promise.reject(error));
        });
}

function countItemTotal(payload, ob, emitterObj) {
    ob.showLoading();
    axiosLogged.post("/cart/count-item-total", payload)
        .then(response => {
            if(response.data.data.head.success=="true"){
                emitterObj.emit('onCartItemTotalSuccess', response.data.data.body);
            }else{
                emitterObj.emit('onCartItemTotalRemoveFail', response.data.data.body.error.msg);    
            }
            ob.hideLoading();
        }).catch(error => {
            ob.hideLoading();
            emitterObj.emit('onCartRemoveFail', Promise.reject(error));
        });
}

function getCartItems(payload, ob, emitterObj) {
    ob.showLoading();
    axiosLogged.post("/cart/get", payload)
        .then(response => {
            if(response.data.data.head.success=="true"){
                emitterObj.emit('onCartGetSuccess', response.data.data.body);
            }else{
                emitterObj.emit('onCartGetFail', response.data.data.body.error.msg);    
            }
            ob.hideLoading();
        }).catch(error => {
            ob.hideLoading();
            emitterObj.emit('onCartGetFail', Promise.reject(error));
        });
}

function getCalculatedItems(payload, ob, emitterObj) {
    ob.showLoading();
    axiosLogged.post("/cart/get-calculated-items", payload)
        .then(response => {
            if(response.data.data.head.success=="true"){
                emitterObj.emit('onGetCalculatedItemsSuccess', response.data.data.body);
            }else{
                emitterObj.emit('onCalculatedItemsFail', response.data.data.body.error.msg);    
            }
            ob.hideLoading();
        }).catch(error => {
            ob.hideLoading();
            emitterObj.emit('onGetCalculatedItemsFail', Promise.reject(error));
        });
}

export const cartService = {
    calculatePrice,
    addIntoCart,
    getCartItems,
    removeFromCart,
    countItemTotal,
    removeAll,
    getCalculatedItems    
};