import axiosNonLogged from "../helpers/axiosNonLogged";
import axiosLogged from "../helpers/axiosLogged";

function getUserCity() {
    let dt = JSON.parse(localStorage.getItem('_db.city'));
    //console.log(dt);
    return dt;
}

function setUserCity(data) {
    localStorage.setItem('_db.city', JSON.stringify(data));
}

function checkPostalCode(postalCode, cityId, ob, emitterObj) {
    ob.showLoading();
    let payload = { "postal_code": postalCode, "city_id": cityId };
    axiosNonLogged.post("/check-postal-code", payload)
        .then(response => {
            ob.hideLoading();
            //console.log(response);
            if (response.data.data.head.success == "true") {
                emitterObj.emit('onPostalCodeSuccess', response.data.data.body);
            } else {
                emitterObj.emit('onPostalCodeFail', false);
            }
        }).catch(error => {
            ob.hideLoading();
            return Promise.reject(error);
        });
}

function login(form, ob, emitterObj) {

    let payload = {
        "mobile_num": form.mobileNum,
        "otp": form.otp,
        "gOtp": form.gOtp,
    };
    ob.showLoading();
    axiosNonLogged.post("/login", payload)
        .then(response => {
            localStorage.setItem('_db.users', JSON.stringify(response.data.data));
            let user = JSON.parse(localStorage.getItem('_db.users'));
            console.log(response);
            //alert(user);

            if (user.head.success == "true") {
                this.refresh(response.data.data);
                emitterObj.emit('onLoginSuccess', user);
            } else {
                emitterObj.emit('onLoginFail', user.body.error.msg);
            }
            ob.hideLoading();
        }).catch(error => {
            ob.hideLoading();
            emitterObj.emit('onLoginError', user);
            ob.showError("Unable to Connect with the Server, Please try again !!")
            return Promise.reject(error);

        });

}

function validateRegistration(mobileNum, ob, emitterObj) {
    ob.showLoading();
    let payload = { "mobile_num": mobileNum };
    axiosNonLogged.post("/validate-registration", payload)
        .then(response => {
            //console.log(response.data.data);
            ob.hideLoading();
            let user = JSON.parse(JSON.stringify(response.data.data));
            console.log(user);
            if (user.head.success == "true") {
                if (user.body.exist == "true") {
                    emitterObj.emit('OnValidateRegistrationScuccess', true);
                } else { emitterObj.emit('OnValidateRegistrationScuccess', false); }
            } else {
                emitterObj.emit('OnVlidateRegistrationScuccess', false);
                //new
                if (user.head.success == "false") {
                    ob.showError(user.body.error.msg);
                }
                //
            }
        }).catch(error => {
            ob.hideLoading();
            ob.showError("Unable to Connect with the Server, Please try again !!")
            return Promise.reject(error);

        });
}

function view(ob, emitterObj) {
    ob.showLoading();
    axiosLogged.get("/customer/view", "")
        .then(response => {
            let user = JSON.parse(JSON.stringify(response.data.data));
            if (user.head.success == "true") {
                this.refresh(response.data.data);
                this.setUserCity(user.body.cityInfo);
                emitterObj.emit('onUserViewSuccess', user.body);
            } else {
                this.logout();
                emitterObj.emit('onUserViewFail', user.body.error.msg);
            }
            ob.hideLoading();
        }).catch(error => {
            emitterObj.emit('onUserViewError', user);
            ob.showError("Unable to Connect with the Server, Please try again !!")
            return Promise.reject(error);

        });

}
function refresh(result) {
    localStorage.setItem('_db.users', JSON.stringify(result));
    let user = JSON.parse(localStorage.getItem('_db.users'));
}

function register(payload, ob, emitterObj) {
    ob.showLoading();
    axiosNonLogged.post("/create-account", payload)
        .then(response => {
            localStorage.setItem('_db.users', JSON.stringify(response.data.data));
            let user = JSON.parse(localStorage.getItem('_db.users'));
            if (user.head.success == "true") {
                emitterObj.emit('onRegisterSuccess', user);
            } else {
                emitterObj.emit('onRegisterFail', user.body.error.msg);
            }
            ob.hideLoading();
        }).catch(error => {
            emitterObj.emit('onLoginError', user);
            ob.showError("Unable to Connect with the Server, Please try again !!")
            return Promise.reject(error);

        });

}
function deleteMyAccount(ob, emitterObj) {
    ob.showLoading();
    axiosLogged.post("/account/delete-my-account")
        .then(response => {
            let data = response.data.data;
            ob.hideLoading();
            console.log((data.head.success == "true"));
            if (data.head.success == "true") {
                emitterObj.emit('onAccountDeleteSuccess', data);
            } else {
                emitterObj.emit('onAccountDeleteFail', data.body.error.msg);
            }
        }).catch(error => {
            ob.showError("Unable to Connect with the Server, Please try again !!")
            console.log("Catch me !")
            //return Promise.reject(error);
      });

}
function upload(payload, ob, emitterObj) {

}

function update(payload, ob, emitterObj) {
    ob.showLoading();
    axiosLogged.post("/customer/update-account", payload)
        .then(response => {
            let user = JSON.parse(JSON.stringify(response.data.data));
            if (user.head.success == "true") {
                this.refresh(response.data.data);
                emitterObj.emit('onUserUpdateSuccess', user.body);
            } else {
                emitterObj.emit('onUserUpdatFail', user.body.error.msg);
            }
            ob.hideLoading();
        }).catch(error => {
            emitterObj.emit('onUserUpdateError', user);
            ob.showError("Unable to Connect with the Server, Please try again !!")
            return Promise.reject(error);

        });

}

function getAuthToken() {
    let db = localStorage.getItem('_db.users');
    let field = "authToken";
    if (db != "undefined") {
        var ob = JSON.parse(db);
        if (ob != null && Object.prototype.hasOwnProperty.call(ob.head, field)) {
            localStorage.setItem("bearerToken", ob.head[field]);
            return ob.head[field];
        }
        return "";
    }
    return "";
}

function user(field) {
    let db = localStorage.getItem('_db.users');
    if (db != "undefined") {
        var ob = JSON.parse(db);
        if (ob != null && Object.prototype.hasOwnProperty.call(ob.body, field)) return ob.body[field];
        return "";
    }
    return "";
}

function getValue(val) {
    return this.user(val);
}

function getValues() {
    let db = localStorage.getItem('_db.users');
    if (db != "undefined") {
        var ob = JSON.parse(db);
        if (ob != null) {
            return ob.body;
        }
    }
    return "";
}
function isLogged() {
    let token = this.user('api_id');
    if (token !== "") {
        return true;
    }
    return false;
}

function logout() {
    localStorage.clear();
}

function isCustomer() {
    let u = this.getValue("who_is");
    if (u == "customer") {
        return true;
    }
    return false;
}



export const userService = {
    login,
    logout,
    register,
    user,
    getValues,
    isLogged,
    getValue,
    view,
    refresh,
    getAuthToken,
    validateRegistration,
    update,
    getUserCity,
    checkPostalCode,
    setUserCity,
    isCustomer,
    deleteMyAccount

};