<template>
  <q-page-container class="bg-container">
    <q-page class="q-pa-xs-md q-gutter-md">
      <div v-if="product != null">
        <q-list>
          <q-item>
            <q-item-section class="q-pa-xs text-bold">Brand</q-item-section>
            <q-item-section class="text-left">
              <span>{{ product.data.brand }} </span>
            </q-item-section>
            <q-item-section class="text-left">
              <span></span>
            </q-item-section>
          </q-item>
          <q-separator />
          <q-item>
            <q-item-section class="q-pa-xs text-bold"
              >Manufacturer</q-item-section
            >
            <q-item-section class="text-left">
              <span>{{ product.data.manufacturer }}</span>
            </q-item-section>
            <q-item-section class="text-left">
              <span></span>
            </q-item-section>
          </q-item>
          <q-separator />
          <q-item>
            <q-item-section class="q-pa-xs text-bold">Product</q-item-section>
            <q-item-section class="text-left">
              <span>{{ product.data.product }}</span>
            </q-item-section>
            <q-item-section class="text-left">
              <span></span>
            </q-item-section>
          </q-item>
          <q-separator />
        </q-list>
      </div>
      <div class="row">
        <div class="col-12">
          <q-form
            @submit="onSubmit"
            v-model="formObj"
            class="q-gutter-md"
            enctype="multipart/form-data"
          >
            <div class="row">
              <div class="col">
                <q-input
                  dense
                  v-model="form.min_sale_qty"
                  label="MOQ"
                  type="number"
                  step="any"
                  square=""
                  outlined=""
                  hint="0 = any qty can be sale."
                  value="0"
                />
              </div>
              <div class="col q-ml-xs">
                <q-input
                  label="Price Valid Upto"
                  outlined=""
                  square
                  readonly=""
                  dense
                  v-model="form.price_valid_date"
                >
                  <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy
                        ref="qDateProxy"
                        cover
                        transition-show="scale"
                        transition-hide="scale"
                      >
                        <q-date
                          v-model="dateValidUpTo"
                          mask="DD-MM-YYYY"
                          :options="optionsFn"
                        >
                          <div class="row items-center justify-end">
                            <q-btn
                              v-close-popup
                              label="Close"
                              color="primary"
                              flat
                            />
                          </div>
                        </q-date>
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input>
              </div>
            </div>
            <!-- #################### RATES ####### -->
            <div class="row" v-if="form.rates != null">
              <div class="col text-right">
                <q-btn
                  label="Fill Price"
                  size="xs"
                  color="green"
                  @click="openFillPriceDialog()"
                />
              </div>
            </div>
            <div
              v-for="(rate, index) in form.rates"
              v-bind:key="rate"
              :id="index"
              :class="
                index % 2 == 0
                  ? 'bg-green-1  rounded-borders'
                  : 'bg-grey-4 rounded-borders'
              "
            >
              <div class="row q-pa-md">
                <div class="col-2 text-caption">
                  <br />
                  <b>{{ rate.name }}</b>
                </div>
                <div class="col q-ml-xs text-left">
                  <q-input
                    :label="'Qty (' + rate.price_uom.replace('per-', '') + ')'"
                    dense
                    v-model="rate.qty"
                    type="number"
                    step="any"
                    square=""
                    class="bg-white"
                    outlined=""
                  />
                </div>
                <div class="col q-ml-xs">
                  <q-input
                    label="B. Rate"
                    type="number"
                    step="any"
                    square=""
                    class="bg-white"
                    v-model="rate.base_price"
                    dense
                    outlined=""
                  />
                </div>
                <div class="col q-ml-xs">
                  <q-input
                    label="G. Diff"
                    type="number"
                    step="any"
                    class="bg-white text-blue text-bold"
                    square=""
                    dense
                    v-model="rate.gauge_diff"
                    outlined=""
                  />
                </div>
              </div>
              <div class="row">
                <div class="col text-right q-pb-xs">
                  <q-badge
                    :label="'Price: ' + rate.price_uom"
                    color="red"
                    class="text-bold"
                  />&nbsp;&nbsp;
                  <input type="hidden" v-model="rate.price" :model-value="rate.price = parseFloat(rate.base_price) + parseFloat(rate.gauge_diff)" />
                  <q-badge
                    :label="'Total:' + rate.price"
                    color="blue"
                    class="text-bold"
                  />
                  &nbsp;
                </div>
              </div>
            </div>

            <br />
            <q-btn
              size="md"
              rounded
              label="Save"
              type="submit"
              class="c-bg-color text-white"
            />
          </q-form>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-right">&nbsp;</div>
      </div>
    </q-page>
    <!-- fill dialog-->
    <q-dialog v-model="fillPriceDialog" persistent>
      <q-card>
        <q-card-section class="q-ma-lg">
          <div class="row">
            <div class="col">
              <q-select
                dense
                outlined
                label="Size"
                v-model="form.fill_size"
                :options="this.form.rates"
                clearable
                hint=" "
                option-label="name"
                :rules="[(val) => !!val || 'Choose Size']"
              />
            </div>
          </div>
          <div class="row">
            <div class="col">
              <q-input
                label="Base Rate"
                type="number"
                step="any"
                v-model="form.fill_base_price"
                square=""
                hint=" "
                full-width
                class="bg-white"
                dense
                outlined=""
                :rules="[(val) => !!val || 'Enter Base Rate']"
              />
            </div>
          </div>
          <div class="row">
            <div class="col">
              <q-input
                label="Gauge Difference Price"
                type="number"
                step="any"
                hint=" "
                v-model="form.fill_gauge_diff_price"
                square=""
                class="bg-white"
                dense
                outlined=""
                :rules="[(val) => !!val || 'Enter Gauge Diff Rate']"
              />
            </div>
          </div>
          <div class="row">
            <div class="col">
              <q-select
                dense
                outlined
                label="Size"
                v-model="form.fill_uom"
                :options="['per-ton', 'per-kg']"
                hint=" "
                clearable
                option-label="name"
                :rules="[(val) => !!val || 'Choose UOM']"
              />
            </div>
          </div>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn
            size="md"
            outline=""
            label="Cancel"
            color="primary"
            v-close-popup
          />
          <q-btn
            size="md"
            outline=""
            label="Fill Value"
            color="primary"
            @click="fill()"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-page-container>
</template>
<script>
import { ref } from "vue";
import { productService } from "../../../services/productService";
import { date } from "quasar";

import mitt from "mitt";
import { emit } from "process";
const emitter = mitt();

export default {
  name: "ProductAddRateTMTPage",
  data() {
    return {
      form: {
        api_id: this.$route.query._id,
        min_sale_qty: "0",
        price_valid_date: "",
        rates: null,
        fill_size: "",
        fill_base_price: "",
        fill_gauge_diff_price: "",
        fill_base_size: "",
        fill_uom: "per-ton",
      },
      formObj: null,
      fillPriceDialog: false,
      product: null,
    };
  },
  created: function () {
    //emitter.on()
  },
  mounted: function () {
    emitter.on("onGetProductSuccess", this.onGetProductSuccess);
    productService.getProduct(this, { api_id: this.$route.query._id }, emitter);

    this.setToolbarTitle("Product - Update Rate");
    this.setPageTitle("Product - Update Rate");
    
  },
  methods: {
    fill: function () {
      if (this.form.fill_size == "") {
        this.showError("Please choose size");
        return;
      }
      if (this.form.fill_base_price == "") {
        this.showError("Please enter base rate");
        return;
      }
      if (this.form.fill_gauge_diff_price == "") {
        this.showError("Please enter gauge difference rate");
        return;
      }
      if (this.form.fill_uom == "") {
        this.showError("Please choose uom");
        return;
      }

      let basePrice = this.form.fill_base_price;
      let gdPrice = this.form.fill_gauge_diff_price;
      for (let j = 0; j < this.form.rates.length; j++) {
        this.form.rates[j].price_uom = this.form.fill_uom;
        this.form.rates[j].qty = 0;
        if (this.form.fill_size.name != this.form.rates[j].name) {
          this.form.rates[j].gauge_diff = this.form.fill_gauge_diff_price;
          gdPrice = this.form.fill_gauge_diff_price;
        } else {
          this.form.rates[j].gauge_diff = 0;
          gdPrice = 0;
          this.form.fill_base_size = this.form.fill_size.size;
        }

        this.form.rates[j].base_price = basePrice;

        this.form.rates[j].price = parseFloat(basePrice) + parseFloat(gdPrice);
      }
      //console.log(this.form.rates);
      this.fillPriceDialog = false;
    },
    onTMTParamsSuccess: function (data) {
      let fdata = [];
      for (let i = 0; i < data.length; i++) {
        data[i].price_uom = "per-ton";
        data[i].qty = 0;
        data[i].base_price = 0.0;
        data[i].gauge_diff = 0.0;
        data[i].price = 0.0;

        fdata.push(data[i]);
      }
      this.form.rates = fdata;
      //console.log(fdata);
      emitter.on("onGetProductRateSuccess", this.onGetProductRateSuccess);
      productService.getProductRate(
        this,
        { api_id: this.form.api_id },
        emitter
      );
    },
    onGetProductRateSuccess: function (data) {
      if (data.data.length != 0) {
        let pr = data.data;
        for (var i = 0; i < pr.length; i++) {
          for (var j = 0; j < this.form.rates.length; j++) {
            if (pr[i].size == this.form.rates[j].size) {
              this.form.rates[j].price_uom = pr[i].price_uom;
              this.form.rates[j].qty = pr[i].qty;
              this.form.rates[j].base_price = pr[i].base_price;
              this.form.rates[j].gauge_diff = pr[i].gauge_diff;
              this.form.rates[j].price = pr[i].price;
            }
          }
        }
      }
    },
    onGetProductSuccess: function (data) {
      //console.log(data);
      this.product = data;
      this.form.min_sale_qty = data.data.min_sale_qty;
      let idate = data.data.price_valid_date;

      if (idate != "" && idate != "0000-00-00" && idate != null) {
        idate = idate.split("-");
        this.form.price_valid_date = idate[2] + "-" + idate[1] + "-" + idate[0];
        this.dateValidUpTo = idate[2] + "-" + idate[1] + "-" + idate[0];
      } else {
        this.form.price_valid_date = 0;
      }
      //get tmt params
      emitter.on("onTMTParamsSuccess", this.onTMTParamsSuccess);
      let payload = {
        mst_brand_id: data.data.mst_brand_id,
        mst_manufacturer_id: data.data.mst_manufacturer_id,
        mst_product_id: data.data.mst_product_id,
        mst_product_grade_id: data.data.mst_product_grade_id,
      };
      productService.getTMTParams(this, payload, emitter);
    },
    openFillPriceDialog: function () {
      this.fillPriceDialog = true;
    },
    onSubmit: function () {
      if (this.form.price_valid_date == "") {
        this.showError("Please enter price valid upto date.");
        return;
      }

      var oneRow = false;
      if (this.form.rates.length > 0) {
        for (var i = 0; i < this.form.rates.length; i++) {
          if (
            parseInt(this.form.rates[i].qty) > 0 &&
            parseInt(this.form.rates[i].price) > 0
          ) {
            oneRow = true;
            break;
          }
        }
      }
      if (!oneRow) {
        this.showError("Add atleast one product price !", "");
        return;
      }

      console.log(this.form);
      emitter.on("onAddProductRateSuccess", this.onAddProductRateSuccess);
      emitter.on("onAddProductRateFail", this.onAddProductRateFail);
      productService.addProductRate(this, this.form, emitter);
    },
    onAddProductRateSuccess: function (data) {
      ///console.log("----------------------------------------------------");
      //console.log(data);
      this.showInfo("Product Rate Save !");
      this.goToProduct();
    },
    onAddProductRateFail: function (data) {
      //console.log("----------------------------------------------------");
      //console.log(data);
      this.showError(data);
    },
  },
  setup() {
    return {
      dateValidUpTo: ref(date.formatDate(Date.now(), "DD-MM-YYYY").toString()),
      optionsFn(d) {
        let newDate = new Date();
        newDate = date.addToDate(newDate, { year: 1 });
        return (
          d >= date.formatDate(Date.now(), "YYYY/MM/DD") &&
          d <= date.formatDate(newDate, "YYYY/MM/DD")
        );
      },
    };
  },
  unmounted: function () {
    emitter.off("onAddProductRateSuccess");
    emitter.off("onAddProductRateFail");
    emitter.off("onGetProductSuccess");
    emitter.off("onTMTParamsSuccess");
    emitter.off("onParamsSuccess");
  },
  watch: {
    dateValidUpTo: function () {
      //alert(this.date);
      this.form.price_valid_date = this.dateValidUpTo;
    },
  },
};
</script>
