<template>
  <q-page-container class="bg-container">
    <q-page class="q-pa-xs-md q-gutter-md">
      <div v-if="product != null">
        <q-list>
          <q-item>
            <q-item-section class="q-pa-xs text-bold">Brand</q-item-section>
            <q-item-section class="text-left">
              <span>{{ product.data.brand }} </span>
            </q-item-section>
            <q-item-section class="text-left">
              <span></span>
            </q-item-section>
          </q-item>
          <q-separator />
          <q-item>
            <q-item-section class="q-pa-xs text-bold"
              >Manufacturer</q-item-section
            >
            <q-item-section class="text-left">
              <span>{{ product.data.manufacturer }}</span>
            </q-item-section>
            <q-item-section class="text-left">
              <span></span>
            </q-item-section>
          </q-item>
          <q-separator />
          <q-item>
            <q-item-section class="q-pa-xs text-bold">Product</q-item-section>
            <q-item-section class="text-left">
              <span>{{ product.data.product }}</span>
            </q-item-section>
            <q-item-section class="text-left">
              <span></span>
            </q-item-section>
          </q-item>
          <q-separator />
        </q-list>
      </div>
      <div class="row">
        <div class="col-12">
          <q-form
            @submit="onSubmit"
            v-model="formObj"
            class="q-gutter-md"
            enctype="multipart/form-data"
          >
            <div class="row">
              <div class="col">
                <q-input
                  dense
                  v-model="form.min_sale_qty"
                  label="MOQ"
                  type="number"
                   step="any"
                  square=""
                  outlined=""
                  hint="0 = any qty can be sale."
                  value="0"
                />
              </div>
              <div class="col q-ml-xs">
                <q-input
                  label="Price Valid Upto"
                  outlined=""
                  square
                  readonly=""
                  dense
                  v-model="form.price_valid_date"
                >
                  <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy
                        ref="qDateProxy"
                        cover
                        transition-show="scale"
                        transition-hide="scale"
                      >
                        <q-date
                          v-model="dateValidUpTo"
                          mask="DD-MM-YYYY"
                          :options="optionsFn"
                        >
                          <div class="row items-center justify-end">
                            <q-btn
                              v-close-popup
                              label="Close"
                              color="primary"
                              flat
                            />
                          </div>
                        </q-date>
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input>
              </div>
            </div>
             <div v-if="sheetParams!=null && product.data.mst_product.color_coated=='yes'">
            <q-select
              outlined
              dense
              label="Color Code"
              v-model="form.color_code"
              :options="sheetParams.colors"
              clearable
              option-label="label"
              emit-value
              map-options
              :rules="[ val => (!!val) || 'Choose Color' ]"
            />
            </div>
            <div class="row bg-blue-1  rounded-borders q-pa-md">
                <div class="col text-left">
                   
                </div>
                <div class="col text-right">
                    <q-select
                    label="Price & Qty UOM"
                    dense
                    outlined
                    v-model="price_uom"
                    :options="['per-ton','per-kg']"
                    class="bg-white"
                    @update:model-value="OnPriceUOMChange()"
                  />

                </div>
            </div>
            <div
              v-for="(rate, index) in form.rates"
              v-bind:key="rate"
              :id="index"
              :class="
                index % 2 == 0
                  ? 'bg-green-1  rounded-borders q-pa-md'
                  : 'bg-grey-4 rounded-borders  q-pa-md'
              ">
              <div class="row" v-if="index!=0">
                  <div class="col text-right">
                    <q-badge
                    label=" X "
                    color="red"
                    class="text-bold"
                    @click="removeRow(index)"
                  />
                  
                  </div>
              </div>
              <div class="row">
                <div class="col q-pa-xs text-center">
                  <span class="text-caption text-black">Thickness</span><br />
                  <q-input
                    square=""
                    class="bg-white"
                    v-model="rate.thickness"
                    type="number"
                    step="any"
                    dense
                    outlined=""
                  />
                </div>
                <div class="col q-pa-xs text-center">
                  <span class="text-black text-caption">T. UOM</span><br />
                  <q-select
                    dense
                    outlined
                    v-model="rate.thickness_uom"
                    :options="sheetParams.thickness_uoms"
                    class="bg-white"
                           emit-value
                  />
                </div>
                <!--
                <div class="col-1 q-pa-md text-center"><br />x</div>
                -->
                <div class="col q-pa-xs text-center">
                  <span class="text-black text-caption">Width</span><br />
                  <q-input
                    type="number"
                    square=""
                     step="any"
                    class="bg-white"
                    v-model="rate.width"
                    dense
                    outlined=""
                  />
                </div>
                <div class="col q-pa-xs text-center">
                  <span class="text-black text-caption">W. Uom</span><br />
                  <q-select
                    dense
                    outlined
                    v-model="rate.width_uom"
                    :options="sheetParams.width_uoms"
                    class="bg-white"
                           emit-value
                  />
                </div>
              </div>
              <div class="row">
                <!--
                <div class="col-1 q-pa-md text-center"><br />x</div>
                -->
                <div class="col q-pa-xs text-center">
                  <span class="text-black text-caption">Length</span><br />
                  <q-input
                    type="number"
                    square=""
                     step="any"
                    class="bg-white"
                    v-model="rate.length"
                    dense
                    outlined=""
                  />
                </div>
                <div class="col q-pa-xs text-center">
                  <span class="text-black text-caption">L. Uom</span><br />
                  <q-select
                    dense
                    outlined
                    v-model="rate.length_uom"
                    :options="sheetParams.length_uoms"
                    class="bg-white"
                           emit-value
                  />
                </div>
                <div class="col q-pa-xs text-center">
                  <span class="text-black text-caption">Qty ({{rate.price_uom.replace('per-','')}})</span><br />
                  <q-input
                    type="number"
                    square=""
                     step="any"
                    class="bg-white"
                    v-model="rate.qty"
                    dense
                    outlined=""
                  />
                </div>
                <div class="col q-pa-xs text-center">
                  <span class="text-black text-caption">Price</span><br />
                  <q-input
                    type="number"
                     step="any"
                    square=""
                    class="bg-white"
                    v-model="rate.price"
                    dense
                    outlined=""
                  />
                </div>
              </div>
              <div class="row">
                
                  <div class="col text-right">
                  <q-badge
                    :label="'Price : '+ rate.price_uom"
                    color="purple"
                    class="text-bold"
                  />&nbsp;&nbsp;
                </div>
              </div>
            </div>
            <div class="row">
                   
                  <div class="col text-right">
                  <q-btn
                    label="+ add row"
                    size="xs"
                    color="green"
                    class="text-bold"
                    @click="addRow(1)"
                  />
                </div>
              </div>
            <br />
            <q-btn
              size="md"
              rounded
              label="Save"
              type="submit"
              class="c-bg-color text-white"
            />
          </q-form>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-right">&nbsp;</div>
      </div>
    </q-page>
  </q-page-container>
</template>
<script>
import { ref } from "vue";
import { productService } from "../../../services/productService";
import { date } from "quasar";
import NumericDiractive from "../../../directives/Numeric.js";

import mitt from "mitt";
import { emit } from "process";
const emitter = mitt();

export default {
  name: "ProductAddRateSheetPage",
  directives:{
    NumericDiractive,
  },
  data() {
    return {
      form: {
        api_id: this.$route.query._id,
        min_sale_qty: "0",
        price_valid_date: "",
        rates: null,
        color_code:'',
      },
      formObj: null,
      product: null,
      sheetParams: null,
      defaultRows:1,
      price_uom:'per-ton',
    };
  },
  created: function () {
    
  },
  mounted: function () {
    emitter.on("onGetProductSuccess", this.onGetProductSuccess);
    productService.getProduct(this, { api_id: this.$route.query._id }, emitter);
    this.setToolbarTitle("Product - Update Rate");
    this.setPageTitle("Product - Update Rate");
  },
  methods: {
    addRow: function (cnt) 
    {
        if(this.form.rates==null){
            this.form.rates = [];
        }
        for(var i=0;i<cnt;i++){
            let row = {
                qty: 0,
                qty_uom: "per-ton",
                thickness: 0,
                thickness_uom: "MM",
                width: 0,
                width_uom: "MM",
                length: 0,
                length_uom: "MM",
                price: 0,
                price_uom: this.price_uom,
            };
            this.form.rates.push(row);
        }
      
    },
    removeRow:function(index){
        this.form.rates.splice(index,1);
    },
    OnPriceUOMChange: function(){
        for(let j=0;j<this.form.rates.length;j++){
            this.form.rates[j].price_uom = this.price_uom;
        }
    },
    onGetProductSuccess: function (data) {
      //console.log(data);
      this.product = data;
      this.form.min_sale_qty = data.data.min_sale_qty;
      this.form.color_code = data.data.color_code;
      let idate = data.data.price_valid_date;

      if (idate != "" && idate != "0000-00-00" && idate != null) {
        idate = idate.split("-");
        this.form.price_valid_date = idate[2] + "-" + idate[1] + "-" + idate[0];
        this.dateValidUpTo = idate[2] + "-" + idate[1] + "-" + idate[0];
      } else {
        this.form.price_valid_date = 0;
      }
      //get Sheet params
      emitter.on("onSheetParamsSuccess", this.onSheetParamsSuccess);
      let payload = {
        mst_brand_id: data.data.mst_brand_id,
        mst_manufacturer_id: data.data.mst_manufacturer_id,
        mst_product_id: data.data.mst_product_id,
        mst_product_grade_id: data.data.mst_product_grade_id,
      };
      productService.getSheetParams(this, payload, emitter);
    },
    onSheetParamsSuccess: function (data) {
      this.sheetParams = data;
      //console.log(data);
      emitter.on("onGetProductRateSuccess", this.onGetProductRateSuccess);
      productService.getProductRate(
        this,
        { api_id: this.form.api_id },
        emitter
      );
    },
    onGetProductRateSuccess: function (data) {
      if (data.data.length != 0) {
        let pr = data.data;
        //console.log("==============================");
        //console.log(data.data);
        this.form.rates = [];
        for (var j = 0; j < pr.length; j++) 
        {
        let row = {
            qty: pr[j].qty,
            qty_uom: pr[j].qty_uom,
            thickness: pr[j].thickness,
            thickness_uom: pr[j].thickness_uom,
            width: pr[j].width,
            width_uom: pr[j].width_uom,
            length: pr[j].length,
            length_uom: pr[j].length_uom,
            price: pr[j].price,
            price_uom: pr[j].price_uom,
        };
        this.price_uom = pr[j].price_uom;
        this.form.rates.push(row);  
        }
        //console.log("-------------------------------------")
        //console.log(this.form.rates);
        
      } else {
        this.addRow(this.defaultRows);
      }
    },
    onSubmit: function () {
      if (this.form.price_valid_date == "") {
        this.showError("Please enter price valid upto date.");
        return;
      }

      var oneRow = false;
      if (this.form.rates.length > 0) {
        for (var i = 0; i < this.form.rates.length; i++) {
          if (
            parseInt(this.form.rates[i].qty) > 0 &&
            parseInt(this.form.rates[i].price) > 0 &&
            (this.form.rates[i].thickness !="" && this.form.rates[i].thickness > 0) &&
            (this.form.rates[i].width !="" && this.form.rates[i].width > 0) &&
            (this.form.rates[i].length !="" && this.form.rates[i].length > 0)
            
          ) {
            oneRow = true;
            break;
          }
        }
      }
      if (!oneRow) {
        this.showError("Add atleast one product price !", "");
        return;
      }
      //alert("submitted")  
      //console.log(this.form);
      emitter.on("onAddProductRateSuccess", this.onAddProductRateSuccess);
      emitter.on("onAddProductRateFail", this.onAddProductRateFail);
      productService.addProductRate(this, this.form, emitter);
    },
    onAddProductRateSuccess: function (data) {
      ///console.log("----------------------------------------------------");
      //console.log(data);
      this.showInfo("Product Rate Save !");
      this.goToProduct();
    },
    onAddProductRateFail: function (data) {
      //console.log("----------------------------------------------------");
      //console.log(data);
      this.showError(data);
    },
  },
  setup() {
    return {
      dateValidUpTo: ref(date.formatDate(Date.now(), "DD-MM-YYYY").toString()),
      optionsFn(d) {
        let newDate = new Date();
        newDate = date.addToDate(newDate, { year: 1 });
        return (
          d >= date.formatDate(Date.now(), "YYYY/MM/DD") &&
          d <= date.formatDate(newDate, "YYYY/MM/DD")
        );
      },
    };
  },
  unmounted: function () {
    emitter.off("onAddProductRateSuccess");
    emitter.off("onAddProductRateFail");
    emitter.off("onGetProductSuccess");
    emitter.off("onSheetParamsSuccess");
    emitter.off("onParamsSuccess");
    //emitter.off("onUserViewFaile");
  },
  watch: {
    dateValidUpTo: function () {
      //alert(this.date);
      this.form.price_valid_date = this.dateValidUpTo;
    },
  },
};
</script>
