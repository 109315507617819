<template>
  <q-page-container class="bg-container">
    <q-page class="q-pa-xs-md q-gutter-md">
      <div class="row">
        <div class="col-12 text-center text-bold">
          <br />
          <q-btn round class="">
            <q-avatar size="120px" @click="goToDashboard()">
              <!--<img src="../assets/img/splash.jpg" style="width: auto" />-->
              <img src="../assets/img/new_splash.png" style="width: auto" />
            </q-avatar>
          </q-btn>
        </div>
      </div>
      <div class="text-center">
        <span class="text-h6 text-bold text-center text-grey-7">
          Register with Us !</span
        ><br />
        <span class="text-body text-center text-grey-5"
          >To Start Selling and Buying Steel</span
        >
      </div>
      <br />
      <div class="row">
        <div class="col-12">
          <q-input
            rounded
            outlined
            v-model="form.business_name"
            label="Business Name*"
            type="text"
            :rules="[(val) => (val && val.length > 0) || '']"
          />

          <q-input
            rounded
            outlined
            v-model="form.contact_person"
            label="Your Name *"
            type="text"
            :rules="[(val) => (val && val.length > 0) || '']"
          />
          <q-input
            rounded
            outlined
            v-model="form.postal_code"
            label="Your City Pin Code *"
            type="text"
            :rules="[(val) => (val && val.length > 0) || '']"
          />

          <q-input
            prefix="+91"
            rounded
            outlined
            v-model="form.mobile_num"
            label="Mobile Number*"
            maxlength="10"
            placeholder="Mobile # will be use for login."
            type="number"
            :rules="[
              (val) => (val && val.length >= 10) || 'Enter mobile number !',
            ]"
            hint=" "
            inputClass="text-bold"
          >
            <template v-slot:prepend>
              <q-icon name="phone" />
            </template>
          </q-input>
          <!-- captacha -->
          <div class="row" v-show="!smsSent">
            <div class="col">
              <div id="recaptcha-container"></div>
            </div>
          </div>
          <q-input
            rounded
            outlined
            type="number"
            v-model="form.gOtp"
            label="OTP*"
            maxlength="6"
            lazy-rules
            :rules="[(val) => (val && val.length > 0) || 'Enter Otp !']"
            hint=" "
            v-show="smsSent"
          >
            <template v-slot:prepend>
              <q-icon name="lock" />
            </template>
          </q-input>
          <div class="q-gutter-xs" v-show="smsSent">
            <q-checkbox v-model="cAgree" label=" " color="blue" />
            <span class="text-blue" @click="showToggleDialog(true)"
              >I Agree - Terms & Conditions</span
            >
            <br />
          </div>
          <div class="row">
            <div class="col">
              <br />
            </div>
          </div>
          <div class="row">
            <div class="col" v-show="smsSent">
              <q-btn
                size="md"
                style="width: auto"
                rounded=""
                label="Submit"
                type="button"
                color="green"
                @click="onFormSubmit"
                v-show="smsSent"
              />
            </div>
            <div class="col text-center">
              <q-btn
                size="md"
                style="width: auto"
                rounded=""
                :label="otpBtnLabel"
                type="button"
                id="log-in"
                class="c-bg-color text-white"
                v-show="otpBtn"
                :disable="enableOtpBtn"
                @click="sendOTP(false)"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          class="col-12 text-bold text-right text-grey-7 text-primary"
          @click="goToHome()"
        >
          Back to Login ?
        </div>
      </div>
      <!-- terms and conditions dialog --->
      <div>
        <q-dialog v-model="dialog" persistent full-width v-show="dialog">
          <q-card>
            <q-card-section v-html="tocContents"> </q-card-section>
          </q-card>
          <q-card class="text-black text-center bg-black">
            <q-card-section>
              <q-btn
                size="10px"
                label="Close"
                color="primary"
                @click="showToggleDialog(false)"
              />
            </q-card-section>
          </q-card>
        </q-dialog>
      </div>
      <!-- alert comp -->
      <alert-comp :msg="alertMsg" :show="alertShow" />
      <!-- /alert comp-->
    </q-page>
  </q-page-container>
</template>
<script>
import { ref } from "vue";
import { userService } from "../services/userService";
import mitt from "mitt";
import AlertComp from "../components/AlertComp.vue";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { cmsService } from "../services/cmsService";

import {
  getAuth,
  signInWithPhoneNumber,
  RecaptchaVerifier,
} from "firebase/auth";

const emitter = mitt();

const OTP_TIMER = 120;

export default {
  name: "HomePage",
  components: {
    AlertComp,
  },
  data() {
    return {
      countryCode: "+91",
      dialog: false,
      form: {
        business_name: "",
        contact_person: "",
        mobile_num: "",
        otp: "2022",
        gOtp: "",
        postal_code:"",
      },
      resendBtn: false,
      otpBtn: true,
      otpInput: false,
      resendTimer: OTP_TIMER,
      resendCloseTimer: null,
      enableResendBtn: true,
      cAgree: false,
      alertShow: false,
      alertMsg: "",

      otpBtnLabel: "Send OTP",
      enableOtpBtn: false,

      confirmationResult: null,
      recaptchaVerifier: null,
      recaptchaWidgetId: null,
      confirmResult: null,
      smsSent: false,
      capatchVerified: false,
      tocContents: "",
    };
  },
  created: function () {
    if (userService.isLogged()) {
      this.goToDashboard();
    }
  },
  mounted: function () {
    this.setToolbarTitle("Register");
    this.setPageTitle("Register");
    firebase.auth().useDeviceLanguage();
    this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier("log-in", {
      size: "invisible",
      callback: (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        this.capatchVerified = true;
        console.log(response);
      },
    });
    emitter.on("onTocSuccess", this.onTocSuccess);
    cmsService.getToc(this, emitter);
  },
  methods: {
    showToggleDialog: function (bool) {
      this.dialog = bool;
    },
    onTocSuccess: function (data) {
      //alert(data);
      this.tocContents = data;
    },
    onFormSubmit() {
      if (this.form.business_name == "") {
        this.showAlert("Please enter Business Name.");
        return;
      }
      if (this.form.contact_person == "") {
        this.showAlert("Please enter your name.");
        return;
      }
      if (this.form.otp == "") {
        this.showAlert("Please enter Otp.");
        return;
      }
      if (this.cAgree == false) {
        this.showAlert("Please accept termns & conditions");
        return;
      }
      this.confirmResult
        .confirm(this.form.gOtp)
        .then((result) => {
          var user = result.user;
          emitter.on("onRegisterSuccess", this.onRegisterSuccess);
          emitter.on("onRegisterFail", this.onRegisterFail);
          userService.register(this.form, this, emitter);
          console.log(user);
        })
        .catch((error) => {
          this.showError("Invalid Otp - " + error);
        });
    },
    onRegisterSuccess(result) {
      console.log(result);
      this.goToDashboard();
    },
    showAlert: function (msg) {
      //this.alertShow = true;
      //this.alertMsg = msg;
      this.showError(msg);
    },
    onRegisterFail(error) {
      this.showError(error);
    },
    mobileExist() {},
    OnValidateRegistrationScuccess(bool) {
      if (bool) {
        this.showError(
          this.form.mobile_num + " - already registered with us !"
        );
      } else {
        /*
        this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
          "recaptcha-container"
        );*/
        this.recaptchaVerifier.render().then((widgetId) => {
          this.recaptchaWidgetId = widgetId;
        });

        var number = this.countryCode + this.form.mobile_num;
        firebase
          .auth()
          .signInWithPhoneNumber(number, this.recaptchaVerifier)
          .then((confirmationResult) => {
            this.confirmResult = confirmationResult;
            //console.log(this.confirmResult);
            this.showInfo("Otp Sent to your mobile - " + this.form.mobile_num);
            //alert("Sms Sent!");
            this.smsSent = true;
          })
          .catch((error) => {
            this.showError("OTP not sent - " + error.message);
          });

        this.enableOtpBtn = true;
        this.resendTimer = OTP_TIMER;
        this.countDownTimer();
      }
      /*
      if (resend == true) {
        //
        this.resendTimer = OTP_TIMER;
      } else {
        //this.otpInput = true;
        this.otpBtn = true;
      }
      this.enableOtpBtn = true;
      this.countDownTimer();
        }*/
    },
    sendOTP: function (resend) {
      if (this.form.mobile_num.length < 10) {
        this.showAlert("Please enter proper mobile number.");
        //console.log("================");
        return;
      } else {
        this.smsSent = false;
        emitter.on(
          "OnValidateRegistrationScuccess",
          this.OnValidateRegistrationScuccess
        );
        userService.validateRegistration(this.form.mobile_num, this, emitter);

        //firebase.auth().settings.appVerificationDisabledForTesting = true;
      }
    },
    countDownTimer: function () {
      if (this.resendTimer > 0) {
        this.resendCloseTimer = setTimeout(() => {
          this.resendTimer -= 1;
          this.countDownTimer();
        }, 1000);
      } else {
        this.resendTimer = 0;
        this.enableOtpBtn = false;
      }
      this.otpBtnLabel = "Re-Send OTP ( " + this.resendTimer + " ) ";
    },
  },
  setup() {
    return {
      navPos: ref("bottom"),
      slide: ref("style"),
      autoplay: ref(true),
    };
  },
  unmounted: function () {
    emitter.off("onLoginSuccess");
    emitter.off("onLoginFail");
    emitter.off("onLoginFailError");
    emitter.off("onTocSuccess");
  },
  watch: {
    resendTimer: function () {
      //console.log(this.resendTimer);
      if (this.resendTimer == OTP_TIMER) {
        clearInterval(this.resendTimer);
        //this.$router.push({ name: "Home" });
        //this.goToHome();
      }
    },
  },
};
</script>
