import axiosLogged from "../helpers/axiosLogged";
import mitt from 'mitt';

function financeEmail(ob, payload, emitterObj) {
    ob.showLoading();
    axiosLogged.post("/account/finance-email", payload)
        .then(response => {
            ob.hideLoading();
            emitterObj.emit('onFinanceEmailSuccess', response.data.data.body.data);
        }).catch(error => {
            ob.hideLoading();
            emitterObj.emit('onFinanceEmailFail', Promise.reject(error));
        });
}

function transportEmail(ob, payload, emitterObj) {
    ob.showLoading();
    axiosLogged.post("/account/transport-email", payload)
        .then(response => {
            ob.hideLoading();
            emitterObj.emit('onTransportEmailSuccess', response.data.data.body.data);
        }).catch(error => {
            ob.hideLoading();
            emitterObj.emit('onTransportEmailFail', Promise.reject(error));
        });
}

function contactEmail(ob, payload, emitterObj) {
    //console.log(payload)
    //console.log(payload.city.label)
    //alert(payload.city.label);
    //return false;
    ob.showLoading();
    axiosLogged.post("/account/contact-us-email", payload)
        .then(response => {
            ob.hideLoading();
            emitterObj.emit('onContactEmailSuccess', response.data.data.body.data);
        }).catch(error => {
            ob.hideLoading();
            emitterObj.emit('onContactEmailFail', Promise.reject(error));
        });
}

function demoEmail(ob, payload, emitterObj) {
    ob.showLoading();
    axiosLogged.post("/account/demo-email", payload)
        .then(response => {
            ob.hideLoading();
            emitterObj.emit('onDemoEmailSuccess', response.data.data.body.data);
        }).catch(error => {
            ob.hideLoading();
            emitterObj.emit('onDemoEmailFail', Promise.reject(error));
        });
}

function becomeSellerEmail(ob, payload, emitterObj) {
    //console.log(payload)
    ob.showLoading();
    axiosLogged.post("/account/become-seller-email", payload)
        .then(response => { 
            ob.hideLoading();
            emitterObj.emit('onBecomeSellerEmailSuccess', response.data.data.body.data);
        }).catch(error => { alert("error");
            ob.hideLoading();
            emitterObj.emit('onBecomeSellerEmailFail', Promise.reject(error));
        });
}

export const emailService = {
    financeEmail,
    transportEmail,
    contactEmail,
    demoEmail,
    becomeSellerEmail
};