import axiosLogged from "../helpers/axiosLogged";

function makePayment(payload, ob, emitterObj) {
    ob.showLoading();
    axiosLogged.post("/payment/make-payment", payload)
        .then(response => {
            if (response.data.data.head.success == "true") {
                emitterObj.emit('onMakePaymentSuccess', response.data.data.body);
            } else {
                emitterObj.emit('onMakePaymentFail', response.data.data.body.error.msg);
            }
            ob.hideLoading();
        }).catch(error => {
            ob.hideLoading();
            emitterObj.emit('onMakePaymentFail', Promise.reject(error));
        });
}

export const paymentService = {
    makePayment
};