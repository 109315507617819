<template>
  <q-page-container class="bg-container">
    <q-page class="q-pa-xs-md q-gutter-md">
      <div class="row">
        <div class="col-12">
          <q-input
            v-model="search"
            rounded
            dense
           outlined=""
            placeholder="Search Sellers "
            @click="prompt"
          >
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
          <br />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <q-btn
            rounded
            size="md"
            flat
            class="
              full-width
              text-center text-bold text-caption
              c-bg-color
              text-white
              rounded-bordered
            "
            label="TMT Steel Sellers"
          />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <q-card class="no-shadow">
            <q-card-section v-for="item in 50" v-bind:key="item" class="text-bold">
              <div class="row">
                <div class="col-3"><img src="//via.placeholder.com/60x60" style="width: 60px;height:60px;" /></div>
                <div class="col">
                  <q-span class="text-bold  color-blue">Aggrwal-{{ item }} Steel Pvt Ltd. </q-span><br />
                  <span class="text-grey text-weight-light">New Delhi - 11000{{ item }}</span><br />
                  <q-badge label="10,000/- per ton" color="green-6" />
                </div>
                <div class="col-2 text-right">
                    <br />
                  <q-btn
                    size="9px"
                    color="blue"
                    label="View"
                    @click="goToSellerDetail()"
                  /><br /><br />
                </div>
              </div>
              <q-separator />
            </q-card-section>  
          </q-card>
        </div>
      </div>
    </q-page>
  </q-page-container>
</template>
<script>
import { userService } from "../services/userService";
import { ref } from "vue";
import { useQuasar, Dialog } from "quasar";
import SearchFormComp from "../components/SearchForm.vue";

export default {
  name: "SearchPage",
  //components:[
  //    SearchFormComp
  // ],
  data() {
    return {
      isLogged: userService.isLogged(),
    };
  },
  methods: {
    prompt: function () {
      Dialog.create({
        component: [SearchFormComp],
        ok: {
          push: true,
        },
        cancel: {
          color: "negative",
        },
        persistent: true,
      });
      /*
      this.$q.dialog({
        component: this.SearchFormComp,
        // props forwarded to your custom component
        componentProps: {
          //text: "something",
          // ...more..props...
        },
      })
        .onOk(() => {
          console.log("OK");
        })
        .onCancel(() => {
          console.log("Cancel");
        })
        .onDismiss(() => {
          console.log("Called on OK or Cancel");
        });
        */
    },
  },
  created: function () {
    this.setPageTitle("Sellers");
    this.setToolbarTitle("Sellers");
  },
  setup() {
    const $q = useQuasar();
    return {
      tab: ref("one"),
    };
  },
};
</script>
<style lang="sass" scoped>
.my-card
    width: 100%
</style>
<style lang="sass" scoped>
.row-1 > div
    padding: 10px 15px

    border: 1px solid rgba(86,61,124,.2)
</style>
