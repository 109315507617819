<template>
  <q-page-container>
    <!-- Transporter -->
    <q-page class="q-pa-xs-md q-gutter-md" v-if="!isCustomer">
      <div class="text-center text-black text-green text-subtitle text-bold">
        Welcome {{ business }} ! <br />
        {{ address }}
        <br />
        <div href='#' @click="goToX('Logout')" class="text-red text-caption text-bold">[ Logout ]</div>
      </div>
      <q-separator />
      <q-card class="my-card no-shadow text-bold">
        <q-card-section class="bg-grey-4 text-grey-7">
          <div class="row">Available Delivery Requests</div>
        </q-card-section>
      </q-card>
      <template v-if="orders == 'na'">
        <h6>Opps ! There is no delivery request found !</h6>
      </template>
      <template v-if="orders != '' && orders !== 'na'">
        <q-card class="no-shadow">
          <div v-for="(item, index) in orders" v-bind:key="item">
            <q-card-section
              :class="
                index % 2 == 0
                  ? 'bg-green-1  rounded-borders'
                  : 'bg-grey-4 rounded-borders'
              "
            >
              <div class="row">
                  <div class="col text-left"><q-badge label="Pickup Address" color="blue" /><br />
                  </div>
                  <div class="col text-right">
                    <q-badge :label="'INV #'+item.invoice_num" color="grey" />
                  </div>
              </div>
              <div class="row">
                <div class="col">
                  <span class="text-bold color-blue text-uppercase">{{
                    item.assign_seller.business_name
                  }}</span
                  ><br />

                  <span class="text-grey text-weight-light">{{
                    item.assign_seller.address
                  }}</span>
                  ,
                  <span class="text-grey text-weight-light"
                    >{{ item.assign_seller.city }},
                    {{ item.assign_seller.state }} -
                    {{ item.assign_seller.postal_code }}</span
                  >
                </div>
              </div>
              <q-separator />
              <div class="row">
                <div class="col">
                  <q-badge label="Delivery Address" color="green" /> <br />
                  <span class="text-bold color-blue text-uppercase">{{
                    item.customer.business_name
                  }}</span
                  ><br />

                  <span class="text-grey text-weight-light">{{
                    item.customer.address
                  }}</span>
                  ,
                  <span class="text-grey text-weight-light"
                    >{{ item.customer.city }}, {{ item.customer.state }} -
                    {{ item.customer.postal_code }}</span
                  >
                </div>
              </div>
              <q-separator />
              <div class="q-pa-sm q-gutter-sm text-right">
                <!--order view-->
                <q-btn
                  square
                  size="9px"
                  color="blue"
                  label="Download Invoice"
                  @click="downloadInvoiceReceipt(item.api_id, item.invoice_num)"
                />
              </div>
            </q-card-section>
          </div>
        </q-card>
      </template>
    </q-page>
    <!--###############################################################################################-->
    <!-- customer -->
    <q-page class="q-pa-xs-md q-gutter-md" v-if="isCustomer">
      <!-- boxes -->
      <div class="row">
        <div class="col q-mr-md">
          <q-card
            class="my-card text-center shadow-1"
            @click="goToOrderRecieved()"
            v-show="visibleRecievedOrder"
          >
            <q-btn round>
              <q-avatar size="70px" icon="money" class="c-bg-color text-white">
              </q-avatar>
            </q-btn>
            <q-card-section>
              <div class="text-caption text-center text-blue text-bold">
                Orders Received
              </div>
            </q-card-section>
          </q-card>
          <!-- IF ALLOWED BUY ADN SELL OR Owner account customers.main=1 -->
          <q-card
            class="my-card text-center shadow-1"
            @click="goToAllProduct()"
            v-show="!visibleRecievedOrder"
          >
            <q-btn round>
              <q-avatar size="70px" icon="shop" class="c-bg-color text-white">
              </q-avatar>
            </q-btn>
            <q-card-section>
              <div class="text-caption text-center text-blue text-bold">
                Buy <br />
                Products
              </div>
            </q-card-section>
          </q-card>
        </div>
        <div class="col q-mr-md">
          <q-card
            class="my-card text-center shadow-1"
            @click="goToOrderPlaced()"
          >
            <q-btn round>
              <q-avatar
                size="70px"
                icon="ion-basket"
                class="c-bg-color text-white"
              >
              </q-avatar>
            </q-btn>
            <q-card-section>
              <div class="text-caption text-center text-blue text-bold">
                My <br />Orders
              </div>
            </q-card-section>
          </q-card>
        </div>
        <div class="col q-mr-md">
          <q-card class="my-card text-center shadow-1" @click="goToContactUs()">
            <q-btn round>
              <q-avatar
                size="70px"
                icon="contact_mail"
                class="c-bg-color text-white"
              >
              </q-avatar>
            </q-btn>
            <q-card-section>
              <div class="text-caption text-center text-blue text-bold">
                Contact <br />
                Us
              </div>
            </q-card-section>
          </q-card>
        </div>
      </div>

      <div class="row">
        <div class="col q-mr-md">
          <q-card
            class="my-card text-center shadow-1"
            @click="goToFinanceSupport()"
          >
            <q-btn round>
              <q-avatar
                size="70px"
                icon="payments"
                class="c-bg-color text-white"
              >
              </q-avatar>
            </q-btn>
            <q-card-section>
              <div class="text-caption text-center text-blue text-bold">
                Need Finance
              </div>
            </q-card-section>
          </q-card>
        </div>
        <div class="col q-mr-md">
          <q-card
            class="my-card text-center shadow-1"
            @click="goToTransportSupport()"
          >
            <q-btn round>
              <q-avatar
                size="70px"
                icon="local_shipping"
                class="c-bg-color text-white"
              >
              </q-avatar>
            </q-btn>
            <q-card-section>
              <div class="text-caption text-center text-blue text-bold">
                Need Transport
              </div>
            </q-card-section>
          </q-card>
        </div>
        <div class="col">
          <!--
          <q-card class="my-card text-center shadow-1" @click="goToShortInventory()">
              <q-btn round>
                <q-avatar size="70px" icon="inventory" class="c-bg-color text-white">
                </q-avatar>
              </q-btn>
            <q-card-section>
              <div class="text-caption text-center text-blue text-bold">
                Short Inventory
              </div>
            </q-card-section>
          </q-card>
          -->
        </div>
      </div>
      <!-- /end boxes -->
      <!-- end /blogs-->
    </q-page>
  </q-page-container>
</template>
<script>
import { userService } from "../../services/userService";
import { transporterService } from "../../services/transporterService";

import { ref } from "vue";
import mitt from "mitt";
import { invoiceService } from "../../services/invoiceService";

const emitter = mitt();

export default {
  name: "MyBizPage",
  data() {
    return {
      isLogged: userService.isLogged(),
      dialog: false,
      mRates: null,
      visibleRecievedOrder: false,
      isCustomer: true,
      business: "",
      address: "",
      orders: "",
    };
  },
  created: function () {
    this.setPageTitle("My Business");
    this.setToolbarTitle("My Business");

    //allowed buy and sell
    if (userService.getValue("allowed") == "buy-sell") {
      this.visibleRecievedOrder = true;
    } else if (userService.getValue("main") == "1") {
      ////owner account
      this.visibleRecievedOrder = true;
    }
  },
  mounted: function () {
    this.isCustomer = userService.isCustomer();
    this.business = userService.getValue("business_name");
    this.address =
      userService.getValue("address") + ", " + userService.getValue("city");

    if (!this.isCustomer) {
      emitter.on("onOrderSuccess", this.onOrderSuccess);
      emitter.on("onOrderFail", this.onOrderFail);
      transporterService.getOrders({}, this, emitter);
    }
  },
  methods: {
    onOrderSuccess: function (data) {
      if (data.data.length <= 0) {
        this.orders = "na";
      } else {
        this.orders = data.data;
      }
      console.log(data);
    },
    onOrderFail: function (error) {
      console.log(error);
    },
    downloadInvoiceReceipt: function (api_id, order_num) {
      //this.order_num = order_num;
      //window.location = process.env.VUE_APP_API_URL+"/order/order-receipt?api_id=" + api_id;
      invoiceService.downloadInvoiceReceipt(api_id, userService.getAuthToken());
    },
  },
  unmounted: function () {},
  setup() {
    return {};
  },
};
</script>

