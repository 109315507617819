import axiosLogged from "../helpers/axiosLogged";
function getStats(emitterObj) {
    axiosLogged.post("/stats/all", "")
        .then(response => {
            if(response.data.data.head.success=="true"){
                emitterObj.emit('onStatsSuccess', response.data.data.body);
            }else{
                emitterObj.emit('onStatsFail', response.data.data.body.error.msg);    
            }
        }).catch(error => {
            emitterObj.emit('onStatsFail', Promise.reject(error));
        });
}
export const statsService = {
    getStats
};