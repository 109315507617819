import axiosLogged from "../helpers/axiosLogged";

function getNotifications(emitterObj,context) 
{
    context.showLoading();
    axiosLogged.post("/notification/list","")
        .then(response => {
            context.hideLoading();
            emitterObj.emit('onNotificationListSuccess', response.data.data.body); 
        }).catch(error => {
            context.hideLoading();
            emitterObj.emit('onNotificationListFail', Promise.reject(error));
    });
}

function deleteNotification(emitterObj,context,payload) 
{
    context.showLoading();
    axiosLogged.post("/notification/delete",payload)
        .then(response => {
            context.hideLoading();
            emitterObj.emit('onNotificationDelSuccess', response.data.data.body); 
        }).catch(error => {
            context.hideLoading();
            emitterObj.emit('onNotificationDelFail', Promise.reject(error));
    });
}
export const notificationService = {
    getNotifications,
    deleteNotification
};