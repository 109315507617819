<template>
  <q-page-container>
    <q-page class="q-pa-xs-md">
    <h6 >Steel Dalal - Privacy Plicy</h6>
  <p>1- Product price given by seller to buyer is his/her individual discretion and SteelDalal does not have any involvement in this front and will not be responsible for any issue.</p>

<p>2- SteelDalal is creating the only platform to connect buyer and seller and SteelDalal does not have any liability and responsibility for any type of transaction.</p>

<p>3- All offer and scheme given by buyer to seller are their individual and SteelDalal does not have any liability and responsibility on this front.</p>

<p>4- Transaction of money is between buyer and seller directly. Money transaction between buyer and seller is wholly responsibility of buyer and seller SteelDalal does not have any role and liability on this front.</p>

<p>5- Your (buyer &amp; seller) information share for registration on SteelDalal platform can be used by company for data analysis, product offer, product promotion and other activity.</p>

<p>6- End customer have access to distributor of any segment in SteelDalal app.</p>

<p>7- In SteelDalal company and seller have rights to send notification to your registered mail ID and phone numbers for promotional and transactional activity.</p>

<p>8- Product quality and delivery are the whole responsibility of seller and SteelDalal does not have any responsibility and liability.</p>

<p>9- Whom to onboard in SteelDalal portal/App is whole discretion of SteelDalal Solution Pvt Ltd.</p>

<p>10- Terms and conditions will change time to time as per situation.</p>

<p>11- SteelDalal have full rights to withdraw its services from buyer or seller at any point of time.</p>

<p>12- If more than 5 complains comes against buyer or seller. SteelDalal have full rights to close the service of that buyer or seller with immediate effect.</p>

<p>13- Monthly subscription is on advance payment on before 3 rd of every month.</p>

<p>14- Any delay of more than 3 days will liable for penalty of 50/- per day.</p>

<p>15- If monthly subscription break is for 2 months so re-joining fee will be applicable as per that time company policy.</p>

<p>16- If seller/buyer got terminated from platform and want to re-join, then he has to give written mail to the company with reason. His re-joining is wholly discretion of company with that time re-joining fee.</p>

<p>17-SteelDalal will support technically to all its customers who have paid for regular monthly subscription.</p>

<p>18- SteelDalal can increase or decrease the monthly subscription at any point of time by giving 5 days prior notification.</p>

<p>19- Any delay or loss of business due to technical error on App/portal is not liable for any legal activity by buyer or seller.</p>

		<b>User Data - Registration</b>
<p>
We required the following information to register with us:
</p>
<ul>
<li> Business Name</li>
<li> Contact Name</li>
<li> Postal Code</li>
<li> Mobile Number</li>
</ul>
<br />
<b> User Account Deletion </b>
<p>
If you wish to want to delete your account with the all information for example order, profile, or any information related to you. please email us at <a href="mailto:info@steeldalal.com">info@steeldalal.com</a>
</p>
      <!--
      <div>
      <b><center>Pricing Policy of www.steeldalal.com</center></b><br />
<b>Chapter I Overview </b><br /><br />
<p>These Rules are formulated in accordance with the steeldalal regulations agreement, so as to safeguard the legitimate rights and interests of the transaction parties, and to maintain the normal operations of order payment on steeldalal.com.</p>
<p>These Rules govern the collection of offline payments for transactions in the manners prescribed by steeldalal.com, including but not limited to the collection by CC Avenue that acts as the Seller's entrusted export and payment collection agency.</p>
<b>Chapter II Definitions</b><br /><br />
<p>Article 1 Steeldalal.com refers to the website www.steeldalal.com and its mobile app.</p>
<p>Article 2 Online Transaction refers to the activity in which the Buyer and the Seller conduct transaction through the online trading system and relevant technical services provided by steeldalal.com and hence to pay platform fees as a service charged by steeldalal.</p>
<p>Article 3 Offline Transaction Order refers to the agreement on payment made for success deal done between the parties, that amount should be paid with mutual understanding within time period.</p>
<p>Article 4 CC Avenue is a provider of integrated payment trade services.</p>
price<br />
<p>Article 5 if the Seller violates these Rules and refuses to cooperate, SteelDalal.com reserves the right to ban the Seller from using any mode of Transaction services of the platform.</p>
<p>Article 6 Steeldalal.com charges annual fees of INR 2999/Yearly basis that has to be renewed every year by the user.</p>
<p>Article 7 Each and every transactions made at steeldalal.com are all safe & secured via payment security systems.</p>
<p>Article 8 Steeldalal charge 0.5 % platform fee on per trade made at the platform by users (providing support of getting live rates, multiple buyer/seller option, insights, logistic support, marketing & sales support)</p>
<p>Article 9 Brand Partnership plans are only be activated by final approval of management, the contract is only made for manufacturers in steel industry.</p>
<p>Article 10 On the Steeldalal.com you are paying for services that you avail at our platform to grow your organization; hence we are not bound for any kind of refund option in any of our platform service.</p>
<b>Chapter III Miscellaneous</b><br /><br />
<p>If the Seller who violates fails to appeal within three working days after receiving the notice of violation from steeldalal.com, or if such an appeal is overruled, the platform will handle the violation in accordance with these Rules.</p>
<p>The platform's handling of violation in accordance with these Rules does not exempt the Seller from its liability for compensation as stipulated by laws and regulations, other rules and protocols of steeldalal.com, the transaction contract or any other agreement between the transaction parties.</p>
<p>Steeldalal.com has the right to revise these Rules from time to time according to any amendments to the company.s laws and regulations, changes in the operation environment, and other factors, and the revised rules will come into effect upon expiration of the statutory publicity period.These Rules constitute a part of the SteelDalal.com Platform Rules.</p>
      </div>
    -->
    </q-page>
  </q-page-container>
</template>
<script>

export default {
  name: "PrivacyPolicy",
  data() {
    return {};
  },
  mounted:function(){
    this.setToolbarTitle("Privacy Policy");
    this.setPageTitle("Privacy Policy");
  },
  setup() {},
};
</script>
