<template>
  <q-page-container class="bg-container">
    <q-page class="q-pa-xs-md q-gutter-md">
      <div class="row">
        <div class="col">
          <q-input v-model="search" outlined="" rounded dense placeholder="Search Products">
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
        </div>
      </div>

      <q-card dense style="padding:0px;" class="c-bg-color text-center text-bold text-white" flat>
        <div class="row">
          <div class="col"><br />Img</div>
          <div class="col"><br />Detail</div>
          <div class="col text-center"><br />Act<br /><br /></div>
        </div>
      </q-card>

      <q-card class="no-shadow">
        <q-card-section class="text-bold" v-for="item in 10" v-bind:key="item">
          <div class="row">
            <div class="col">
              <q-img
                src="https://via.placeholder.com/65x65"
                spinner-color="project-tertiary"
                style="height: 65px; width: 65px"
                class="q-mt-xs"
                fit="contain"
              ></q-img>
              <br /><br />
            </div>
            <div class="col text-left">
              Product Xyz - {{ item }}<br />
              <span class="text-grey">Quantity : {{ item + 3 }}</span>
            </div>
            <div class="col text-right text-red">
              <q-btn rounded color="green" size="9px" label="edit" />
            </div>
            <!--
            <div class="col text-right">
              <q-btn size="10px" color="green" label="Process" />
            </div>
            -->
          </div>
          <q-separator />
        </q-card-section>
      </q-card>
    </q-page>
  </q-page-container>
</template>
<script>
export default {
  name: "ShortInventoryPage",
  data() {
    return {
    };
  },
  created: function () {
    this.setPageTitle("Short Inventory");
    this.setToolbarTitle("Short Inventory");
  },
  beforeMount: function () {
    this.showLoading();
  },
  mounted: function () {
    //this.setToolbarTitle(this.$route.meta.toolbarTitle);
    //this.setPageTitle(this.$route.meta.pageTitle);
    this.hideLoading();
  },
};
</script>
