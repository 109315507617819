<template>
  <q-page-container class="bg-container">
    <q-page class="q-pa-xs-md q-gutter-md"> 
        <p class="text-subtitle1">
            <b>Important : </b>Once your account deleted. You are not able to recover any information your previous order, profile or any data releated to you and between steel dalal.
        </p>
        <div>
            <q-checkbox color="red" v-model="right" class="text-bold text-red" label="I am agree to delete my account permanently." />
        </div>
        <div>
            <q-btn style="background: #FF0080; color: white" label="Delete My Account Permanently" @click="deleteMyAccount" />
        </div>
    </q-page>
  </q-page-container>
</template>
<script>
import { userService } from "../../services/userService";
import mitt from "mitt";

const emitter = mitt();

export default {
  name: "DeleteMyAccountPage",
  data() {
    return {
      isLogged: false,
      right:false,
    };
  },
  methods: {
    deleteMyAccount: function () {
      if(!this.right){
        this.showError('Please click to "I am agree.." checkbox.');
        return;
      }  
      emitter.on("onAccountDeleteSuccess", this.onAccountDeleteSuccess);
      emitter.on("onAccountDeleteFail", this.onAccountDeleteFail);
      userService.deleteMyAccount(this,emitter);
      //orderService.placedOrderList(this, emitter);
    },
    onAccountDeleteSuccess: function (data) {
      //console.log(data);
      this.showInfo("Account Deleted Successfully !");
      this.goToLogout();
    },

    onAccountDeleteFail: function (error) {
      this.showError(error);
    },
  },
  mounted: function(){
    this.setPageTitle("Delete My Account");
    this.setToolbarTitle("Delete My Account");
  },
  unmounted: function () {
    emitter.off("onAccountDeleteSuccess");
    emitter.off("onAccountDeleteFail");
  },
  setup() {},
};
</script>

