import { createApp } from 'vue';
import App from './App.vue';
import Quasar from 'quasar/src/vue-plugin.js';import Loading from 'quasar/src/plugins/Loading.js';import Dialog from 'quasar/src/plugins/Dialog.js';import Notify from 'quasar/src/plugins/Notify.js';;
import quasarUserOptions from './quasar-user-options';
import router from './router';
import '@/assets/css/main.scss';
import '@/assets/css/main-dark.scss';
import NonLoggedLayout from "@/views/layout/NonLoggedLayout.vue";
import LoggedLayout from "@/views/layout/LoggedLayout.vue";
import BlankLayout from "@/views/layout/BlankLayout.vue";
import globalMixin from './mixins/globalMixin.js';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
//import * from 'firebase';
import firebase from "firebase/compat/app";

const app = createApp(App);

app.config.productionTip = true


app.use(Quasar, {
    plugins: {
        Loading,
        Dialog,
        Notify
    },
    config: {
        loading: { /* look at QuasarConfOptions from the API card */ },
        notify: { /* look at QuasarConfOptions from the API card */ },
        brand: {
            primary: '#013F7A',

          }
    }
}, quasarUserOptions);

  

app.use(router);
app.component('non-logged-layout', NonLoggedLayout);
app.component('logged-layout', LoggedLayout);
app.component('blank-layout', BlankLayout);
app.mixin(globalMixin);

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyD_0AlrF3mXhUV8ZrBSIvRDS64epkR-G2s",
    authDomain: "steeldalal-b90a5.firebaseapp.com",
    projectId: "steeldalal-b90a5",
    storageBucket: "steeldalal-b90a5.appspot.com",
    messagingSenderId: "541854238674",
    appId: "1:541854238674:web:9478aa20a4c81c5480aa0b",
    measurementId: "G-XD8JH5740R"
  };
  
  // Initialize Firebase
  //const app = initializeApp(firebaseConfig);
  //const analytics = getAnalytics(app);
  firebase.initializeApp(firebaseConfig);

//app.firebase.initializeApp(firebaseConfig)
app.mount('#app');
