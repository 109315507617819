<template>
    <component :is="layout" :toolbarTitle="toolbarTitle">
      <router-view  @updateToolbarTitle="updateToolbarTitle"></router-view>
    </component>
</template>

<script>
const nonLoggedtLayout = "non-logged-layout";
const loggedLayout = "logged-layout";
//const blankLayout =  'blank-layout';

import NonLoggedLayout from "./views/layout/NonLoggedLayout.vue";
import LoggedLayout from "./views/layout/LoggedLayout.vue";
import BlankLayout from "./views/layout/BlankLayout.vue";
import { userService } from "./services/userService";

export default {
  name: "App",
  data() {
    return {
      toolbarTitle: "",
    };
  },
  created: function(){
      this.toolbarTitle = this.getToolbarTitle();
      //this.isLogged = userService.isLogged();
  },
  components: {
    NonLoggedLayout,
    LoggedLayout,
    BlankLayout,
  },
  methods:{
      updateToolbarTitle: function(data){
          this.toolbarTitle = data;
      },
  },
  computed: {
    
    layout() {
      /*let whichLayout = userService.isLogged() ? loggedLayout : nonLoggedtLayout;
      return (this.$route.meta.layout == "logged-layout" ||
        this.$route.meta.layout == "non-logged-layout"
        ? whichLayout
        : this.$route.meta.layout);*/
        if(userService.isLogged()){
          let whichLayout = userService.isLogged() ? loggedLayout : nonLoggedtLayout;
          return (this.$route.meta.layout == "logged-layout" ||
            this.$route.meta.layout == "non-logged-layout"
            ? whichLayout
            : this.$route.meta.layout);
        }else{
          return this.$route.meta.layout;
        }
    },
    
  },
  mounted: function(){
      
  },
  updated: function(){
    //this.isLogged = userService.isLogged();
  },

};
</script>
