<template>
  <q-page-container class="bg-container">
    <q-page class="q-pa-xs-md q-gutter-md">
      <div class="row" v-if="info">
        <div class="col">
             <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
            <p class="text-h5 text-bold text-center">Data has been saved !</p>
            <div class="text-center text-blue text-bold" @click="goToDashboard()">Click to Back Dashboard</div>
        </div>
      </div>
      <div class="row" v-if="!info">
        <div class="col-12">
          <q-form @submit="onSubmit" v-model="formObj" class="q-gutter-md" enctype="multipart/form-data">
            <q-input
             rounded outlined
              v-model="form.business_name"
              label="Business Name*"
              type="text"
              :rules="[(val) => (val && val.length > 0) || '']"
            />

            <q-input
             rounded outlined
              v-model="form.gst_num"
              label="GST Number*"
              type="text"
              :rules="[(val) => (val && val.length > 0) || '']"
            />

            <q-input
              rounded outlined
              v-model="form.trading_name"
              label="Display Business Name*"
              type="text"
              :rules="[(val) => (val && val.length > 0) || '']"
            />

            <q-input
              rounded outlined
              v-model="form.address"
              label="Address*"
              rows="2"
              type="textarea"
              :rules="[(val) => (val && val.length > 0) || '']"
            />

            <q-input
              rounded outlined
              v-model="form.city"
              label="City*"
              type="text"
              :rules="[(val) => (val && val.length > 0) || '']"
            />

            <q-select
              v-model="iState"
              rounded outlined
              label="State*"
              :options="states"
              option-label="label"
              option-value="label"
            />
            <q-input
              rounded outlined
              v-model="form.postal_code"
              label="Postal Code*"
              type="text"
              :rules="[(val) => (val && val.length > 0) || '']"
            />
            
        
            <br />
            <q-btn
              size="md"
              rounded
              label="Submit"
              type="submit"
              class="c-bg-color text-white"
            />
          </q-form>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-right">&nbsp;</div>
      </div>
    </q-page>
  </q-page-container>
</template>
<script>
import { ref } from "vue";
import { userService } from "../../services/userService";
import { geoService } from "../../services/geoService";
import mitt from "mitt";
import { returnStatement } from "@babel/types";
const emitter = mitt();

export default {
  name: "KYC",
  data() {
    return {
      form: {
        //"api_id": "1039a6fb78e1028285eef41be915e366",
        //"contact_person": "deep dhyani",
        //"email": null,
        business_name: "",
        trading_name: "",
        address: "",
        city: "",
        state: "",
        postal_code:'',
        //"mobile_num": "9711439022",
        //"phone_nos": null,
        //"website_url": null,
        "gst_num": "",
        //"pan_num": null,
        //"aadhar_num": null,
        //"export_import_num": null,
        //"required_finance": "no",
        //"required_transport": "no",
        //kyc_approved: "no",
      },
      iState:null,
      states: [],
      info:false,
      gst:null,
      pan:null,
      cheque:null,
      aadhar:null,
      uploadPath:process.env.VUE_APP_API_URL+'/customer/upload',
      formObj:null,
    };
  },
  created: function () {
    //emitter.on()
  },
  mounted: function () {
    emitter.on("onUserViewSuccess", this.onUserViewSuccess);
    //emitter.on("onUserViewFail",this.onUserViewFail);
    userService.view(this, emitter);

    this.setToolbarTitle("Submit KYC");
    this.setPageTitle("Submit KYC");

    emitter.on("onStateResultSuccess", this.onStateResultSuccess);
    geoService.getStates(this, emitter);
  },
  methods: {
    onSubmit: function () {
      
      this.form.state = (this.iState.label !=undefined )?this.iState.label:this.iState;
     
      let fd = new FormData();
      for (var key in this.form) {
        fd.append(key,this.form[key]);
      }
      emitter.on("onUserUpdateSuccess", this.onUserUpdateSuccess);
      userService.update(fd, this, emitter);
     
    },
    onUserUpdateSuccess: function (data) {
      //console.log(data);
      this.info = true;
      //this.showInfo("You Data has")
      userService.view(this,emitter);
    },
    onStateResultSuccess: function (data) {
      //console.log(data);
      this.states = data;
      //alert("call");
      //console.log(data);
    },
    onUserViewSuccess: function (result) 
    {
      //console.log(result);
      this.iState = result.state;
      //alert(this.iState);
      this.form = result;
    },
    onUserViewFail: function (result) {
      console.log(result);
      this.showError(result);
    },
  },
  setup() {},
  unmounted: function () {
    emitter.off("onUserViewSuccess");
    emitter.off("onUserViewFaile");
  },
};
</script>
