<script>

export default {
  name: "HowItWorks",
  data() {
    return {};
  },
  mounted:function(){
    this.setToolbarTitle("How It Works");
    this.setPageTitle("How It Works Policy");
  },
  setup() {},
};
</script>
<template>
  <q-page-container>
    <q-page class="q-pa-xs-md">
    <!-- youtube vedios --->
                <q-card class="bg-black text-white text-center q-pb-sm">
                  <q-card-section>
                    <q-video                      
                      style="height: 350px"
                      src="https://www.youtube.com/embed/WI5LLBteInI?&autoplay=1"
                      frameborder="0"
                      allow="autoplay;clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen                      
                    />
                  </q-card-section>
                  
                </q-card>
              <!-- /end vedios -->
    </q-page>
  </q-page-container>
</template>

