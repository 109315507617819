import axiosLogged from "../helpers/axiosLogged";
import axios from "axios";

function placedOrderList(ob, emitterObj) {
    ob.showLoading();
    axiosLogged.post("/order/placed-order-list", "")
        .then(response => {
            if (response.data.data.head.success == "true") {
                emitterObj.emit('onPlacedOrderListSuccess', response.data.data.body);
            } else {
                emitterObj.emit('onPlacedOrderListFail', response.data.data.body.error.msg);
            }
            ob.hideLoading();
        }).catch(error => {
            ob.hideLoading();
            emitterObj.emit('onPlacedOrderListFail', Promise.reject(error));
        });
}

function recivedOrderList(ob, emitterObj) {
    ob.showLoading();
    axiosLogged.post("/order/recieved-order-list", "")
        .then(response => {
            if (response.data.data.head.success == "true") {
                emitterObj.emit('onRecivedOrderListSuccess', response.data.data.body);
            } else {
                emitterObj.emit('onRecivedOrderListFail', response.data.data.body.error.msg);
            }
            ob.hideLoading();
        }).catch(error => {
            ob.hideLoading();
            emitterObj.emit('onRecivedOrderListFail', Promise.reject(error));
        });
}

function recievedOrderConfirm(payload, ob, emitterObj) {
    ob.showLoading();
    axiosLogged.post("/order/recieved-order-confirm", payload)
        .then(response => {
            if (response.data.data.head.success == "true") {
                emitterObj.emit('onRecievedOrderConfirmSuccess', response.data.data.body);
            } else {
                emitterObj.emit('onRecievedOrderConfirmFail', response.data.data.body.error.msg);
            }
            ob.hideLoading();
        }).catch(error => {
            ob.hideLoading();
            emitterObj.emit('onRecievedOrderConfirmFail', Promise.reject(error));
        });
}

function recievedOrderDeclined(payload, ob, emitterObj) {
    ob.showLoading();
    axiosLogged.post("/order/recieved-order-declined", payload)
        .then(response => {
            if (response.data.data.head.success == "true") {
                emitterObj.emit('onRecievedOrderDeclinedSuccess', response.data.data.body);
            } else {
                emitterObj.emit('onRecievedOrderDeclinedFail', response.data.data.body.error.msg);
            }
            ob.hideLoading();
        }).catch(error => {
            ob.hideLoading();
            emitterObj.emit('onRecievedOrderDeclinedFail', Promise.reject(error));
        });
}

function getOrder(payload, ob, emitterObj) {
    ob.showLoading();
    axiosLogged.post("/order/get-order", payload)
        .then(response => {
            if (response.data.data.head.success == "true") {
                emitterObj.emit('onGetOrderSuccess', response.data.data.body);
            } else {
                emitterObj.emit('onGetOrderFail', response.data.data.body.error.msg);
            }
            ob.hideLoading();
        }).catch(error => {
            ob.hideLoading();
            emitterObj.emit('onGetOrderFail', Promise.reject(error));
        });
}

/*function orderPaid(payload, ob, emitterObj) {
    ob.showLoading();
    axiosLogged.post("/order/order-paid", payload)
        .then(response => {
            if (response.data.data.head.success == "true") {
                emitterObj.emit('onOrderPaidSuccess', response.data.data.body);
            } else {
                emitterObj.emit('onOrderPaidFail', response.data.data.body.error.msg);
            }
            ob.hideLoading();
        }).catch(error => {
            ob.hideLoading();
            emitterObj.emit('onOrderPaidFail', Promise.reject(error));
        });
}*/

function orderInLoading(payload, ob, emitterObj) {
    ob.showLoading();
    axiosLogged.post("/order/order-in-loading", payload)
        .then(response => {
            if (response.data.data.head.success == "true") {
                emitterObj.emit('onOrderILSuccess', response.data.data.body);
            } else {
                emitterObj.emit('onOrderILFail', response.data.data.body.error.msg);
            }
            ob.hideLoading();
        }).catch(error => {
            ob.hideLoading();
            emitterObj.emit('onOrderILFail', Promise.reject(error));
        });
}

function orderShipped(payload, ob, emitterObj) {
    ob.showLoading();
    axiosLogged.post("/order/order-shipped", payload)
        .then(response => {
            if (response.data.data.head.success == "true") {
                emitterObj.emit('onOrderShippedSuccess', response.data.data.body);
            } else {
                emitterObj.emit('onOrderShippedFail', response.data.data.body.error.msg);
            }
            ob.hideLoading();
        }).catch(error => {
            ob.hideLoading();
            emitterObj.emit('onOrderShippedFail', Promise.reject(error));
        });
}

function orderDelivered(payload, ob, emitterObj) {
    ob.showLoading();
    axiosLogged.post("/order/order-delivered", payload)
        .then(response => {
            if (response.data.data.head.success == "true") {
                emitterObj.emit('onOrderDeliveredSuccess', response.data.data.body);
            } else {
                emitterObj.emit('onOrderDeliveredFail', response.data.data.body.error.msg);
            }
            ob.hideLoading();
        }).catch(error => {
            ob.hideLoading();
            emitterObj.emit('onOrderDeliveredFail', Promise.reject(error));
        });
}

function orderReceipt(api_id,ob,emitterObj) {
    ob.showLoading();
    axiosLogged.get("/order/order-receipt?api_id=" + api_id,{
        responseType: 'blob',
    })
    .then(res => {
            ob.hideLoading();
            emitterObj.emit('onOrderReceiptSuccess', res);
     }).catch(error => {
        ob.hideLoading();
        emitterObj.emit('onOrderReceiptFail', Promise.reject(error));
    });
}

function downloadOrderReceipt(api_id,token){
    window.location = process.env.VUE_APP_API_URL+"/order/order-receipt?api_id=" + api_id + "&token="+token;
}


export const orderService = {
    placedOrderList,
    recivedOrderList,
    recievedOrderConfirm,
    recievedOrderDeclined,
    getOrder,
    //orderPaid,
    orderInLoading,
    orderShipped,
    orderDelivered, 
    orderReceipt,
    downloadOrderReceipt
};