<template>
  <q-page-container>
    <q-page class="q-pa-xs-md">
      <div v-if="seller != null">
        <q-list>
          <q-item>
            <q-item-section class="q-pa-xs text-bold">Order #</q-item-section>
            <q-item-section class="text-left">
              <span class="text-uppercase">{{ data.data.order_num }} </span>
            </q-item-section>
          </q-item>
          <q-separator />
          <q-item>
            <q-item-section class="q-pa-xs text-bold">Customer</q-item-section>
            <q-item-section class="text-left">
              <span class="text-uppercase"
                >{{ data.data.customer.business_name }}
              </span>
            </q-item-section>
          </q-item>
          <q-separator />
          <q-item>
            <q-item-section class="q-pa-xs text-bold"
              >City / State</q-item-section
            >
            <q-item-section class="text-left">
              <span
                >{{ data.data.customer.city }} ,
                {{ data.data.customer.state }}</span
              >
            </q-item-section>
          </q-item>
          <q-separator />
        </q-list>
      </div>
      <br />
      <div class="text-body1 text-center text-bold text-primary q-pa-md">
        Order Items
      </div>
      <div v-if="data != null">
        <div v-for="(item, index) in items" v-bind:key="item">
          <q-card
            class="no-shadow"
            :id="index"
            :class="
              index % 2 == 0
                ? 'bg-green-1  rounded-borders'
                : 'bg-grey-4 rounded-borders'
            "
            no-shadow
          >
            <q-card-section>
              <div class="row">
                <div class="col">
                  <b>{{ item.product }} </b><br />
                  <span class="text-caption">{{ item.brand }}</span
                  ><br />
                  <span
                    class="text-caption text-italic text-grey"
                    v-if="item.size_type == 'standard'"
                  >
                    {{ item.size }} MM</span
                  >
                  <span
                    class="text-regular text-bold"
                    v-if="item.size_type == 'coil'"
                  >
                    <q-badge squar color="grey-5 text-black" label="W : " />
                    {{ item.width + " " + item.width_uom }} <br />
                    <q-badge squar color="grey-5 text-black" label="T : " />
                    {{ item.thickness + " " + item.thickness_uom }}
                  </span>
                  <span
                    class="text-regular text-bold"
                    v-if="item.size_type == 'sheet'"
                  >
                    <q-badge squar color="grey-5 text-black" label="W : " />
                    {{ item.width + " " + item.width_uom }} <br />
                    <q-badge squar color="grey-5 text-black" label="L : " />
                    {{ item.length + " " + item.length_uom }} <br />
                    <q-badge squar color="grey-5 text-black" label="T : " />
                    {{ item.thickness + " " + item.thickness_uom }}
                  </span>
                  <br />
                  <q-chip
                    size="12px"
                    class="text-white"
                    color="green"
                    :label="'Order Qty: ' + item.qty + ' Ton'"
                  />
                </div>
                <div class="col text-right">
                  <!--
                  <q-badge
                    squar
                    color="white text-blue text-subtitle1 text-bold text-right"
                    :label="item.total"
                  />
                  -->
                  <!--
                  <q-chip
                    squar
                    icon="currency_rupee"
                    square=""
                    color="white text-blue text-bold"
                    :label="item.total"
                  />
                  -->
                  <q-input
                    v-model="form.rates[index].qty"
                    type="number"
                    style="width: 80%"
                    setp="any"
                    outlined
                    :label="'Qty (ton)'"
                    stack-label
                    class="bg-white text-right"
                    inputClass="text-right text-bold"
                  />
                  <input type="hidden" v-model="form.rates[index].api_id" />

                  <!--
                  <br />
                  <q-chip
                    size="10px"
                    class="text-white"
                    color="grey-7"
                    :label="
                      'Qty: ' + item.qty + ' ' + item.qty_uom
                    "
                  />
                  -->
                </div>
              </div>
            </q-card-section>
          </q-card>
          <br />
        </div>
        <div class="text-center">
          <q-form
            @submit.prevent.stop="onGenerateInvoice"
            class="q-gutter-md"
            ref="invForm"
          >
            <q-input
              v-model="form.eway_bill_no"
              outlined
              label="Eway Bill No"
              stack-label
              class="bg-white text-right"
              inputClass="text-right text-bold"
              :rules="[(val) => !!val || 'Required']"
            />
            <q-input
              v-model="form.delivery_charges"
              outlined
              label="Delivery Charges"
              stack-label
              class="bg-white text-right"
              inputClass="text-right text-bold"
              :rules="[(val) => !!val || 'Required']"
            />
            <q-select
              outlined=""
              inputClass="text-right text-bold"
              v-model="form.transporter_id"
              class="bg-white text-right"
              :options="transporters"
              label="Choose Transporter"
              emit-value
              :rules="[(val) => !!val || 'Required']"
            />
            <q-select
              outlined=""
              inputClass="text-right text-bold"
              v-model="form.dseller_id"
              class="bg-white text-right"
              :options="sellers"
              label="Choose Seller"
              emit-value
              :rules="[(val) => !!val || 'Required']"
            />
            <div>
              <q-btn
                icon="done"
                size="md"
                rounded
                color="primary"
                label="Generate Invoice"
                type="submit"
              />
            </div>
          </q-form>
        </div>
      </div>
    </q-page>
  </q-page-container>
</template>
<script>
import { userService } from "../../services/userService";
import mitt from "mitt";
import { ref } from "vue";
import { orderService } from "../../services/orderService";
import { invoiceService } from "../../services/invoiceService";

const emitter = mitt();

export default {
  name: "OrderRecievedViewPage",
  data() {
    return {
      isLogged: null,
      data: null,
      items: null,
      seller: null,
      form: {
        api_id: "",
        eway_bill_no: "",
        delivery_charges: 0,
        rates: [],
        dseller_id:'',
        transporter_id:''
      },
      sellers: [],
      transporters: [],
    };
  },
  methods: {
    onGetOrderSuccess: function (data) {
      //console.log(data);
      if (data != "undefined") {
        this.data = data;
        this.items = data.data.order_details;
        this.form.api_id = data.data.api_id;
        for (let i = 0; i < this.items.length; i++) {
          ////this.form.rates[i].api_id = this.items[i].api_id;
          //this.form.rates[i].qty = this.items[i].qty;
          let item = {
            api_id: this.items[i].api_id,
            qty: this.items[i].qty,
          };
          this.form.rates.push(item);
        }
        this.seller = data.data.seller;
        this.form.delivery_charges = data.data.delivery_charges;
        this.transporters = this.data.transporters;
        this.sellers = this.data.sellers;
        //console.log(this.seller);
      }
    },
    onGetOrderFail: function (error) {
      this.showError(error);
    },
    onGenerateInvoice: function (evt) {
      let vm = this;
      this.$refs.invForm
        .validate()
        .then((success) => {
          //vm.$q.notify({ color: 'positive', message: 'You have been successfully logged in.' });
          emitter.on("onInvoiceGenerateSuccess", this.onInvoiceGenerateSuccess);
          emitter.on("onInvoiceGenerateFail", this.onInvoiceGenerateFail);
          invoiceService.generateInvoice(this, this.form, emitter);
        })
        .catch((err) => {
          vm.$q.notify({
            color: "red",
            message: "Please enter required field",
          });
        });
    },
    onInvoiceGenerateSuccess: function (data) {
      //alert("done.");
      this.showInfo("Invoice Generated.");
      this.goToOrderRecieved();
    },
    onInvoiceGenerateFail: function (error) {
      this.showError("Unable to generate invoice.");
    },
  },
  created: function () {
    this.setPageTitle("Generate Invoice");
    this.setToolbarTitle("Generate Invoice");
  },
  mounted: function () {
    emitter.on("onGetOrderSuccess", this.onGetOrderSuccess);
    emitter.on("onGetOrderFail", this.onGetOrderFail);
    orderService.getOrder({ api_id: this.$route.query._id }, this, emitter);
    this.isLogged = userService.isLogged();
  },
  unmounted: function () {
    emitter.off("onGetOrderSuccess");
    emitter.off("onGetOrderFail");
    emitter.off("onInvoiceGenerateSuccess");
    emitter.off("onInvoiceGenerateFail");
  },
  setup() {},
};
</script>
