<script>
//import { ref } from "vue";
//import { useQuasar } from 'quasar'
import { ref } from "vue";
import validationMixin from "../mixins/validationMixin";
import { emailService } from "../services/emailService";
import { userService } from "../services/userService";
//import { dashboardService } from "../services/dashboardService";//new
import mitt from "mitt";
const emitter = mitt();
//const emitterCity = mitt();
export default {
  name: "BecomeSellerPage",
  mixins: [validationMixin],
  data() {
    return {
      data: null,
      
      cities: [],
      form: {
        business_name: "",
        contact_person: "",
        email: "",
        mobile: "",
        city:"",
        gst_num: "",
        message: "",
        
        
      },
      submitted: false,
    };
  },
  created: function () {},
  mounted: function () {
    this.setToolbarTitle("Become a seller");
    this.setPageTitle("Become a seller ?");
    emitter.on("onUserViewSuccess", this.onUserViewSuccess);
    //console.log(userService.isLogged());
    if(userService.isLogged()){
    userService.view(this, emitter);
    }
    //emitterCity.on("onCitySuccess", this.onCitySuccess);
    //emitterCity.on("onCityFail", this.onCityFail);
    //dashboardService.getCities(emitterCity);
  },
  unmounted: function () {
    emitter.off("onUserViewSuccess");
    emitter.off("onBecomeSellerEmailSuccess");
    //emitterCity.off("onCitySuccess");
    //emitterCity.off("onCityFail");
  },
  methods: {
    
    onSubmit: function () {
      //console.log(this);
      //alert("In Progres..");
      //return false;
        emitter.on("onBecomeSellerEmailSuccess",this.onBecomeSellerEmailSuccess)
        emailService.becomeSellerEmail(this,this.form,emitter);
    },
    onUserViewSuccess: function (data) {
      this.data = data;
      this.form = data;
      //this.form.email = "";
      
    },
    onBecomeSellerEmailSuccess: function(data){
        this.submitted = true;
    },
   
  },
};
</script>
<template>
  <q-page-container class="bg-container">
     <q-page class="flex flex-center" v-if="submitted">
      <span class="text-bold text-subtitle1 text-center text-green">
        <q-icon name="check" size="80px" /> <br />

        Message has been sent successfully !
        <br /><br />
        <q-btn
          color="primary"
          size="sm"
          label="Click to go Dashboard"
          @click="goToHome()"
        />
      </span>
    </q-page>

    <q-page class="q-pa-xs-md q-gutter-md" v-if="!submitted">
       <div>
       <b>Email Address : info@steeldalal.com</b> <br />
       <b>Mobile # : 9839651123</b> <br />

       <br /><br />
       <p class="text-bold">Address</p>
       <p>
        Steel Dalal Private Limited , <br />
        Office no 3, 2nd Floor, Sanghvi Jewels, <br />Underai, Off S V Road , Malad (W),<br />
        Mumbai - 400064<br />
        Maharashtra ,India<br />
      </p>
      <br />
      </div>
      <div class="row">
        <div class="col-12">
             <q-form
            @submit="onSubmit"
            class="q-gutter-xxs"
            enctype="multipart/form-data"
          >
            <q-input
              outlined=""
              dense
              input-class="text-black text-bold"
              label="Business Name*"
              type="text"
              v-model="form.business_name"
              :rules="[(val) => (val && val.length > 0) || '']"
            />

            <q-input
              outlined=""
               dense
              input-class="text-black text-bold"
             
              label="Contact Person Name *"
              v-model="form.contact_person"
              type="text"
              :rules="[(val) => (val && val.length > 0) || '']"
            />

            <q-input
              outlined=""
               dense
              input-class="text-black text-bold "
             
              label="Email"
              v-model="form.email"
              type="text"
              lazy-rules
            /><br>

            <q-input
              outlined=""
               dense
              input-class="text-black text-bold"
             
              label="Mobile #*"
              v-model="form.mobile"
              type="text"
              lazy-rules
              :rules="[(val) => (val && val.length > 0) || 'Enter Mobile']"
            />

            <q-input
              outlined=""
               dense
              input-class="text-black text-bold"
             
              label="City"
              v-model="form.city"
              type="text"
              
            /><br>

            
           <!--<div class="col-12  q-mt-sm" id="">
            <q-select
            dense
            outlined
            v-model="form.city"
            :options="cities"
            label="Select City"
            dropdown-icon="expand_more"
            transition-show="flip-up"
            transition-hide="flip-down"
            color="text-bold text-primary"
            @update:model-value="onChangeCity()"
            
            style="width:100%"
            input-class="text-bold text-subtitle"
            
          >            
          </q-select>
           </div>
          <template v-slot:option="scope">
            <q-item v-bind="scope.itemProps">
              <q-item-section>
                <q-item-label>{{ scope.opt.label }}</q-item-label>
              </q-item-section>
            </q-item>
          </template><br>-->

          <q-input
              outlined=""
               dense
              input-class="text-black text-bold"
             
              label="GST No"
              v-model="form.gst_num"
              type="text"
              
            /><br>

            <q-input
              outlined=""
               dense
              input-class="text-black text-bold"
             
              label="Message*"
              type="textarea"
              v-model="form.message"
              lazy-rules
              :rules="[(val) => (val && val.length > 0) || 'Enter Message ']"
            />
            <q-btn
              size="md"
              label="Submit"
              type="submit"
              color="blue"
            />
          </q-form>
          
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-right">&nbsp;</div>
      </div>
      <!--
      <div class="row">
        <div class="col-12 text-right">
          <b>
            <a href="#" class="text-grey-6" @click="goToLogin()">Back to Login ?</a>
          </b>
        </div>
      </div>
      -->
    </q-page>
  </q-page-container>
</template>

