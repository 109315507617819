import axiosLogged from "../helpers/axiosLogged";


function getStates(parentRef,emitterObj)
{
    let payload = {};
    axiosLogged.get("/master/state-list", payload)
        .then(response => {
            let data = JSON.parse(JSON.stringify(response.data.data));
            if (data.head.success == "true") 
            {
                emitterObj.emit('onStateResultSuccess', data.body);
            }else {
                emitterObj.emit('onStateResultFail', data.body.error.msg);
            }
        }).catch(error => {
            parentRef.showError("Unable to Connect with the Server, Please try again !!")
            return Promise.reject(error);

    });
}
export const geoService = {
    getStates
};