<script>

export default {
  name: "FAQ",
  data() {
    return {};
  },
  mounted:function(){
    this.setToolbarTitle("FAQ's");
    this.setPageTitle("FAQ's");
  },
  setup() {},
};
</script>

<template>
<q-page-container>
    <q-page class="q-pa-xs-md">
      <div class="q-pa-xs" style="max-width: 100%">
        <q-list bordered class="rounded-borders q-mb-sm">
          <q-expansion-item
            switch-toggle-side
            expand-separator
            header-class="text-primary"
            label="Lorem ipsum dolor sit amet ?"
            style="font-weight: bold;"
          >
            <q-card>
              <q-card-section style="font-weight:normal;">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quidem, eius reprehenderit eos corrupti
                commodi magni quaerat ex numquam, dolorum officiis modi facere maiores architecto suscipit iste
                eveniet doloribus ullam aliquid.
              </q-card-section>
            </q-card>
          </q-expansion-item>
          </q-list>

          <q-list bordered class="rounded-borders q-mb-sm">
          <q-expansion-item
            switch-toggle-side
            expand-separator
            header-class="text-primary"
            label="Lorem ipsum dolor sit amet ?"
            style="font-weight: bold;"
          >
            <q-card>
              <q-card-section style="font-weight:normal;">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quidem, eius reprehenderit eos corrupti
                commodi magni quaerat ex numquam, dolorum officiis modi facere maiores architecto suscipit iste
                eveniet doloribus ullam aliquid.
              </q-card-section>
            </q-card>
          </q-expansion-item>
          </q-list>

          <q-list bordered class="rounded-borders q-mb-sm">
          <q-expansion-item
            switch-toggle-side
            expand-separator
            header-class="text-primary"
            label="Lorem ipsum dolor sit amet ?"
            style="font-weight: bold;"
          >
            <q-card>
              <q-card-section style="font-weight:normal;">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quidem, eius reprehenderit eos corrupti
                commodi magni quaerat ex numquam, dolorum officiis modi facere maiores architecto suscipit iste
                eveniet doloribus ullam aliquid.
              </q-card-section>
            </q-card>
          </q-expansion-item>
          </q-list>

          <q-list bordered class="rounded-borders q-mb-sm">
          <q-expansion-item
            switch-toggle-side
            expand-separator
            header-class="text-primary"
            label="Lorem ipsum dolor sit amet ?"
            style="font-weight: bold;"
          >
            <q-card>
              <q-card-section style="font-weight:normal;">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quidem, eius reprehenderit eos corrupti
                commodi magni quaerat ex numquam, dolorum officiis modi facere maiores architecto suscipit iste
                eveniet doloribus ullam aliquid.
              </q-card-section>
            </q-card>
          </q-expansion-item>
          </q-list>

          <!-- Ref: https://quasar.dev/vue-components/expansion-item/
            <q-expansion-item
            switch-toggle-side
            expand-separator
            icon="signal_wifi_off"
            label="Wifi settings"
          >
            <q-card>
              <q-card-section>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quidem, eius reprehenderit eos corrupti
                commodi magni quaerat ex numquam, dolorum officiis modi facere maiores architecto suscipit iste
                eveniet doloribus ullam aliquid.
              </q-card-section>
            </q-card>
          </q-expansion-item>-->
        

        
      </div>
    </q-page>
</q-page-container>
</template>