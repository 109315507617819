<template>
  <q-page-container>
    <q-page class="q-pa-xs-md">
      <div v-if="seller != null">
        <div class="row">
          <div class="col">
              <q-btn round flat>
                <q-avatar size="100px">
                  <img src="../../assets/img/splash.jpg" style="width:auto" />
                </q-avatar>
              </q-btn>
              
          </div>
          <div class="col text-right q-pa-md">
            <!--order reciept-->
                  <br />
                  <q-btn
                    icon="file_download"
                    size="9px"
                    color="green"
                    label="Download"
                    @click="downloadInvoiceReceipt(data.data.api_id, data.data.invoice_num)"
                  />
          </div>
        </div>
        <q-separator />
        <q-list>
          <q-item>
            <q-item-section class="q-pa-xs text-bold">Invoice #</q-item-section>
                <q-item-section class="text-left">
                <span class="text-uppercase">{{ data.data.invoice_num }} </span>
                </q-item-section>
          </q-item>
            <q-separator />
          <q-item>
            <q-item-section class="q-pa-xs text-bold">Invoice Dt</q-item-section>
                <q-item-section class="text-left">
                <!--<span class="text-uppercase">{{ data.data.created_at }} </span>-->
                <span class="text-uppercase">{{ formatDate(data.data.created_at) }} {{ formatTime(data.data.created_at)}}</span>
                </q-item-section>
          </q-item>
          <q-separator />
          <q-item>
            <q-item-section class="q-pa-xs text-bold">Last Updated</q-item-section>
                <q-item-section class="text-left">
                <!--<span class="text-uppercase">{{ data.data.created_at }} </span>-->
                <span class="text-uppercase">{{ formatDate(data.data.updated_at) }} {{ formatTime(data.data.updated_at)}}</span>
                </q-item-section>
          </q-item>
         <q-separator /> 
          <q-item>
            <q-item-section class="q-pa-xs text-bold">Order #</q-item-section>
            <q-item-section class="text-left">
              <span class="text-uppercase">{{ data.data.order.order_num }} </span>
            </q-item-section>
          </q-item>
            <q-separator />
          <q-item>
            <q-item-section class="q-pa-xs text-bold">Order Dt</q-item-section>
            <q-item-section class="text-left">
              <!--<span class="text-uppercase">{{ data.data.order.created_at }} </span>-->
              <span class="text-uppercase">{{ formatDate(data.data.order.created_at) }} {{ formatTime(data.data.order.created_at)}}</span>
            </q-item-section>
          </q-item>
          <q-separator />
          <q-item>
            <q-item-section class="q-pa-xs text-bold">Last Updated</q-item-section>
            <q-item-section class="text-left">
              <!--<span class="text-uppercase">{{ data.data.order.created_at }} </span>-->
              <span class="text-uppercase">{{ formatDate(data.data.order.updated_at) }} {{ formatTime(data.data.order.updated_at)}}</span>
            </q-item-section>
          </q-item>
            <q-separator />
          <q-item>
            <q-item-section class="q-pa-xs text-bold">Eway Bill #</q-item-section>
            <q-item-section class="text-left">
              <span class="text-uppercase">{{ data.data.eway_bill_no }} </span>
            </q-item-section>
          </q-item>
          <q-separator />
          <q-separator />
        </q-list>

        <!-- SELLER -->
        <q-card class="no-shadow bg-blue-2  rounded-borders q-pa-sm text-bold text-center" no-shadow>
            <q-item-section> SELLER </q-item-section>
        </q-card>
        <q-list>
          <q-item>
            <q-item-section class="q-pa-xs text-bold">Seller</q-item-section>
            <q-item-section class="text-left">
              <span class="text-uppercase">{{ seller.business_name }} </span>
            </q-item-section>
          </q-item>
          <q-separator />
          <q-item>
            <q-item-section class="q-pa-xs text-bold">Address</q-item-section>
            <q-item-section class="text-left">
              <span class="text-uppercase">{{ seller.address }} </span>
            </q-item-section>
          </q-item>
          <q-separator />
          <q-item>
            <q-item-section class="q-pa-xs text-bold"
              >City / State</q-item-section
            >
            <q-item-section class="text-left">
              <span>{{ seller.city }} , {{ seller.state }}</span>
            </q-item-section>
          </q-item>
            <q-separator />
          <q-item>
            <q-item-section class="q-pa-xs text-bold">GST #</q-item-section>
            <q-item-section class="text-left">
              <span class="text-uppercase">{{ seller.gst_num }} </span>
            </q-item-section>
          </q-item>
            <q-separator />
          <q-item>
            <q-item-section class="q-pa-xs text-bold">Pan #</q-item-section>
            <q-item-section class="text-left">
              <span class="text-uppercase">{{ seller.pan_num }} </span>
            </q-item-section>
          </q-item>
        </q-list>

        <!-- BYER-->
        <br />    
        <q-card class="no-shadow bg-blue-2  rounded-borders q-pa-sm text-bold text-center" no-shadow>
            <q-item-section> BUYER BILLING ADDRESS</q-item-section>
        </q-card>
        <q-list>
          <q-item>
            <q-item-section class="q-pa-xs text-bold">Buyer</q-item-section>
            <q-item-section class="text-left">
              <span class="text-uppercase">{{ customer.business_name }} </span>
            </q-item-section>
          </q-item>
          <q-separator />
          <q-item>
            <q-item-section class="q-pa-xs text-bold">Address</q-item-section>
            <q-item-section class="text-left">
              <span class="text-uppercase">{{ customer.address }} </span>
            </q-item-section>
          </q-item>
          <q-separator />
          <q-item>
            <q-item-section class="q-pa-xs text-bold"
              >City / State</q-item-section
            >
            <q-item-section class="text-left">
              <span>{{ customer.city }} , {{ customer.state }}</span>
            </q-item-section>
          </q-item>
          <q-separator />
          <q-item>
            <q-item-section class="q-pa-xs text-bold">GST #</q-item-section>
            <q-item-section class="text-left">
              <span class="text-uppercase">{{ customer.gst_num }} </span>
            </q-item-section>
          </q-item>
            <q-separator />
          <q-item>
            <q-item-section class="q-pa-xs text-bold">Pan #</q-item-section>
            <q-item-section class="text-left">
              <span class="text-uppercase">{{ customer.pan_num }} </span>
            </q-item-section>
          </q-item>
        </q-list>

        <!-- BYER Shipping-->
        <br />    
        <q-card class="no-shadow bg-blue-2  rounded-borders q-pa-sm text-bold text-center" no-shadow>
            <q-item-section> BUYER SHIPPING ADDRESS</q-item-section>
        </q-card>
        <q-list>
          <q-item>
            <q-item-section class="q-pa-xs text-bold">Address</q-item-section>
            <q-item-section class="text-left">
              <span class="text-uppercase">{{ data.data.s_address }} </span>
            </q-item-section>
          </q-item>
          <q-separator />
          <q-item>
            <q-item-section class="q-pa-xs text-bold"
              >City / State</q-item-section
            >
            <q-item-section class="text-left">
              <span>{{ data.data.s_city }} , {{ data.data.s_state }}</span>
            </q-item-section>
          </q-item>
          <q-separator />
          <q-item>
            <q-item-section class="q-pa-xs text-bold"
              >Postal Code</q-item-section
            >
            <q-item-section class="text-left">
              <span>{{ data.data.s_postal_code }}</span>
            </q-item-section>
          </q-item>
          <q-separator />
        </q-list>
        
      </div>
      <br />
      <q-card class="no-shadow bg-blue-2  rounded-borders q-pa-sm text-bold text-center" no-shadow>
            <q-item-section> INVOICE ITEM(S)</q-item-section>
      </q-card>
      <br />
      <div v-if="data != null">
        <div v-for="(item, index) in items" v-bind:key="item">
        <!--
       
        <q-card-section>
            
        </q-card-section>
        </q-card>
         -->
           <q-card class="my-card no-shadow" :id="index"
            :class="
              index % 2 == 0
                ? 'bg-green-1  rounded-borders'
                : 'bg-grey-4 rounded-borders'
            "
            no-shadow
           >
            <q-card-section>
              <div class="row">
                <div class="col">
                  <b>{{ item.product }} </b> - 
                  <span class="text-caption">{{ item.brand }}</span
                  ><br />
                  <span
                    class="text-caption text-italic text-grey"
                    v-if="item.size_type == 'standard'"
                  >
                    {{ item.size }} MM</span
                  >
                  <span
                    class="text-regular text-bold"
                    v-if="item.size_type == 'coil'"
                  >
                    <q-badge squar color="grey-5 text-black" label="W : " />
                    {{ item.width + " " + item.width_uom }} <br />
                    <q-badge squar color="grey-5 text-black" label="T : " />
                    {{ item.thickness + " " + item.thickness_uom }}
                  </span>
                  <span
                    class="text-regular text-bold"
                    v-if="item.size_type == 'sheet'"
                  >
                    <q-badge squar color="grey-5 text-black" label="W : " />
                    {{ item.width + " " + item.width_uom }} <br />
                    <q-badge squar color="grey-5 text-black" label="L : " />
                    {{ item.length + " " + item.length_uom }} <br />
                    <q-badge squar color="grey-5 text-black" label="T : " />
                    {{ item.thickness + " " + item.thickness_uom }}
                  </span>
                  <span class="text-caption">
                   <br /> Grade:  {{ item.grade }}
                  </span>
                    <span  class="text-caption"  v-if="item.color_name">
                        <br /> Color: {{ item.color_name }}
                    </span>
                    <span  class="text-caption"  v-if="item.cw_5_10 > 0 || item.cw_10_22 > 0 || item.cw_22_27 > 0">
                        <br /> Weight: 
                        <span v-if="item.cw_5_10 > 0 ">5-10 ,</span>
                        <span v-if="item.cw_10_22 > 0 ">10-22 ,</span>
                        <span v-if="item.cw_22_27 > 0 ">22-27</span>
                    </span>
                </div>
              </div>
              <div class="row">
                <div class="col text-right">
                  <q-separator />

                  <q-chip
                    square=""
                    size="10px"
                    class="text-white"
                    color="grey-7"
                    :label="'Qty: ' + item.qty + ' ton'"
                  />
                  X

                  <q-chip
                    size="10px"
                    square=""
                    class="text-white"
                    color="grey-7"
                    :label="'Price: ' + item.price"
                  />
                  =
                  <q-chip
                    squar
                    icon="currency_rupee"
                    square=""
                    color="white text-blue text-bold"
                    :label="item.total"
                  />
                </div>
              </div>
            </q-card-section>
          </q-card>
          <q-separator />
          <br />
        </div>
        <div class="q-pr-md">
              <div class="row">
                  <div class="col text-right text-caption text-bold text-uppercase" style="width:auto">
                      Sub Total
                  </div>
                  <div class="col text-right text-bold  text-subtitle1 text-pink">
                     <q-icon name="currency_rupee" /> {{data.data.sub_total}}
                  </div>
              </div>
              <q-separator />
        </div>
        <div class="q-pr-md" v-if="data.data.igst_amount > 0">
              <div class="row">
                  <div class="col text-right text-caption text-bold text-uppercase" style="width:auto">
                      IGST @ {{data.data.igst_percent}}  % 
                  </div>
                  <div class="col text-right text-bold  text-subtitle1 text-pink">
                     <q-icon name="currency_rupee" /> {{data.data.igst_amount}}
                  </div>
              </div>
              <q-separator />
        </div>
        <div class="q-pr-md" v-if="data.data.cgst_amount > 0">
              <div class="row">
                  <div class="col text-right text-caption text-bold text-uppercase" style="width:auto">
                      CGST @ {{data.data.cgst_percent}}  % 
                  </div>
                  <div class="col text-right text-bold  text-subtitle1 text-pink">
                     <q-icon name="currency_rupee" /> {{data.data.cgst_amount}}
                  </div>
              </div>
              <q-separator />
        </div>
        <div class="q-pr-md" v-if="data.data.sgst_amount > 0">
              <div class="row">
                  <div class="col text-right text-caption text-bold text-uppercase" style="width:auto">
                      CGST @ {{data.data.cgst_percent}}  % 
                  </div>
                  <div class="col text-right text-bold  text-subtitle1 text-pink">
                     <q-icon name="currency_rupee" /> {{data.data.cgst_amount}}
                  </div>
              </div>
              <q-separator />
        </div>
        <!--
        <div class="q-pr-md" v-if="data.data.convenience_fee > 0">
              <div class="row">
                  <div class="col text-right text-caption text-bold text-uppercase" style="width:auto">
                      Convenience Fee 
                  </div>
                  <div class="col text-right text-bold  text-subtitle1 text-pink">
                     <q-icon name="currency_rupee" /> {{data.data.convenience_fee}}
                  </div>
              </div>
              <q-separator />
        </div>
        -->
        <div class="q-pr-md" v-if="data.data.delivery_charges > 0">
              <div class="row">
                  <div class="col text-right text-caption text-bold text-uppercase" style="width:auto">
                      Delivery Charges 
                  </div>
                  <div class="col text-right text-bold  text-subtitle1 text-pink">
                     <q-icon name="currency_rupee" /> {{data.data.delivery_charges}}
                  </div>
              </div>
              <q-separator />
        </div>
        <div class="q-pr-md">
              <div class="row">
                  <div class="col text-right text-caption text-bold text-uppercase" style="width:auto">
                      Total Amount 
                  </div>
                  <div class="col text-right text-bold  text-subtitle1 text-pink">
                     <q-icon name="currency_rupee" /> {{data.data.total_amount}}
                  </div>
              </div>
              <q-separator />
        </div>
        <br /> 
        <p class="text-center text-grey-5">
            Thank you for your business. It’s a pleasure to work with you 
        </p>
        <br />
      </div>
    </q-page>
  </q-page-container>
</template>
<script>
import { userService } from "../../services/userService";
import mitt from "mitt";
import { invoiceService } from "../../services/invoiceService";

const emitter = mitt();

export default {
  name: "InvoiceViewPage",
  data() {
    return {
      isLogged: null,
      data: null,
      items: null,
      seller:null,
      customer:null,
    };
  },
  methods: {
    onGetInvoiceSuccess: function (data) {
      //console.log(data);
      if (data != "undefined") {
        this.data = data;
        this.items = data.data.invoice_details;
        this.seller = data.data.seller;
        this.customer = data.data.customer;
        //console.log(this.seller);

        this.setPageTitle("Invoice # " + this.data.data.invoice_num);
        this.setToolbarTitle("Invoice # "+ this.data.data.invoice_num );
      }
    },
    onGetOrderFail: function (error) {
      this.showError(error);
    },
    downloadInvoiceReceipt: function (api_id, order_num) {
      this.order_num = order_num;
      //window.location = process.env.VUE_APP_API_URL+"/order/order-receipt?api_id=" + api_id;
      invoiceService.downloadInvoiceReceipt(api_id,userService.getAuthToken())
    },
  },
  created: function () {
    this.setPageTitle("Invoice View");
    this.setToolbarTitle("Invoice View");
  },
  mounted: function () {
    emitter.on("onGetInvoiceSuccess", this.onGetInvoiceSuccess);
    emitter.on("onGetInvoiceFail", this.onGetInvoiceFail);
    invoiceService.getInvoice({ api_id: this.$route.query._id }, this, emitter);
    this.isLogged = userService.isLogged();
  },
  unmounted: function () {
    emitter.off("onGetInvoiceSuccess");
    emitter.off("onGetInvoiceFail");
  },
  setup() {},
};
</script>
