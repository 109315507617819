import { createWebHistory, createRouter } from "vue-router";
import SplashScreen from "@/views/SplashScreen.vue";
import HomePage from "@/views/Home.vue";
import NotFound from "@/views/NotFound.vue";
import RegisterPage from "@/views/Register.vue";
import DashboardPage from '@/views/account/Dashboard.vue';
import LogoutPage from "@/views/Logout.vue";
import SearchPage from "@/views/Search.vue";
import ProductDetailPage from "@/views/ProductDetail.vue";
import CartPage from "@/views/Cart.vue";
import CheckoutPage from "@/views/Checkout.vue";
import RequiredDemoPage from "@/views/RequiredDemo.vue";
import KYCPage from "@/views/account/KYC.vue";
import MyBizPage from "@/views/account/MyBiz.vue";
import OrderRecievedPage from "@/views/account/OrderRecieved.vue";
import OrderRecievedViewPage from "@/views/account/OrderRecievedView.vue";
import OrderPlacedViewPage from "@/views/account/OrderPlacedView.vue";
import OrderPlacedPage from "@/views/account/OrderPlaced.vue";
import OrderPlacedMessagePage from "@/views/account/OrderPlacedMessage.vue";

import FinanceSupportPage from "@/views/account/FinanceSupport.vue";
import TransportSupportPage from "@/views/account/TransportSupport.vue";
import ContactUsPage from "@/views/account/ContactUs.vue";
import ShortInventoryPage from "@/views/account/ShortInventory.vue";
import ShipmentPage from "@/views/account/Shipment.vue";
import ProductPage from "@/views/account/product/Product.vue";
import ProductAddPage from "@/views/account/product/ProductAdd.vue";
import ProductAddRateTMTPage from "@/views/account/product/ProductAddRateTMT.vue";
import ProductAddRateSheetPage from "@/views/account/product/ProductAddRateSheet.vue";
import ProductAddRateCoilPage from "@/views/account/product/ProductAddRateCoil.vue";

import WishListPage from "@/views/account/WishList.vue";
import AccountPage from "@/views/account/Account.vue";
import DeleteMyAccountPage from "@/views/account/DeleteMyAccount.vue";

import SellerPage from "@/views/buy/Seller.vue";
import SellerProductRatePage from "@/views/buy/SellerProductRate.vue";
import SellerProductPage  from "@/views/buy/SellerProduct.vue";
import AllProductPage  from "@/views/buy/AllProduct.vue";
import CalculatePricePage  from "@/views/buy/CalculatePrice.vue";

import PaymentPage from "@/views/account/Payment.vue";
import PaymentMessagePage from "@/views/account/PaymentMessage.vue";



import NotificationPage from "@/views/Notification.vue";

import TestPage  from "@/views/Test.vue"

import GenerateInvoicePage from "@/views/account/GenerateInvoice.vue";
import InvoiceViewPage from "@/views/account/InvoiceView.vue";

import PrivacyPolicyPage from "@/views/cms/PrivacyPolicy.vue";
import TocPage from "@/views/cms/Toc.vue";
import AboutUsPage from "@/views/cms/AboutUs.vue"; 
import RefundPolicyPage from "@/views/cms/RefundPolicy.vue"; 

import auth from "../middleware/auth";
import middlewarePipeline from './middlewarePipeline';
import DemoPage from "@/views/Demo.vue";
import HowItWorksPage from "@/views/HowItWorks.vue"; 
import BecomeSellerPage from "@/views/BecomeSeller.vue";
import FAQPage from "@/views/FAQ.vue";

const routes = [
  {
    path: "/itest",
    name: "TestPage",
    component: TestPage,
    meta: {
      layout: 'non-logged-layout',
    },
  },{
    path: "/demo",
    name: "DemoPage",
    component: DemoPage,
    meta: {
      layout: 'non-logged-layout',
    },
  },
  {
    path: "/",
    name: "SplashScreen",
    component: SplashScreen,
    meta: {
      layout: 'blank-layout',
    },
  },
  {
    path: "/home",
    name: "Home",
    meta: {
     
      layout: 'non-logged-layout',
    },
    component: HomePage,
  },
  {
    path: "/register",
    name: "Register",
    meta: {
      layout: 'non-logged-layout',
    },
    component: RegisterPage,
  },
  {
    path: "/search",
    name: "Search",
    meta: {
      layout: 'non-logged-layout',
    },
    component: SearchPage,
  },
  {
    path: "/product-detail",
    name: "ProductDetail",
    meta: {
      layout: 'non-logged-layout',
    },
    component: ProductDetailPage,
  },
  {
    path: "/cart",
    name: "Cart",
    meta: {
      layout: 'non-logged-layout',
    },
    component: CartPage,
  },
  {
    path: "/checkout",
    name: "Checkout",
    meta: {
      layout: 'non-logged-layout',
    },
    component: CheckoutPage,
  },
  {
    path: "/required-demo",
    name: "RequiredDemo",
    meta: {
      layout: 'non-logged-layout',
    },
    component: RequiredDemoPage,
  },
  /**********************************************************************/
  {
    path: "/dashboard",
    name: "Dashboard",
    component: DashboardPage,
    
    meta: {
      layout: 'non-logged-layout',

    },
  },
  {
    path: "/kyc",
    name: "Kyc",
    component: KYCPage,
    props: true,
    meta: {
      middleware: [auth],
      layout: 'logged-layout',

    }
  },
  {
    path: "/order-recieved",
    name: "OrderRecieved",
    component: OrderRecievedPage,
    props: true,
    meta: {
      middleware: [auth],
      layout: 'logged-layout',

    }
  },
  {
    path: "/order-recieved-view",
    name: "OrderRecievedView",
    component: OrderRecievedViewPage,
    props: true,
    meta: {
      middleware: [auth],
      layout: 'logged-layout',

    },
  },
  {
    path: "/my-biz",
    name: "MyBiz",
    component: MyBizPage,
    props: true,
    meta: {
      middleware: [auth],
      layout: 'logged-layout',

    },
  },

  {
    path: "/order-placed-view",
    name: "OrderPlacedView",
    component: OrderPlacedViewPage,
    props: true,
    meta: {
      middleware: [auth],
      layout: 'logged-layout',

    },
  },
  {
    path: "/order-placed",
    name: "OrderPlaced",
    component: OrderPlacedPage,
    props: true,
    meta: {
      middleware: [auth],
      layout: 'logged-layout',

    },
  },
  {
    path: "/order-placed-message",
    name: "OrderPlacedMessage",
    component: OrderPlacedMessagePage,
    props: true,
    meta: {
      middleware: [auth],
      layout: 'logged-layout',

    },
  },
  {
    path: "/finance-support",
    name: "FinanceSupport",
    component: FinanceSupportPage,
    props: true,
    meta: {
      middleware: [auth],
      layout: 'logged-layout',

    }
  },
  {
    path: "/transport-support",
    name: "TransportSupport",
    component: TransportSupportPage,
    props: true,
    meta: {
      middleware: [auth],
      layout: 'logged-layout',

    }
  },
  {
    path: "/short-inventory",
    name: "ShortInventory",
    component: ShortInventoryPage,
    props: true,
    meta: {
      middleware: [auth],
      layout: 'logged-layout',

    }
  }, 
  {
    path: "/contact-us",
    name: "ContactUs",
    component: ContactUsPage,
    props: true,
    meta: {
      //middleware: [auth],
      layout: 'non-logged-layout',

    }
  },
  {
    path: "/shipments",
    name: "Shipment",
    component: ShipmentPage,
    props: true,
    meta: {
      middleware: [auth],
      layout: 'logged-layout',

    }
  },
  {
    path: "/products",
    name: "Product",
    component: ProductPage,
    props: true,
    meta: {
      middleware: [auth],
      layout: 'logged-layout',

    }
  },
  {
    path: "/product-add",
    name: "ProductAdd",
    component: ProductAddPage,
    props: true,
    meta: {
      middleware: [auth],
      layout: 'logged-layout',

    }
  },
  {
    path: "/product-add-rate-tmt",
    name: "ProductAddRateTMT",
    component: ProductAddRateTMTPage,
    props: true,
    meta: {
      middleware: [auth],
      layout: 'logged-layout',

    }
  },
  {
    path: "/product-add-rate-sheet",
    name: "ProductAddRateSheet",
    component: ProductAddRateSheetPage,
    props: true,
    meta: {
      middleware: [auth],
      layout: 'logged-layout',

    }
  },
  {
    path: "/product-add-rate-coil",
    name: "ProductAddRateCoil",
    component: ProductAddRateCoilPage,
    props: true,
    meta: {
      middleware: [auth],
      layout: 'logged-layout',

    }
  },
  {
    path: "/wish-list",
    name: "WishList",
    component: WishListPage,
    props: true,
    meta: {
      middleware: [auth],
      layout: 'logged-layout',

    }
  },
  {
    path: "/account",
    name: "Account",
    component: AccountPage,
    props: true,
    meta: {
      middleware: [auth],
      layout: 'logged-layout',

    }
  },
  {
    path: "/account/delete",
    name: "DeleteMyAccount",
    component: DeleteMyAccountPage,
    props: true,
    meta: {
      middleware: [auth],
      layout: 'logged-layout',

    }
  },
  {
    path: "/buy/sellers",
    name: "Seller",
    component: SellerPage,
    props: true,
    meta: {
      layout: 'non-logged-layout',

    }
  },
  {
    path: "/buy/seller-products",
    name: "SellerProduct",
    meta: {
      layout: 'non-logged-layout',
    },
    component: SellerProductPage,
  },
  {
    path: "/buy/calculate-price",
    name: "CalculatePrice",
    meta: {
      layout: 'no-logged-layout',
    },
    component: CalculatePricePage,
  },
  {
    path: "/buy/seller-product-rates",
    name: "SellerProductRate",
    meta: {
      layout: 'non-logged-layout',
    },
    component: SellerProductRatePage,
  },
  {
    path: "/buy/all-products",
    name: "AllProduct",
    meta: {
      layout: 'non-logged-layout',
    },
    component: AllProductPage,
  },
  {
    path: "/payment",
    name: "Payment",
    meta: {
      layout: 'logged-layout',
    },
    component: PaymentPage,
  }
  ,
  {
    path: "/payment-message",
    name: "PaymentMessage",
    meta: {
      layout: 'logged-layout',
    },
    component: PaymentMessagePage,
  },
  {
    path: "/notifications",
    name: "Notification",
    meta: {
      layout: 'logged-layout',
    },
    component: NotificationPage,
  },
  {
    path: "/logout",
    name: "Logout",
    props: true,
    meta: {
      layout: 'non-logged-layout',
    },
    component: LogoutPage,
  },
  {
    path: "/generate-invoice",
    name: "GenerateInvoice",
    meta: {
      layout: 'logged-layout',
    },
    component: GenerateInvoicePage,
  },
  {
    path: "/invoice-view",
    name: "InvoiceView",
    meta: {
      layout: 'logged-layout',
    },
    component: InvoiceViewPage,
  },
  {
    path: "/cms/privacy-policy",
    name: "PrivacyPolicy",
    meta: {
      layout: 'non-logged-layout',
    },
    component: PrivacyPolicyPage,
  },
  {
    path: "/cms/refund-policy",
    name: "RefundPolicy",
    meta: {
      layout: 'non-logged-layout',
    },
    component: RefundPolicyPage,
  },
  {
    path: "/cms/toc",
    name: "Toc",
    meta: {
      layout: 'non-logged-layout',
    },
    component: TocPage,
  },
  {
    path: "/cms/about-us",
    name: "AboutUs",
    meta: {
      layout: 'non-logged-layout',
    },
    component: AboutUsPage,
  },

  {
    path: "/how-it-works",
    name: "HowItWorks",
    meta: {
      layout: 'non-logged-layout',
    },
    component: HowItWorksPage,
  },

  {
    path: "/become-seller",
    name: "BecomeSeller",
    meta: {
      layout: 'non-logged-layout',
    },
    component: BecomeSellerPage,
  },
  {
    path: "/faq",
    name: "FAQ",
    meta: {
      layout: 'non-logged-layout',
    },
    component: FAQPage,
  },
  
  
  /**********************************************************************/
  { path: '/:pathMatch(.*)*', name: 'not-found', component: NotFound },
  { path: '/:pathMatch(.*)', name: 'bad-not-found', component: NotFound }
];
const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_DEPLOY_PATH),
  routes,
  mode: 'hash'
});

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next()
  }
  const middleware = to.meta.middleware

  const context = {
    to,
    from,
    next
  }
  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1)
  })
});
export default router;