import axiosLogged from "../helpers/axiosLogged";

function getProductRates(emitterObj,payload) {
    axiosLogged.post("/dashboard/products",payload)
        .then(response => {
            emitterObj.emit('onProductRateSuccess', response.data.data.body); 
        }).catch(error => {
            emitterObj.emit('onProductRateFail', Promise.reject(error));
    });

}

function getCities(emitterObj){
    axiosLogged.post("/dashboard/cities","")
        .then(response => {
            emitterObj.emit('onCitySuccess', response.data.data.body); 
        }).catch(error => {
            emitterObj.emit('onCityFail', Promise.reject(error));
    });
}
export const dashboardService = {
    getProductRates,
    getCities
};