<script>
import { ref } from "vue";
import { userService } from "../services/userService";
import mitt from "mitt";
import AlertComp from "../components/AlertComp.vue";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import {
  getAuth,
  signInWithPhoneNumber,
  RecaptchaVerifier,
} from "firebase/auth";
import { cmsService } from "../services/cmsService";

const emitter = mitt();

const OTP_TIMER = 60;

export default {
  name: "HomePage",
  components: {
    AlertComp,
  },
  data() {
    return {
      dialog: false,
      countryCode: "+91",
      form: {
        mobileNum: "",
        otp: "2022",
        gOtp: "",
        //user:null,
      },
      dummyMobile: false,
      resendBtn: false,
      otpBtn: true,
      resendTimer: OTP_TIMER,
      resendCloseTimer: null,
      enableResendBtn: true,
      enableOtpBtn: false,
      cAgree: false,
      alertShow: false,
      alertMsg: "",
      otpBtnLabel: "Send OTP",

      confirmationResult: null,
      recaptchaVerifier: null,
      recaptchaWidgetId: null,
      confirmResult: null,
      smsSent: false,
      capatchVerified: false,
      tocContents: "",
    };
  },
  created: function () {
    if (userService.isLogged()) {
      this.goToDashboard();
      return;
    }
  },
  mounted: function () {
    //this.setToolbarTitle("Home");
    //this.setPageTitle("Home");
    this.setToolbarTitle("Login");
    this.setPageTitle("Home");
    if (!userService.isLogged()) {
      firebase.auth().useDeviceLanguage();
      this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier("log-in", {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          this.capatchVerified = true;
          //console.log(response);
        },
      });
      emitter.on("onTocSuccess", this.onTocSuccess);
      cmsService.getToc(this, emitter);
    }
  },
  methods: {
    showToggleDialog: function (bool) {
      this.dialog = bool;
    },
    onTocSuccess: function (data) {
      //alert(data);
      this.tocContents = data;
    },
    onFormSubmit() {
      if (this.form.otp == "") {
        this.showAlert("Please enter Otp.");
        return;
      }
      if (this.cAgree == false) {
        this.showAlert("Please accept termns & conditions");
        return;
      }
      //for dummy mobile number
      if (this.dummyMobile) {
        this.form.otp = this.form.gOtp; 
        emitter.on("onLoginSuccess", this.onLoginSuccess);
        emitter.on("onLoginFail", this.onLoginFail);
          //this.form.user = user;
        userService.login(this.form, this, emitter);
        //alert("gotologin");
        //dummy moblie
      } else {
        this.confirmResult
          .confirm(this.form.gOtp)
          .then((result) => {
            var user = result.user;
            emitter.on("onLoginSuccess", this.onLoginSuccess);
            emitter.on("onLoginFail", this.onLoginFail);
            //this.form.user = user;
            userService.login(this.form, this, emitter);
            //console.log(user);
          })
          .catch((error) => {
            this.showError("Invalid Otp - " + error);
          });
      }
    },
    onLoginSuccess(result) {
      //console.log(result);
      emitter.on("onUserViewSuccess",this.loadDashboard);
      userService.view(this,emitter);
    },
    loadDashboard(d){
      this.goToDashboard();
    },
    showAlert: function (msg) {
      //this.alertShow = true;
      //this.alertMsg = msg;
      this.showError(msg);
    },
    onLoginFail(error) {
      this.showError(error);
    },
    mobileExist() {
      //emitter.on("OnValidateRegistrationScuccess", this.OnValidateRegistrationScuccess);
      //userService.validateRegistration(this.form.mobileNum, this, emitter);
    },
    OnValidateRegistrationScuccess(bool) {
      if (bool) {
        //this.enableOtpBtn = false;
        //firebase.auth().settings.appVerificationDisabledForTesting = true;
        /*
        this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
          "recaptcha-container"
        );*/

        this.recaptchaVerifier.render().then((widgetId) => {
          this.recaptchaWidgetId = widgetId;
        });

        var number = this.countryCode + this.form.mobileNum;
        firebase
          .auth()
          .signInWithPhoneNumber(number, this.recaptchaVerifier)
          .then((confirmationResult) => {
            this.confirmResult = confirmationResult;
            //console.log(this.confirmResult);
            //alert("Sms Sent!");
            this.showInfo("Otp Sent to your mobile - " + this.form.mobileNum);
            this.smsSent = true;
          })
          .catch((error) => {
            this.showError("OTP not sent - " + error.message);
          });

        this.enableOtpBtn = true;
        this.resendTimer = OTP_TIMER;
        this.countDownTimer();
      } else {
        this.showError(this.form.mobileNum + " - Not registered with us !");
      }
    },
    sendOTP: function (resend) {
      this.dummyMobile = false;
      if (this.form.mobileNum.length != 10) {
        this.showAlert("Invalid mobile number.");
        return;
      }
      let isDummyMob =
        this.form.mobileNum >= 1000000001 && this.form.mobileNum <= 1000000999
          ? true
          : false;
      if (isDummyMob) {
        this.dummyMobile = true;
        this.smsSent = true;
        //alert(this.form.mobileNum);
      } else {
        this.smsSent = false;
        emitter.on(
          "OnValidateRegistrationScuccess",
          this.OnValidateRegistrationScuccess
        );
        userService.validateRegistration(this.form.mobileNum, this, emitter);
      }
    },
    countDownTimer: function () {
      if (this.resendTimer > 0) {
        this.resendCloseTimer = setTimeout(() => {
          this.resendTimer -= 1;
          this.countDownTimer();
        }, 1000);
      } else {
        this.resendTimer = 0;
        this.enableOtpBtn = false;
      }
      this.otpBtnLabel = "Re-Send OTP ( " + this.resendTimer + " ) ";
    },
  },
  setup() {
    return {
      navPos: ref("bottom"),
      slide: ref("style"),
      autoplay: ref(true),
    };
  },
  unmounted: function () {
    emitter.off("onLoginSuccess");
    emitter.off("onLoginFail");
    emitter.off("onLoginFailError");
    emitter.off("onTocSuccess");
    emitter.off("onUserViewSuccess");
  },
  watch: {
    resendTimer: function () {
      //console.log(this.resendTimer);
      if (this.resendTimer == OTP_TIMER) {
        clearInterval(this.resendTimer);
        //this.$router.push({ name: "Home" });
        //this.goToHome();
      }
    },
  },
};
</script>

<template>
  <q-page-container class="q-pa-md">
    <q-page class="q-pa-xs-md q-gutter-md">
      <div class="row">
        <div class="col-12 text-center text-bold">
          <br />
          <q-btn round class="">
            <q-avatar size="120px" @click="goToDashboard()">
              <!--<img src="../assets/img/splash.jpg" style="width: auto"  />-->
              <img src="../assets/img/new_splash.png" style="width: auto"  />
            </q-avatar>
          </q-btn>
        </div>
      </div>
      <div class="text-center">
        <span class="text-h5 text-bold text-center text-grey-7">
          Welcome back !</span
        ><br />
        <span class="text-body text-bold text-center text-grey-5"
          >Login to your account</span
        >
      </div>

      <q-separator />
      <br />
      <div class="row">
        <div class="col-12">
          <q-input
            prefix="+91"
            rounded
            outlined
            v-model="form.mobileNum"
            label="Mobile Number*"
            maxlength="10"
            type="number"
            :rules="[
              (val) => (val && val.length >= 10) || 'Enter mobile number !',
            ]"
            hint=" "
            inputClass="text-bold"
          >
            <template v-slot:prepend>
              <q-icon name="phone" />
            </template>
          </q-input>
          <!-- captacha -->
          <div class="row">
            <div class="col">
              <div id="recaptcha-container"></div>
            </div>
          </div>
          <q-input
            rounded
            outlined
            type="number"
            v-model="form.gOtp"
            label="OTP*"
            lazy-rules
            :rules="[(val) => (val && val.length > 0) || 'Enter Otp !']"
            hint=" "
            v-show="smsSent"
          >
            <template v-slot:prepend>
              <q-icon name="lock" />
            </template>
          </q-input>
          <div class="q-gutter-xs" v-show="smsSent">
            <q-checkbox v-model="cAgree" label=" " color="blue" /> I Agree -

            <span class="text-blue" @click="showToggleDialog(true)"
              >Terms & Conditions</span
            >

            <!--
            <allow-navigation class="text-blue" href="https://steeldalal.vredly.in/toc">Terms & Conditions</allow-navigation>
            -->
            <br />
          </div>
          <div class="row">
            <div class="col" v-show="smsSent">
              <q-btn
                size="md"
                style="width: auto"
                rounded=""
                label="Submit"
                type="button"
                color="green"
                class="btn-primary"
                @click="onFormSubmit"
                v-show="smsSent"
              />
            </div>
            <div class="col text-center">
              <q-btn
                size="md"
                style="width: auto"
                rounded=""
                :label="otpBtnLabel"
                type="button"
                id="log-in"
                class="c-bg-color text-white btn-primary"
                v-show="otpBtn"
                :disable="enableOtpBtn"
                @click="sendOTP(false)"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-right">&nbsp;</div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <a href="tel:+919839651123" class="text-grey-7 text-bold  text-primary"
            >Call Us?
          </a>
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <a href="#" class="text-grey-7 text-bold text-primary" @click.stop="goToRegister()"
            >Create a New Account ?</a
          >
        </div>
      </div>
      <div>
        <q-dialog
          v-model="dialog"
          persistent
          full-width
          v-show="dialog"
          class="bg-black"
        >
          <q-card>
            <q-card-section v-html="tocContents"> </q-card-section>
          </q-card>
          <q-card class="text-black text-center bg-black">
            <q-card-section>
              <q-btn
                size="10px"
                label="Close"
                color="primary"
                @click="showToggleDialog(false)"
              />
            </q-card-section>
          </q-card>
        </q-dialog>
      </div>
      <!-- alert comp -->
      <alert-comp :msg="alertMsg" :show="alertShow" v-show="alertShow" />
      <!-- /alert comp-->
    </q-page>
  </q-page-container>
</template>

