<template>
  <div class="q-pa-sm">
    <q-layout view="lHh lpr lFf">
      <q-header elevated v-show="true">
        
        <q-toolbar class="c-toolbar shadow-2">
          <!--
          <q-btn
            outline
            dense
            icon="home"
            class="q-mr-sm"
            v-show="btnHomeShow"
            @click="goToHome()"
          />
          -->
          <!--<q-btn dense  color="primary" icon="home" @click="this.goToDashboard()"></q-btn>-->
          <q-btn round class="">
            <q-avatar size="40px">
             <!-- <img src="/img/splash.3d7bdd78.jpg" style="width: auto" @click="this.goToDashboard()"/>-->
             <img src="/img/new_splash.d2767935.png" style="width: auto" @click="this.goToDashboard()" class="bg-white"/>
             
            </q-avatar>
          </q-btn>
        
      <q-toolbar-title class="text-body1">{{ toolbarTitle }} </q-toolbar-title>
      <span v-show="toolbarTitle !== 'Login'">
      <div class="row q-pa-none" v-if="!isLogged">
        <div class="col text-right">
          <!--<q-icon name="" /><a @click="goToHome()" class="text-white text-underline btn-xs q-btn-xs" icon="person">Login</a>-->
          
          <q-btn dense  color="primary" icon="login" class="q-ml-lg" @click="goToHome()" style="font-size:14px; text-transform:capitalize;">Login</q-btn>
          <!--TEST DIALOG-->    
              <!--<q-avatar color="white" text-color="primary" style="font-size:26px;" class="q-ml-lg" icon="person" @click="profiledialog = true"/>-->
          <!--END TEST DIALOG -->            
        </div>
      </div>
      </span>
      
          <!--<q-toolbar-title class="text-body1">steeldalal.com</q-toolbar-title>-->
          <span v-show="toolbarTitle !== 'Login'">
          <q-btn dense  color="primary" icon="notifications" class="q-ml-md" @click="goToNotification()">
          <q-badge color="red" floating v-show="notificationCount > 0">{{notificationCount}}</q-badge>
        </q-btn>
          
        &nbsp;&nbsp;
        </span>
        <!--<q-btn dense color="primary" icon="shopping_cart" @click="goToCart()">
          <q-badge color="red" floating v-show="cartItemCount > 0">{{cartItemCount}}</q-badge>
        </q-btn>-->
          
          <!--
          <q-btn
            squar
            outline
            dense
            icon="person"
            @click="goToLogin()"
            v-show="btnMyAccountShow"
          >
            My Account
          </q-btn>
          -->
        </q-toolbar>
         <!--<NonLoggedFooter></NonLoggedFooter>-->
      </q-header>
      <Footer :cartTotalItems="cartItemCount"></Footer>
      <slot><!---- views will render here ---></slot>
    </q-layout>
  </div>
      <menu-non-logged :open="openNonLogged" @close="toggleNonLoggedMenu"></menu-non-logged>

</template>

<script>
import mitt from 'mitt';
import { statsService } from '../../services/statsService';
import NonLoggedFooter from "./includes/NonLoggedFooter.vue";
const emitter = mitt();
import { ref } from "vue";
import { useRoute, useRouter } from 'vue-router'
import { onMounted } from 'vue'
import Footer from './includes/Footer.vue';
export default {
  name: "NonLoggedLayout",
  props: ["toolbarTitle"],
  components:{
    Footer
  },
  
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
      backBtn:true,
      cartItemCount:0,
      notificationCount:0,
      profiledialog: ref(false),
      maximizedToggle: ref(true),
      route_name: useRoute().name



    };
  },
  methods: {
    onStatsSuccess:function(data){
        //console.log(data);
        this.cartItemCount = data.data.total_cart_items;
        this.notificationCount = data.data.total_notifications;
        localStorage.setItem("gst",data.data.gst);
    },
    onStatsFail:function(error){
        //console.log(error);
    }
  },
  updated: function () {
    if (
      this.$router.currentRoute.value["name"] == "Home") {
      this.backBtn = false;
    } else{
      this.backBtn = true;  
    }
    emitter.on("onStatsSuccess",this.onStatsSuccess);
    emitter.on("onStatsFail",this.onStatsFail);
    statsService.getStats(emitter);
  },
  unmounted:function(){
      emitter.off("onStatsSuccess",this.onStatsSuccess);
      emitter.off("onStatsFail",this.onStatsFail);
      //console.log("LOOGED-LAYOUT:: UnMOUNTED");
  }
};
</script>
