<template>
  <q-layout view="lHh Lpr lFf">
    <q-page-container>
      <q-page class="flex flex-center">
        <span v-if="success" class="text-bold text-subtitle1 text-center text-green">
        <q-icon name="check" size="100px" /> <br />

        <!-- Your payment has been made successfully ! -->
          <br />
          <span class="text-body2 text-blue">
            Thank you For your Purchase Track your order:
          </span>
          <br /><br />
          <q-btn color="primary" size="sm" label="My Orders" @click="goToOrderPlaced()"/>
        </span>

        <span v-if="!success" class="text-bold text-subtitle1 text-center text-red">
          <q-icon name="dangerous" size="100px" /> <br />

          Payment Failure !
          <br />
          <span class="text-body2 text-red">
           Reason: {{error}}
          </span>
          <br /><br />
          <q-btn color="primary" size="sm" label="Click to go Placed Order(s)" @click="goToOrderPlaced()"/>
        </span>
        
      </q-page>
    </q-page-container>
  </q-layout>
</template>
<script>
import mitt from "mitt";
import { emit } from "process";

const emitter = mitt();

export default {
  name: "PaymentMessagePage",
  data() {
    return {
        success:false,
        error:'UNKNOWN',
    };
  },
  mounted:function(){
    this.setPageTitle("Payment Status");
    this.setToolbarTitle("Payment Status");
    let mt = this.$route.query.act;
    this.error = this.$route.query.error;
    if(mt=="success"){
      this.success = true;
    }else{
      this.success = false;
    }
    this.success = true;
  }
};
</script>
