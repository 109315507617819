<template>
  <q-layout view="lHh Lpr lFf">
    <q-page-container>
      <q-page class="flex flex-center">
        <span class="text-bold text-subtitle1 text-center">
          <q-icon name="check" class="text-green" rounded size="100px" /> <br />
          <span class="text-body2 text-blue">
            Thank you for your order, Awaiting Confirmation From SteelDalal
          </span> <br /><br />
          <q-list v-if="data!=null" class="text-caption text-bold" bordered>
            <q-separator />
            <q-item class="bg-grey-2">
              <q-item-section class="text-bold">Order #</q-item-section>
              <q-item-section class="text-left">
                <span class="text-uppercase">{{ orderNum}} </span>
              </q-item-section>
            </q-item>
            <q-separator />
            <q-item>
              <q-item-section class="q-pa-xs text-bold"
                >Date:</q-item-section
              >
              <q-item-section class="text-left">
                <span class="text-uppercase"
                  >{{ formatDate(data.created_at)}}
                </span>
              </q-item-section>
            </q-item>
            <q-separator />
            <q-item  class="bg-grey-2">
              <q-item-section class="q-pa-xs text-bold"
                >Time:</q-item-section
              >
              <q-item-section class="text-left">
                <span class="text-uppercase"
                  >{{ formatTime(data.created_at)}}
                </span>
              </q-item-section>
            </q-item>
            <q-separator />
            <q-item>
              <q-item-section class="q-pa-xs text-bold"
                >Total</q-item-section
              >
              <q-item-section class="text-left">
                <span class="text-uppercase"
                  ><q-icon name="currency_rupee" />{{ data.total_amount}}
                </span>
              </q-item-section>
            </q-item>
            <q-separator />
          </q-list>
          <br />
         
          <br /><br />
          <q-btn
            color="primary"
            size="sm"
            label="Click to See Order Placed"
            @click="goToOrderPlaced()"
          />
        </span>
      </q-page>
    </q-page-container>
  </q-layout>
</template>
<script>
import mitt from "mitt";
import { emit } from "process";
import { orderService } from "../../services/orderService";

const emitter = mitt();

export default {
  name: "OrderPlacedMessagePage",
  data() {
    return {
      data: null,
      orderNum:0,
    };
  },
  methods: {
    onGetOrderSuccess: function (data) {
      this.data = data.data;
      this.orderNum = data.orderNum;
      console.log(this.data.api_id);
      console.log(this.data);
    },
    onGetOrderFail: function (error) {
      this.showError(error);
    },
  },
  mounted: function () {
    this.setPageTitle("Order Placed");
    this.setToolbarTitle("Order Placed");

    emitter.on("onGetOrderSuccess", this.onGetOrderSuccess);
    emitter.on("onGetOrderFail", this.onGetOrderFail);
    orderService.getOrder({ api_id: this.$route.query._id }, this, emitter);
  },
  unmounted: function () {
    emitter.off("onGetOrderSuccess");
    emitter.off("onGetOrderFail");
  },
};
</script>
