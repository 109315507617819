<template>
  <q-footer
    bordered
    class="c-toolbar no-shadow text-caption"
    elevated
    style="height: auto"
  >
   
    <q-tabs
      no-caps=""
      active-color="white"
      indicator-color="transparent"
      class="text-white text-caption"
      v-model="ntab"
      style="display: block !important"
    >
      <q-tab
        class=""
        :ripple="false"
        icon="home"
        label="Home"
        @click="goToDashboard()"
      />

      <q-separator gray vertical inset />
      <q-tab
        :ripple="false"
        icon="ion-basket"
        label="Buy"
        v-show="true"
        @click="goToAllProduct()"
      />

      <!--
      <q-separator gray vertical inset />
      <q-tab :ripple="false" icon="money" label="Sell" @click="goToProduct()" />
      -->
      <!--<q-separator gray vertical inset />
      <q-tab
        :ripple="false"
        icon="business"
        label="My Biz"
        @click="goToMyBiz()"
      /> -->
      <q-separator gray vertical inset />

      <q-tab
        :ripple="false"
        icon="shopping_cart"
        label="Cart"
        @click="goToCart()"
      >
        <q-badge color="red" floating v-show="cartTotalItems > 0">{{
          cartTotalItems
        }}</q-badge>
      </q-tab>

      <q-separator gray vertical inset />
      <q-tab
        :ripple="false"
        icon="fa fa-bars"
        label="More"
        @click="profiledialog = true"
        v-show="1"
      />
    </q-tabs>
  </q-footer>
  <q-drawer
    v-model="drawer"
    :breakpoint="500"
    full-width
    bordered
    overlay
    side="right"
    class="bg-grey-3"
  >
    <q-scroll-area
      class="fit"
      style="
        height: calc(100% - 150px);
        margin-top: 150px;
        border-right: 1px solid #ddd;
      "
    >
      <q-list>
        <template v-for="(menuItem, index) in menuList" :key="index">
          <q-item
            clickable
            :active="menuItem.label === menuItem.id"
            v-ripple
            @click="goToX(menuItem.id)"
          >
            <q-item-section avatar>
              <q-icon :name="menuItem.icon" />
            </q-item-section>
            <q-item-section
              :class="
                menuItem.id == 'DeleteMyAccount' ? 'text-red' : 'text-blue'
              "
            >
              {{ menuItem.label }}
            </q-item-section>
          </q-item>
          <q-separator :key="'sep' + index" v-if="menuItem.separator" />
        </template>
      </q-list>
    </q-scroll-area>
    <q-img
      class="absolute-top"
      src="../../../assets/img/avatar-bg.jpg"
      style="height: 150px"
    >
      <!--<div class="absolute-bottom bg-transparent">
        <q-avatar size="56px" class="q-mb-sm">
          <img src="../../../assets/img/avatar.jpg" />
        </q-avatar>
        <div class="text-weight-bold">
          <q-icon name="badge"></q-icon> {{ this.userName }}
        </div>
        <div><q-icon name="place"></q-icon> {{ location }}</div>
        <div><q-icon name="phone"></q-icon> {{ mobile }}</div>
      </div>-->
    </q-img>
  </q-drawer>
</template>
<script>
import { ref } from "vue";
import { userService } from "../../../services/userService";
import { statsService } from "../../../services/statsService";
//import NonLoggedLayout from "../NonLoggedLayout.vue";
import ProfilePopUpFooter from "../includes/ProfilePopUpFooter.vue";
/*const menuList = [
  {
    id: "Dashboard",
    icon: "ion-stats",
    label: "Dashboard",
    separator: true,
  },
  {
    id: "WishList",
    icon: "ion-heart",
    iconColor: "primary",
    label: "My Wish list",
    separator: false,
  },
  
  {
    id: "Faq",
    icon: "quiz",
    iconColor: "primary",
    label: "FAQs",
    separator: false,
  },
  {
    id: "AboutUs",
    icon: "note",
    iconColor: "primary",
    label: "About Us",
    separator: false,
  },
  {
    id: "Toc",
    icon: "note",
    iconColor: "primary",
    label: "Terms & Conditions",
    separator: false,
  },
  {
    id: "PrivacyPolicy",
    icon: "note",
    iconColor: "primary",
    label: "Privacy Policy",
    separator: false,
  },
  {
    id: "RefundPolicy",
    icon: "note",
    iconColor: "primary",
    label: "Refund Policy",
    separator: false,
  },
  {
    id: "ContactUs",
    icon: "note",
    iconColor: "primary",
    label: "Contact Us",
    separator: false,
  },
 
];*/

import mitt from "mitt";
const emitter = mitt();

export default {
  name: "NonLoggedFooter",
  props: ["cartTotalItems"],
  components: {
    //NonLoggedLayout
    //ProfilePopUpFooter,
  },
  //props:['userName'],
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
      userName: "",
      location: "",
      mobile: "",
      ntab: "",
      isCustomer: false,
      profiledialog: ref(false),
      maximizedToggle: ref(true),
      dialog: false,
      cartItemCount: 0,
    };
  },
  methods: {
    callme: function () {
      //alert("hi");
    },
    showToggleDialog: function (bool) {
      this.dialog = bool;
    },

    onStatsSuccess: function (data) {
      //alert("hii");
      //console.log(data);
      this.cartItemCount = data.data.total_cart_items;
      //this.notificationCount = data.data.total_notifications;
      localStorage.setItem("gst", data.data.gst);
    },
    onStatsFail: function (error) {
      //console.log(error);
    },
  },

  mounted: function () {
    statsService.getStats(emitter);
    emitter.on("onStatsSuccess", this.onStatsSuccess);
  },
  updated: function () {
    if (this.$router.currentRoute.value["name"] == "Home") {
      this.backBtn = false;
    } else {
      this.backBtn = true;
    }
    emitter.on("onStatsSuccess", this.onStatsSuccess);
    emitter.on("onStatsFail", this.onStatsFail);
    statsService.getStats(emitter);
  },
  unmounted: function () {
    emitter.off("onStatsSuccess", this.onStatsSuccess);
    emitter.off("onStatsFail", this.onStatsFail);
  },
  setup() {
    return {
      //drawer: ref(false),
      //menuList,
    };
  },
};
</script>
