<template>
  <q-layout view="lHh Lpr lFf" class="c-bg-color">
    <q-page-container>
      <q-page class="flex flex-center">
        <!--
        <img
          src="../assets/img/splash.jpg"
          style="width: 150px; height: 150px"
        />
        -->
        <q-btn round>
          <q-avatar size="120px">
            <img src="../assets/img/new_splash.png" style="width:auto" class="bg-white"/>
          </q-avatar>
        </q-btn>
      </q-page>
    </q-page-container>
  </q-layout>
</template>
<script>
import { userService } from "../services/userService";
import mitt from "mitt";
import { emit } from "process";

const emitter = mitt();

export default {
  name: "SplashScreenPage",
  data() {
    return {
      splashTimer: 10,
      closeTimer: null,
    };
  },
  created: function () {
    this.countDownTimer();
  },
  mounted: function () {
      if(userService.isLogged()){
          userService.view(this,emitter); 
      }
  },
  methods: {
    countDownTimer: function () {
      if (this.splashTimer > 0) {
        this.closeTimer = setTimeout(() => {
          this.splashTimer -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
  },
  watch: {
    splashTimer: function () {
      //console.log(this.splashTimer);
      if (this.splashTimer == this.splashTimer) {
        clearInterval(this.closeTimer);
        //this.$router.push({ name: "Home" });
        this.goToDashboard();
      }
    },
  },
};
</script>
