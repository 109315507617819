<template>
  <q-page-container class="bg-container">
    <q-page class="q-pa-xs-md q-gutter-md">
      <div class="text-center text-bold text-h4 text-grey"  v-if="data==null || data.length =='0'">
            <br /><br /><br /><br />
            There is no notification found !!.
      </div>
      <q-list v-if="data != null">
        <template v-for="(item, index) in data" v-bind:key="item">
          <q-item :id="'notify-' + index">
            <q-item-section>
              <q-item-label>{{(item.heading==null) ? 'N/A': item.heading}}</q-item-label>
              <q-item-label caption lines="2"
                >{{item.msg}}</q-item-label
              >
            </q-item-section>
            <q-item-section side top>
              <q-item-label caption class="text-bold">{{item.created_at}}</q-item-label>
              <br />
              <!--
              <q-icon name="view" color="black" @click="onDelete(item.api_id)" />
              -->
              <q-btn color="green" size="sm" icon="check" @click="onDelete(item.api_id)" > Read </q-btn>
            </q-item-section>
          </q-item>
          <q-separator spaced inset />
        </template>
      </q-list>
    </q-page>
  </q-page-container>
</template>
<script>
import mitt from "mitt";
import { emit } from "process";
import { notificationService } from "../services/notificationService";
const emitter = mitt();

export default {
  name: "NotificationPage",
  data() {
    return {
      data: null,
    };
  },
  created: function () {},
  mounted: function () {
    this.setToolbarTitle("Notifications");
    this.setPageTitle("Notifications");
    this.pullNotifications();
  },
  methods: {
    pullNotifications: function () {
      emitter.on("onNotificationListSuccess", this.onNotificationListSuccess);
      emitter.on("onNotificationListFail", this.onNotificationListFail);
      notificationService.getNotifications(emitter, this);
    },
    onNotificationListSuccess: function (data) {
      this.data = data.data;
      //console.log(data);
    },
    onNotificationListFail: function () {},
    onDelete:function(api_id)
    {
      emitter.on("onNotificationDelSuccess",this.onNotificationDelSuccess);
      emitter.on("onNotificationDelFail",this.onNotificationDelFail);
      notificationService.deleteNotification(emitter,this,{'api_id':api_id});
    },
    onNotificationDelFail:function(data){},
    onNotificationDelSuccess:function(data){
      let d = data;
      if(d.type != undefined){
        //alert(d.type);
        let type = d.type;
        switch(type){
          case "ord-receive":
          case "ord-payment":
          case "ship-receive":  
            this.goToOrderRecieved();
            break;
          case "ord-placed":
          case "ord-confirm":
          case "ord-decline":
          case "ship-load":
          case "ship-shipped":  
          case "inv-generate":
            this.goToOrderPlaced();
            break;
          case "kyc-submit":
          case "kyc-approve":
          case "transport-assign":
          case "notify-mandi-rate":
          default:
            this.goToMyBiz();
          break;
        }

      }
      //this.pullNotifications();
      //console.log(this.data.type);
    }
  },
  unmounted: function () {
    emitter.off("onNotificationListSuccess");
    emitter.off("onNotificationListFail");
    emitter.on("onNotificationDelSuccess");
    emitter.on("onNotificationDelFail");
      
      
  },
  setup() {},
};
</script>
