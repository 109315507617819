import axios from 'axios';
import { userService } from '../services/userService';
const ax = axios;
ax.interceptors.request.use(req => {
    //console.log("====================="+userService.isLogged());
    if(userService.isLogged()){
        req.baseURL = process.env.VUE_APP_API_URL;
        req.headers["time"]=1;
    }else{
        req.baseURL = process.env.VUE_APP_API_URL;
        req.headers["time"]=0;
    }
    //console.log(req.baseURL);
    //req.headers['Content-Type'] = 'application/json;charset=utf-8';
    //req.headers["baseURL"] = req.baseURL;
    req.headers["authorization"] = userService.getAuthToken();
    req.headers['Content-Type'] = 'multipart/form-data';
    req.time = { startTime: new Date() };
    return req;
});

ax.interceptors.response.use(res => {
    try {
        let logingExpired = res.data.data.body.error.code; //403
        if (logingExpired != "undefined" && logingExpired == "403") {
            localStorage.clear();
        }
    } catch (error) {
        //console.log(error);
    }
    return res;
});
export default ax;
