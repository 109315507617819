
<script>
import { ref } from "vue";
//const username = "";
export default {
  name: "MenuLogged",
  props: ["open","uName"],
  data() {
    return {
      dialog: false,
      userDevice: "",
      customerName: "",
    };
  },
  methods: {
    close: function () {
      //this.dialog = false;
      this.$emit("close", false);
    },
  },
  updated: function () {
    //alert(this.open);
    this.customerName = this.uName;
    //console.log(this.open);
    //this.dialog = this.open;
    if(this.dialog ==false){
      //this.dialog = false;
      this.$emit("close", false);    
    }
  },
  watch: {
    open: function (val) {
      this.dialog = val;
      this.userDevice = getMobileOperatingSystem();
      
    },
  },
};
//ref: https://codepen.io/hardiksolanki/pen/zPPwpd
function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if( userAgent.match( /iPad/i ) || userAgent.match( /iPhone/i ) || userAgent.match( /iPod/i ) )
  {
    return 'IOS';
  }
  else if( userAgent.match( /Android/i ) )
  {
    return 'Android';
  }
  else
  {
    return 'unknown'; 
    
  }
}


</script>
<template>
  <q-dialog
    v-model="dialog"
    persistent
    :maximized="dialog"
    transition-show="slide-up"
    transition-hide="slide-down"
  >
    <q-card class="bg-white text-black">
      <q-bar class="text-left bg-primary text-white">
        <q-space />
        <!--<q-btn dense flat icon="close" v-close-popup @click="close">
          <q-tooltip class="bg-white text-primary">Close</q-tooltip>
        </q-btn>-->
        <div class="col-12">
            <q-btn
              dense
              flat
              icon="arrow_back"
              class="q-ml-md"
              v-close-popup
              style="
                margin-left: -2px;
                text-transform: capitalize;
                font-weight: normal;
              "
              @click="close"
            >
            </q-btn><span
              class="q-ml-md"
              style="font-weight: normal; font-size: 12px"
              >My Profile</span
            >
            
          </div>
          
      </q-bar>
      
      <div class="col q-pa-xs" >
                <q-list>
                  <q-card-section class="col-12"
                  
                  >
                    <div class="col-12">
                      <div
                        class="q-btn q-btn-primary"
                        style="
                          background: #013f7a;
                          text-transform: capitalize;
                          color: white;
                          text-align: left;
                          width: 100%;
                          border: 2px solid #013f7a;
                          border-radius: 1rem;
                        "
                        clickable v-ripple
                        
                        
                      >
                        🙏 Namaste - {{ customerName }}
                      </div>
                    </div>
                  </q-card-section>

                  <q-card class="rounded q-mb-sm">
                    <q-item clickable v-ripple @click="goToFAQ()" v-close-popup>
                      <q-item-section avatar>
                        <q-avatar
                          rounded
                          color="primary"
                          text-color="white"
                          icon="contact_support"
                          style="font-size: 40px"
                        />
                      </q-item-section>

                      <q-item-section>FAQ's</q-item-section>
                    </q-item>

                    <q-separator />

                    <q-item
                      clickable
                      v-ripple
                      @click="goToContactUs()"
                      v-close-popup
                    >
                      <q-item-section avatar>
                        <q-avatar
                          rounded
                          color="primary"
                          text-color="white"
                          icon="perm_phone_msg"
                          style="font-size: 40px"
                        />
                      </q-item-section>
                      <q-item-section>Contact Us</q-item-section>
                    </q-item>

                    <q-separator />
                    <q-item
                      clickable
                      v-ripple
                      @click="goToRequiredDemo()"
                      v-close-popup
                    >
                      <q-item-section avatar>
                        <q-avatar
                          rounded
                          color="primary"
                          text-color="white"
                          icon="alarm "
                          style="font-size: 40px"
                        />
                      </q-item-section>
                      <q-item-section>Schedule a Demo?</q-item-section>
                    </q-item>

                    <q-separator />
                    <q-item clickable v-ripple @click="goToHowItWorks()" v-close-popup>
                      <q-item-section avatar>
                        <q-avatar
                          rounded
                          color="primary"
                          text-color="white"
                          icon="ion-logo-youtube "
                          style="font-size: 40px"
                        />
                      </q-item-section>
                      <q-item-section>How It works?</q-item-section>
                    </q-item>

                    
                  </q-card>
                  <q-separator />
                  <q-card class="no-margin no-padding rounded" v-close-popup>
                    <q-item
                      clickable
                      v-ripple
                      no-margin
                      no-padding
                      @click="goToAboutUs()"
                    >
                      <q-item-section avatar>
                        <q-avatar
                          rounded
                          color="primary"
                          text-color="white"
                          icon="info"
                          style="font-size: 40px"
                        />
                      </q-item-section>
                      <q-item-section>About Us</q-item-section>
                    </q-item>
                    <q-separator />
                    <span v-if="userDevice === 'Android' || userDevice === 'unknown'">
                    <!--<q-item href="https://api.whatsapp.com/send?text=https://play.google.com/store/apps/details?id=steel.dalal.com%26hl=en_IN%26gl=US">-->
                      <q-item href ="share:https://play.google.com/store/apps/details?id=steel.dalal.com&hl=en_IN&gl=US">
                      <q-item-section avatar >
                        
                        <q-avatar
                          rounded
                          color="primary"
                          text-color="white"
                          icon="share"
                          style="font-size: 40px"
                        />
                      
                      </q-item-section>
                      <q-item-section>Share App</q-item-section>
                      
                    </q-item>
                      <!--</a>-->
                    </span>

                    <span v-if="userDevice === 'IOS'">
                      
                    <!--<q-item href="https://api.whatsapp.com/send?text=https://apps.apple.com/us/app/steel-dalal/id6446887047">-->
                    <q-item href="share:https://apps.apple.com/us/app/steel-dalal/id6446887047">
                      
                      <q-item-section avatar >
                        
                        <q-avatar
                          rounded
                          color="primary"
                          text-color="white"
                          icon="share"
                          style="font-size: 40px"
                        />
                      
                      </q-item-section>
                      <q-item-section>Share App</q-item-section>
                      
                    </q-item>
                    <!--</a>-->
                    </span>

                    <q-separator />
                    <q-item
                      clickable
                      v-ripple
                      no-margin
                      no-padding
                      @click="goToPrivacyPolicy()"
                      v-close-popup
                    >
                      <q-item-section avatar>
                        <q-avatar
                          rounded
                          color="primary"
                          text-color="white"
                          icon="privacy_tip"
                          style="font-size: 40px"
                        />
                      </q-item-section>
                      <q-item-section>Privacy Policy</q-item-section>
                    </q-item>

                    <q-separator />
                    <q-item
                      clickable
                      v-ripple
                      no-margin
                      no-padding
                      @click="goToBecomeSeller()" 
                      v-close-popup
                    >
                      <q-item-section avatar>
                        <q-avatar
                          rounded
                          color="primary"
                          text-color="white"
                          icon="person"
                          style="font-size: 40px"
                          
                        />
                      </q-item-section>
                      <q-item-section>Become a Seller</q-item-section>
                    </q-item>
                  </q-card>
                </q-list>
              </div>

              <p class="text-center q-mt-md text-primary text-bold">
                &copy; 2023 Steel Dalal. All rights reserved.
              </p>
              <p class="text-center q-mt-md">Version - 1.0 </p>

    </q-card>
  </q-dialog>
</template>
 