import axiosLogged from "../helpers/axiosLogged";

function getAllSellerProducts(payload, ob, emitterObj) {
    ob.showLoading();
    axiosLogged.post("/product/all", payload)
        .then(response => {
            if(response.data.data.head.success=="true"){
                emitterObj.emit('onAllSellerProductsSuccess', response.data.data.body);
            }else{
                emitterObj.emit('onAllSellerProductsFail', response.data.data.body.error.msg);    
            }
            ob.hideLoading();
        }).catch(error => {
            ob.hideLoading();
            emitterObj.emit('onAllSellerProductsFail', Promise.reject(error));
        });
}

//based on brand
function getSellerProducts(payload, ob, emitterObj) {
    ob.showLoading();
    axiosLogged.post("/product/list", payload)
        .then(response => {
            if(response.data.data.head.success=="true"){
                emitterObj.emit('onSellerProductsSuccess', response.data.data.body);
            }else{
                emitterObj.emit('onSellerProductsFail', response.data.data.body.error.msg);    
            }
            ob.hideLoading();
        }).catch(error => {
            ob.hideLoading();
            emitterObj.emit('onSellerProductsFail', Promise.reject(error));
        });
}

function getSellerProductRates(payload, ob, emitterObj) {
    ob.showLoading();
    axiosLogged.post("/product/rates", payload)
        .then(response => {
            //console.log(response.data.data);
            if(response.data.data.head.success=="true"){
                emitterObj.emit('onSellerProductRatesSuccess', response.data.data.body);
            }else{
                emitterObj.emit('onSellerProductRatesFail', response.data.data.body.error.msg);    
            }
            ob.hideLoading();
        }).catch(error => {
            ob.hideLoading();
            emitterObj.emit('onSellerProductRatesFail', Promise.reject(error));
        });
}


export const buyService = {
    getSellerProducts,
    getSellerProductRates,
    getAllSellerProducts
    
};