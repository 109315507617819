<template>
  <q-page-container>
    <q-page class="q-pa-xs-md">
      <div v-html="tocContents"></div>
    </q-page>
  </q-page-container>
</template>
<script>
import { cmsService } from "../../services/cmsService";
import mitt from "mitt";

const emitter = mitt();

export default {
  name: "Toc",
  data() {
    return {
      tocContents: "",
    };
  },
  mounted: function () {
    this.setToolbarTitle("Terms & Conditions");
    this.setPageTitle("Terms & Conditions");
    emitter.on("onTocSuccess", this.onTocSuccess);
    cmsService.getToc(this, emitter);
  },
  methods: {
    onTocSuccess: function (data) {
      //alert(data);
      this.tocContents = data;
    },
  },
  unmounted: function () {
    emitter.off("onTocSuccess");
  },
  setup() {},
};
</script>

