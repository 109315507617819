import axiosLogged from "../helpers/axiosLogged";
//import qs from 'qs';
//import mitt from 'mitt';

function getRates(emitterObj) {
    axiosLogged.post("/mandi-rate/list","")
        .then(response => {
            emitterObj.emit('onMandiRateSuccess', response.data.data); 
        }).catch(error => {
            emitterObj.emit('onMandiRateFail', Promise.reject(error));
    });
}
export const mandiRateService = {
    getRates,
};