<template>
    <div>
      <q-layout view="lHh lpr lFf">
        <q-header elevated v-show="false">
          
          <q-toolbar class="c-toolbar shadow-2">
            <!--
            <q-btn
              outline
              dense
              icon="home"
              class="q-mr-sm"
              v-show="btnHomeShow"
              @click="goToHome()"
            />
            -->
            <q-btn v-show="backBtn" dense  color="primary" icon="west" @click="this.$router.go(-1)"></q-btn>
            <q-toolbar-title>Steel Dalal - {{ toolbarTitle }}</q-toolbar-title>
            
            <!--
            <q-btn
              squar
              outline
              dense
              icon="person"
              @click="goToLogin()"
              v-show="btnMyAccountShow"
            >
              My Account
            </q-btn>
            -->
          </q-toolbar>
        </q-header>
        <!--<Footer></Footer>-->
  
        
        <q-footer elevated reveal  v-show="false">
          <q-toolbar class="c-toolbar shadow-2">
            <q-toolbar-title class="text-center text-body2">
              &copy; Steel Dalal - 2022 -
              <i style="color: #cccccc">(app - {{ version }})</i></q-toolbar-title
            >
          </q-toolbar>
        </q-footer>
        
        <slot><!---- views will render here ---></slot>
      </q-layout>
    </div>
  </template>
  <script>
  export default {
    components: {
      //Footer
    },
    name: "NonLoggedLayout",
    props: ["toolbarTitle"],
    data() {
      return {
        version: process.env.VUE_APP_VERSION,
        backBtn:true
      };
    },
    methods: {},
    updated: function () {
      if (
        this.$router.currentRoute.value["name"] == "Home") {
        this.backBtn = false;
      } else{
        this.backBtn = true;  
      }
    },
  };
  </script>
  