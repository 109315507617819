<template>
  <q-page-container>
    <q-page class="q-pa-xs-md">
      <div v-if="data == null" class="text-center text-bold text-body1">
        <br /><br /><br /><br /><br />
        Opps !! There is nothing to calculate. <br />
        <span class="text-blue" @click="goToSeller()">Click to Shopping</span>
      </div>
      <div v-if="data != null">
        <div v-for="(item, index) in items" v-bind:key="item">
          <q-card
            :id="index"
            :class="
              index % 2 == 0
                ? 'bg-green-1  rounded-borders'
                : 'bg-grey-4 rounded-borders'
            "
            no-shadow
          >
            <q-card-section>
              <div class="row">
                <div class="col">
                  <span class="text-subtitle1 text-red text-bold">{{
                    item.mst_product.name
                  }}</span
                  ><br />
                  <q-chip
                    rounded
                    color="blue-2"
                    size="9px"
                    class="text-black text-caption"
                    :label="item.product.brand"
                  />
                  <q-chip
                    rounded
                    color="blue-2"
                    size="9px"
                    class="text-black text-caption"
                    :label="item.product.category"
                  />
                  <q-chip
                    rounded
                    color="blue-2"
                    size="9px"
                    class="text-black text-caption"
                    :label="item.product.grade"
                  />
                </div>
              </div>
              <q-separator />
              <div class="row q-pa-sm">
                <div class="col-4">
                  <q-avatar size="70px">
                    <img
                      no-shadow
                      :src="data.productPhotoURL + item.mst_product.img"
                    />
                  </q-avatar>
                </div>
                <div class="col">
                  <div class="text-caption text-italic text-black">
                    <span v-if="item.product.size_type == 'standard'">
                      <br />
                      {{ item.product_rate.size }} MM
                    </span>

                    <span v-if="item.product.size_type == 'coil'">
                      <q-badge squar color="grey-5 text-black" label="Width " />
                      {{
                        item.product_rate.width +
                        " " +
                        item.product_rate.width_uom
                      }}
                      <br />
                      <q-badge
                        squar
                        color="grey-5 text-black"
                        label="Thickness : "
                      />
                      {{
                        item.product_rate.thickness +
                        " " +
                        item.product_rate.thickness_uom
                      }}
                    </span>
                    <span
                      class="text-regular text-bold"
                      v-if="item.product.size_type == 'sheet'"
                    >
                      <q-badge
                        squar
                        color="grey-5 text-black"
                        label="Width : "
                      />
                      {{
                        item.product_rate.width +
                        " " +
                        item.product_rate.width_uom
                      }}
                      <br />
                      <q-badge
                        squar
                        color="grey-5 text-black"
                        label="Thickness : "
                      />
                      {{
                        item.product_rate.thickness +
                        " " +
                        item.product_rate.thickness_uom
                      }}
                      <br />
                      <q-badge
                        squar
                        color="grey-5 text-black"
                        label="Length : "
                      />
                      {{
                        item.product_rate.length +
                        " " +
                        item.product_rate.length_uom
                      }}
                    </span>
                    <span v-if="item.product.mst_color_id > 0">
                      <br />
                      Color: {{ item.product.color_name }}
                    </span>
                    <span
                      v-if="
                        item.product.cw_5_10 > 0 ||
                        item.product.cw_10_22 > 0 ||
                        item.product.cw_22_27 > 0
                      "
                    >
                      <br />
                      Weight:
                      <span v-if="item.product.cw_5_10 > 0"> 5-10 ,</span>
                      &nbsp;
                      <span v-if="item.product.cw_10_22 > 0">10-22 ,</span>
                      &nbsp;
                      <span v-if="item.product.cw_22_27 > 0">22-27</span> &nbsp;
                    </span>
                  </div>
                </div>
              </div>
              <q-separator />
              <div class="row q-pt-sm">
                <div class="col-2 text-left">
                  <q-chip
                    square=""
                    color="green text-white text-bold"
                    :label="'#' + (index + 1)"
                  />
                </div>
                <div class="col text-right">
                  <span class="text-caption text-bold">
                    {{ item.qty }} {{ item.product_rate.qty_uom }} x
                    {{ item.price }} =
                  </span>
                  <q-chip
                    squar
                    icon="currency_rupee"
                    square=""
                    color="white text-blue text-bold"
                    :label="item.total"
                  />
                </div>
              </div>
            </q-card-section>
          </q-card>
          <q-separator />
          <br />
        </div>
        <div class="q-pa-sm">
          <div class="row text-pink text-subtitle1">
            <div class="col text-right text-subtitle1">
              <span class="text-black text-grey-9 text-bold">Total</span>
            </div>
            <div class="col text-right text-bold">
              <q-icon name="currency_rupee" /> {{ total }}
            </div>
          </div>
          <q-separator />
          <div class="row">
            <div class="col text-right">
              <span class="text-caption text-grey"
                >* exclude gst and other fee</span
              >
            </div>
          </div>
        </div>
        <br /><br />
        <div class="row">
          <div class="col">
            <q-btn
              size="12px"
              rounded
              icon="ion-basket"
              color="primary"
              label="Add To Cart"
              @click="addToCart()"
            />
          </div>
          <div class="col text-right">
            <q-btn
              size="12px"
              rounded
              color="green"
              label="Modify Items"
              @click="goToSellerProductRate(items[0].product.api_id)"
            />
          </div>
        </div>
      </div>
    </q-page>
  </q-page-container>
</template>
  <script>
import { userService } from "../../services/userService";
import mitt from "mitt";
import { cartService } from "../../services/cartService";

const emitter = mitt();

export default {
  name: "CalculatePrice",
  data() {
    return {
      isLogged: null,
      data: null,
      items: null,
      total: 0.0,
      form:null,
    };
  },
  methods: {
    addToCart: function () {
      emitter.on("onCartAddSuccess", this.onCartAddSuccess);
      emitter.on("onCartAddFail", this.onCartAddFail);
      cartService.addIntoCart(this.form, this, emitter);
    },
    onCartAddSuccess: function (data) {
      this.showInfo("Item added into cart !");
      this.goToCart();
    },
    onCartAddFail: function (error) {
      this.showError(error);
    },
    onGetCalculatedItemsSuccess: function (data) {
      //console.log(data);
      if (data.data.length > 0) {
        this.data = data;
        this.items = data.data;
        for (let i = 0; i < this.items.length; i++) {
          this.total = parseFloat(this.total) + parseFloat(this.items[i].total);
        }
      }
    },
    onGetCalculatedItemsFail: function (error) {
      this.showError(error);
    },
    pullCartItems: function () {
      emitter.on(
        "onGetCalculatedItemsSuccess",
        this.onGetCalculatedItemsSuccess
      );
      emitter.on("onGetCalculatedItemsFail", this.onGetCalculatedItemsFail);
      cartService.getCalculatedItems("", this, emitter);
    },
  },
  created: function () {
    this.setPageTitle("Product Calculation");
    this.setToolbarTitle("Product Calculation");
  },
  mounted: function () {
    this.pullCartItems();
    this.isLogged = userService.isLogged();
  },
  unmounted: function () {
    emitter.off("onGetCalculatedItemsSuccess");
    emitter.off("onGetCalculatedItemsFail");
    emitter.off("onCartAddSuccess");
    emitter.off("onCartAddFail");
  },
  setup() {},
};
</script>
  