<script>
import { ref } from "vue";
import { userService } from "../../services/userService";
import Footer from "./includes/Footer.vue";
import NonLoggedFooter from "./includes/NonLoggedFooter.vue";
import mitt from "mitt";
import { statsService } from "../../services/statsService";

const emitter = mitt();
import MenuLogged from './includes/menus/MenuLogged.vue';

const Name ="test";
const userName = userService.user("first_name") + " " + userService.user("last_name");
export default {
  name: "LoggedLayout",
  props: ["toolbarTitle"],
  components: {
    Footer,
   MenuLogged,
    //NonLoggedFooter,
  },
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
      userName: userService.user("first_name") + " " + userService.user("last_name"),      
      cartItemCount: 0,
      notificationCount: 0,
      isCustomer: true,
      isLogged: userService.isLogged(), //new
      //profiledialog: ref(false), //new
      //maximizedToggle: ref(true), //new
      //dialog: false,
      //openNonLogged:false,
      openLogged:false,
    };
  },

  mounted: function () {
    this.userName = userService.getValue("contact_person");
    this.Name = this.userName;
    //console.log(this.Name);
    
  },
  methods: {
    callme: function () {
      //alert("hi");
    },
    
    toggleLoggedMenu: function(val){
      this.openLogged = val;
    },
     
    /*showToggleDialog: function (bool) {
      this.dialog = bool;
    },
   toggleNonLoggedMenu: function(val){
      this.openNonLogged = val;
    },
    toggleLoggedMenu: function(val){
      this.openLogged = val;
    },*/
    onStatsSuccess: function (data) {
      //console.log(data);
      this.cartItemCount = data.data.total_cart_items;
      this.notificationCount = data.data.total_notifications;
      localStorage.setItem("gst", data.data.gst);

    },
    onStatsFail: function (error) {
      //console.log(error);
    },
  },
  updated: function () {
    //this.scrollToTop();
    emitter.on("onStatsSuccess", this.onStatsSuccess);
    emitter.on("onStatsFail", this.onStatsFail);
    statsService.getStats(emitter);
    //console.log("LOOGED-LAYOUT:: Updated");
    this.isCustomer = userService.isCustomer();
    
  },
  setup() {
    return {
      
    };
  },
  unmounted: function () {
    emitter.on("onStatsSuccess", this.onStatsSuccess);
    emitter.on("onStatsFail", this.onStatsFail);
    //console.log("LOOGED-LAYOUT:: UnMOUNTED");
  },
};
</script>
<template>
  <div class="q-pa-sm">
    <q-layout view="lHh lpr lFf">
      <q-header elevated>
        <q-toolbar class="c-toolbar shadow-2">
          <q-btn
            dense
            color="primary"
            icon="west"
            @click="this.$router.go(-1)"
            v-show="false"
          />
          <!--
           <q-separator dark vertical inset></q-separator>
            -->

          <q-btn round class="">
            <q-avatar size="40px">
              <!--<img
                src="img/splash.3d7bdd78.jpg"
                style="width: auto"
                @click="this.goToDashboard()"
              />-->
              <img src="/img/new_splash.d2767935.png" style="width: auto" @click="this.goToDashboard()" class="bg-white"/>
              
            </q-avatar>
          </q-btn>

          <q-toolbar-title class="text-body1">{{
            toolbarTitle
          }}</q-toolbar-title>
          <!--
          <q-btn round dense color="primary" icon="home" @click="goToHome()"  />
          -->
          <!--
          <q-btn flat round dense icon="notifications" >
            <q-badge floating color="red">2</q-badge>
          </q-btn>
        -->
          <!--TEST DIALOG-->
          <span v-show="isLogged">
            <q-avatar
              color="white"
              text-color="primary"
              style="font-size: 26px"
              class="q-ml-lg"
              icon="person"
              @click="toggleLoggedMenu(true)"
              
            />
          </span>
          <span v-show="!isLogged">
            <q-btn
              dense
              color="primary"
              icon="login"
              class="q-ml-lg"
              @click="goToHome()"
              style="font-size: 14px; text-transform: capitalize"
              >Login</q-btn
            >
          </span>
          <!--END TEST DIALOG -->
          <q-btn
            dense
            color="primary"
            icon="notifications"
            class="q-ml-md"
            @click="goToNotification()"
          >
            <q-badge color="red" floating v-show="notificationCount > 0">{{
              notificationCount
            }}</q-badge>
          </q-btn>
          &nbsp;&nbsp;
          <!--<q-btn dense color="primary" icon="shopping_cart" @click="goToCart()" v-if="isCustomer">
          <q-badge color="red" floating v-show="cartItemCount > 0">{{cartItemCount}}</q-badge>
        </q-btn>-->

          <!--
        <q-btn dense color="red" icon="logout" @click="goToLogout()"  />
        -->
        </q-toolbar>
      </q-header>
      <!--<span v-show="isLogged"
        ><Footer :cartTotalItems="cartItemCount"></Footer
      ></span>
      <span v-show="!isLogged"
        ><NonLoggedFooter :cartTotalItems="cartItemCount"></NonLoggedFooter
      ></span>-->
      <Footer :cartTotalItems="cartItemCount"></Footer>
      <!--/end drawer -->
      <!--
      <q-footer elevated reveal>
        <q-toolbar class="c-toolbar  shadow-2">
          <q-toolbar-title class="text-center text-body2">
            &copy; Steel Dalal - 2022 -
            <i style="color: #cccccc">(app - {{ version }})</i>
          </q-toolbar-title>
        </q-toolbar>
      </q-footer>
      -->
      <!--
      <router-view />
      -->
      <slot />
    </q-layout>
  </div>
  <!--TEST--->

  <!--<div class="col-12">
    <q-dialog
      v-model="profiledialog"
      persistent
      :maximized="maximizedToggle"
      transition-show="slide-up"
      transition-hide="slide-down"
    >
      <q-card class="text-black">
        <q-bar class="bg-white">
          <div class="col-12">
            <q-btn
              dense
              flat
              icon="arrow_back"
              class="q-ml-md"
              v-close-popup
              style="
                margin-left: -2px;
                text-transform: capitalize;
                font-weight: normal;
              "
            >
            </q-btn
            ><span class="q-ml-md" style="font-weight: normal; font-size: 12px"
              >My Profile</span
            >
          </div>

          <q-btn
            dense
            flat
            icon=""
            @click="maximizedToggle = false"
            :disable="maximizedToggle"
          >
            <q-tooltip v-if="maximizedToggle" class="bg-white text-white"
              >Minimize</q-tooltip
            >
          </q-btn>
          <q-btn
            dense
            flat
            icon=""
            @click="maximizedToggle = true"
            :disable="!maximizedToggle"
          >
            <q-tooltip v-if="!maximizedToggle" class="bg-white text-white"
              >Maximize</q-tooltip
            >
          </q-btn>
        </q-bar>
        <q-separator />
        <div class="col q-mt-xs q-ml-xs" style="max-width: 97%">
          <q-list>
            <q-card-section class="col-12">
              <div class="col-12">
                <div
                  class="q-btn q-btn-primary"
                  style="
                    background: #013f7a;
                    text-transform: capitalize;
                    color: white;
                    text-align: left;
                    width: 100%;
                    border: 2px solid #013f7a;
                    border-radius: 1rem;
                  "
                  @click="goToHome()"
                >
                  Sign In/ Sign Up
                </div>
              </div>
            </q-card-section>

            <q-card class="rounded q-mb-sm">
              <q-item clickable v-ripple>
                <q-item-section avatar>
                  <q-avatar
                    rounded
                    color="primary"
                    text-color="white"
                    icon="contact_support"
                    style="font-size: 40px"
                  />
                </q-item-section>

                <q-item-section>FAQ's</q-item-section>
              </q-item>

              <q-separator />

              <q-item
                clickable
                v-ripple
                @click="$router.replace('/contact-us')"
                v-close-popup
              >
                <q-item-section avatar>
                  <q-avatar
                    rounded
                    color="primary"
                    text-color="white"
                    icon="perm_phone_msg"
                    style="font-size: 40px"
                  />
                </q-item-section>
                <q-item-section>Contact Us</q-item-section>
              </q-item>
              <q-separator />
              <q-item
                clickable
                v-ripple
                @click="goToRequiredDemo()"
                v-close-popup
              >
                <q-item-section avatar>
                  <q-avatar
                    rounded
                    color="primary"
                    text-color="white"
                    icon="alarm "
                    style="font-size: 40px"
                  />
                </q-item-section>
                <q-item-section>Schedule a Demo?</q-item-section>
              </q-item>

              <q-separator />
              <q-item clickable v-ripple @click="showToggleDialog(true)">
                <q-item-section avatar>
                  <q-avatar
                    rounded
                    color="primary"
                    text-color="white"
                    icon="ion-logo-youtube "
                    style="font-size: 40px"
                  />
                </q-item-section>
                <q-item-section>How It works?</q-item-section>
              </q-item>

              
              <q-dialog v-model="dialog" persistent full-width v-show="dialog">
                <q-card class="bg-black text-white text-center q-pb-sm">
                  <q-card-section>
                    <q-video
                      style="height: 350px"
                      src="https://www.youtube.com/embed/WI5LLBteInI"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    />
                  </q-card-section>
                  <q-btn
                    size="10px"
                    label="Close"
                    color="primary"
                    @click="showToggleDialog(false)"
                  />
                </q-card>
              </q-dialog>
            </q-card>
            <q-separator />
            <q-card
              class="no-margin no-padding rounded"
              @click="$router.replace('/cms/about-us')"
              v-close-popup
            >
              <q-item clickable v-ripple no-margin no-padding>
                <q-item-section avatar>
                  <q-avatar
                    rounded
                    color="primary"
                    text-color="white"
                    icon="info"
                    style="font-size: 40px"
                  />
                </q-item-section>
                <q-item-section>About Us</q-item-section>
              </q-item>
              <q-separator />
              <q-item clickable v-ripple no-margin no-padding>
                <q-item-section avatar>
                  <q-avatar
                    rounded
                    color="primary"
                    text-color="white"
                    icon="share"
                    style="font-size: 40px"
                  />
                </q-item-section>
                <q-item-section>Share App</q-item-section>
              </q-item>
              <q-separator />
              <q-item
                clickable
                v-ripple
                no-margin
                no-padding
                @click="$router.replace('/cms/privacy-policy')"
              >
                <q-item-section avatar>
                  <q-avatar
                    rounded
                    color="primary"
                    text-color="white"
                    icon="privacy_tip"
                    style="font-size: 40px"
                  />
                </q-item-section>
                <q-item-section>Privacy Policy</q-item-section>
              </q-item>

              <q-separator />
              <q-item
                clickable
                v-ripple
                no-margin
                no-padding
                @click="$router.replace('/contact-us')"
                v-close-popup
              >
                <q-item-section avatar>
                  <q-avatar
                    rounded
                    color="primary"
                    text-color="white"
                    icon="person"
                    style="font-size: 40px"
                  />
                </q-item-section>
                <q-item-section>Become a Seller</q-item-section>
              </q-item>
            </q-card>
          </q-list>
        </div>
      </q-card>
    </q-dialog>
  </div>-->
  <!--END TEST-->
    <menu-logged :open="openLogged" @close="toggleLoggedMenu" :uName="Name"></menu-logged>
    
</template>

